import React, { Component } from 'react';
import styles from './style.css';
import closeIcon from 'static/closeIcon.png';

class WeChat extends Component {
  render() {
    return (
      <div>
        <div className={styles.kidsLogin__header}>
          <p className={styles.kidsLogin__title}>微信登录</p>
          <img className={styles.kidsLogin__close}
            src={closeIcon}
            onClick={this.handleCloseBtnClick} />
        </div>
      </div>
    );
  }
}

export default WeChat;