import React, { Component } from 'react';
import bindAll from 'lodash.bindall';
import Bubble from '../../../../components/Bubble';
import styles from './style.css';

class TabBar extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleShareEnter',
      'handleShareLeave',
    ]);
    this.state = {
      bubble: {
        text: '',
        top: '',
        left: ''
      }
    };
  }

  handleShareEnter({ share_code }, e) {
    const { pageX, pageY } = e;
    this.setState({
      bubble: {
        text: share_code,
        top: pageY,
        left: pageX
      }
    });
  }

  handleShareLeave({ share_code }, e) {
    this.setState({
      bubble: {}
    });
  }

  render() {
    const { currentLabelId, labelList, onTabBarClick } = this.props;
    const { bubble: { text, top, left } } = this.state;
    return (
      <div className={styles.kidsOther__saveWrapper__item}>
        <div className={styles.kidsOther__saveWrapper__tabBar}>
          {labelList.map((item, index) => {
            return (
              <div key={index} className={
                styles.kidsOther__saveWrapper__tabBarItem + ' ' +
                (currentLabelId === item.id && styles['kidsOther__saveWrapper__tabBarItem--active'])
              }
                onClick={onTabBarClick.bind(this, item)}>
                {item.id === 0 ? '全部' : item.label}
                {item.share_code && (
                  <div
                    className={styles.kidsOther__saveWrapper__tabBarItemShare}
                    onMouseEnter={this.handleShareEnter.bind(this, item)}
                    onMouseLeave={this.handleShareLeave.bind(this, item)}
                  >
                    <span>享</span>
                    {text && (
                      <Bubble
                        text={text}
                        top={top}
                        left={left}
                      />)
                    }
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default TabBar;