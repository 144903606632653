var escape = require("../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n  /* probably unecessary, transitional until layout is refactored */\n  width: 100%;\n  height: 100%;\n  margin: 0;\n\n  /* Setting min height/width makes the UI scroll below those sizes */\n  min-width: 1024px;\n  min-height: 640px; /* Min height to fit sprite/backdrop button */\n  -ms-touch-action: none;\n      touch-action: none;\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n\n.scratchCategoryMenuItemLabel {\n  font-size: 0.85rem !important;\n}\n\n.scratchCategoryMenu {\n  background-color: #ffffff;\n}\n\n.blocklyText {\n  font-size: 18px !important;\n}\n\n.blocklyFlyout__trash {\n  display: block;\n  opacity: 0;\n  visibility: hidden;\n  pointer-events: none;\n  z-index: 100;\n  position: absolute;\n  width: 60px;\n  left: 0px;\n  top: 0;\n  bottom: 0;\n  background-image: url(\"/static/trash_empty.svg\");\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-color: rgba(0, 0, 0, 0.4);\n  background-size: 50px 50px;\n  /* animation: opac .2s; */\n  -webkit-transition: all 0.2s;\n  -o-transition: all 0.2s;\n  transition: all 0.2s;\n}\n\n.blocklyFlyout.fix{\n  display: block !important;\n}\n\n.injectionDiv__nail:hover {\n  opacity: 0.85;\n  cursor: pointer;\n}\n\n/* @keyframes opac {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n} */\n\n.blocklyFlyout__trash:hover {\n  background-image: url(" + escape(require("../../static/trash_full.svg")) + ");\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};