/**
 * fetchWork: 获取远程作品(需传入props，再传入url)
 * @param {object} props 如下：
 * @property {function} loadProject 加载作品API
 * @property {object} vm VM引擎（封面绘制）
 * @property {function} setLoading 是
 * @param {string} url 作品地址
 */
const fetchWork = ({
  loadProject,
  vm,
  setLoading = () => { }
}) => {
  return function (url) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      fetch(url, {
        method: 'GET'
      }).then(response => response.arrayBuffer())
        .then(file => {
          loadProject(file).then(() => {
            if (vm) {
              vm.renderer.draw(); // 绘制第一帧封面
            }
            setTimeout(() => {
              setLoading(false);
              resolve();
            }, 500);
          }).catch(e=>{
            setLoading(false);
            reject(e)
          })
        }).catch((err) => {
          setLoading(false);
          reject(err);
        })
    })
  }
}

export {
  fetchWork
}
