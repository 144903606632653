import { combineReducers } from 'redux';
import app from './kids-app';
import outerPanel from './kids-outer-panel';
import userPanel from './kids-user-panel';
import entities from './kids-entities';
import page from './kids-page';

const reducers = combineReducers({
  app,
  outerPanel,
  userPanel,
  entities,
  page
});

export default reducers;