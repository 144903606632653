exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* 标题 */\n.style_kidsLogin__header_2DLPh {\n  position: relative;\n  text-align: center;\n}\n.style_kidsLogin__title_aHy_b {\n  color: #333;\n  font-size: 24px;\n  line-height: 60px;\n  height: 60px;\n  margin: 0;\n}\n.style_kidsLogin__close_Oclld {\n  width: 36px;\n  position: absolute;\n  right: 20px;\n  top: 12px;\n  color: #666;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"kidsLogin__header": "style_kidsLogin__header_2DLPh",
	"kidsLoginHeader": "style_kidsLogin__header_2DLPh",
	"kidsLogin__title": "style_kidsLogin__title_aHy_b",
	"kidsLoginTitle": "style_kidsLogin__title_aHy_b",
	"kidsLogin__close": "style_kidsLogin__close_Oclld",
	"kidsLoginClose": "style_kidsLogin__close_Oclld"
};