exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_kidsProgress__wrapper_2OSms {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 28050;\n  /* background: rgba(0, 0, 0, 0.15); */\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n.style_kidsProgress__wrapper_2OSms img {\n  /* position: absolute;\n  top: 20%;\n  left: 50%;\n  margin-left: -20px; */\n  width: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"kidsProgress__wrapper": "style_kidsProgress__wrapper_2OSms",
	"kidsProgressWrapper": "style_kidsProgress__wrapper_2OSms"
};