import React, { Component } from 'react';
import styles from './style.css';

class Confirm extends Component {
  render() {
    const {
      content,
      confirmEvent,
      confirmText,
      cancelEvent,
      cancelText
    } = this.props;
    return (
      <div>
        <div className={styles.kidsPerson__tipsMask} ref='mask'></div>
        <div className={styles.kidsPerson__tips} ref='toast'>
          <div className={styles.kidsPerson__tips__text}>
            {content}
          </div>
          <div className={styles.kidsPerson__tips__content}>
            <div
              className={styles.kidsPerson__tips__confirm}
              onClick={confirmEvent}
            >{confirmText}</div>
            <div
              className={styles.kidsPerson__tips__cancel}
              onClick={cancelEvent}
            >{cancelText}</div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.refs.mask.className = styles.kidsPerson__tipsMask + ' ' + styles['kidsPerson__tipsMask--show']
      this.refs.toast.className = styles.kidsPerson__tips + ' ' + styles['kidsPerson__tips--show']
    }, 20)
  }

}

export default Confirm;