import React, { Component } from 'react';
import styles from './style.css';
import Login from './components/Login';
import Register from './components/Register';
import Forget from './components/Forget';
import WeChat from './components/WeChat';

class OuterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideClass: true
    };
  }
  render() {
    const { hideClass } = this.state;
    const { outerPanelMode } = this.props;
    return (
      <div>
        <div className={
          styles.kidsLogin__windowMask + ' ' +
          (hideClass ? styles['kidsLogin__windowMask--hide'] : '')
        }>
        </div>
        <div className={
          styles.kidsLogin__Window + ' ' +
          styles['kidsLogin__WindowLogin'] + ' ' +
          (outerPanelMode === 1 && styles.kidsLogin__WindowLogin) + ' ' +
          (outerPanelMode === 2 && styles.kidsLogin__WindowRegister) + ' ' +
          (outerPanelMode === 3 && styles.kidsLogin__WindowForget) + ' ' +
          (hideClass ? styles['kidsLogin__Window--hide'] : '')
        }>
          {/* 不额外增设常量映射关系，我们规定outerPanelMode:
            0 关闭 1 Login 2 Regiter 3 Forget 4 WeChat */}
          {outerPanelMode === 1 && <Login />}
          {outerPanelMode === 2 && <Register />}
          {outerPanelMode === 3 && <Forget />}
          {outerPanelMode === 4 && <WeChat />}

        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        hideClass: false
      });
    }, 20)
  }

}

export default OuterPanel;