import React, { Component } from "react";
import styles from "./style.css";
import bindAll from "lodash.bindall";

class Popup extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      "handleInputChange",
      "handleConfirmKeyDown",
      "handleSubmitClick",
    ]);
  }

  handleInputChange(e) {
    this.props.setPopupInput(e.target.value);
  }
  handleConfirmKeyDown(e) {
    if (e.keyCode !== 13) {
      return;
    }
    const { value } = this.refs.input;
    this.props.confirmEvent(value);
  }

  handleSubmitClick() {
    const { value } = this.refs.input;
    this.props.confirmEvent(value);
  }

  componentDidMount() {
    this.refs.input.focus();
  }

  render() {
    const {
      title,
      tip,
      placeholder,
      input,
      confirmText,
      confirmEvent,
      cancelText,
      cancelEvent,
      top,
      left,
    } = this.props;
    return (
      <div className={styles.kidsPopup__wrapper}>
        <div
          className={styles.kidsPopup__main}
          style={{
            top: top ? top : null,
            left: left ? left : null,
          }}
        >
          <div className={styles.kidsPopup__header}>
            <div className={styles.kidsPopup__title}>{title}</div>
          </div>
          <div className={styles.kidsPopup__container}>
            {tip && (
              <div className={styles.kidsPopup__tip}>
                <i
                  className={"iconfont icon-tip " + styles.kidsPopup__tipIcon}
                />
                <div className={styles.kidsPopup__tipText}>{tip}</div>
              </div>
            )}
            <input
              className={styles.kidsPopup__input}
              // autoComplete="on"
              // name="popupInput"
              placeholder={placeholder}
              value={input}
              maxLength="20"
              onChange={this.handleInputChange}
              onKeyDown={this.handleConfirmKeyDown}
              ref="input"
            />
          </div>
          <div className={styles.kidsPopup__button}>
            <div
              className={styles.kidsPopup__btn}
              onClick={this.handleSubmitClick}
            >
              {confirmText}
            </div>
            <div className={styles.kidsPopup__btn} onClick={cancelEvent}>
              {cancelText}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
