import React, { Component } from 'react';
import bindAll from 'lodash.bindall';
import styles from './style.css';
import { getPublicAvatar } from '../../../../../lib/fetch-api';
import addBtnIcon from 'static/addBtnIcon.png';

class InfoProvideAvatar extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleProvideAvatarClick',
      'handleProvideAvatarCloseClick',
      'handleAddBtnClick',
      'handleSaveClick'
    ]);
    this.state = {
      avatarList: [],
      showProvidePanel: false,
      selectedIndex: -1
    };
    // 临时变量
    this.pressTimer = null; // 缩放按钮长按事件
    this._bodyClick = null; // 外部点击监听器
  }

  componentDidMount() {
    getPublicAvatar().then(res => {
      if (res.errno === -1) {
        // 暂不处理
        return;
      }
      this.setState({
        avatarList: res.data.list
      });
    })
    // this._bodyClick = document.addEventListener('click',
    //   this.handleProvideAvatarCloseClick, false);
  }
  // componentWillUnmount() {
  // document.removeEventListener('click', this._bodyClick);
  // }

  handleAddBtnClick(e) {
    // 阻止元素与document的事件间冒泡。
    e.nativeEvent.stopImmediatePropagation();
    // 事件委托
    if (!e.target.className.includes('style_kidsCanvas__addIcon')) {
      return;
    }
    this.setState({
      showProvidePanel: !this.state.showProvidePanel,
      selectedIndex: -1
    });

  }

  handleProvideAvatarClick(avatar, index) {
    // 重置canvas数据
    this.props.refreshCanvasAvatar();
    // 改变选项卡索引
    this.setState({ selectedIndex: index });
    /** 
   * 若点击上传，则
   * 执行img的捕获，src -> new Image() -> canvas转base64 -> 
   *  this.dataURL寄存 -> base64转blob转File -> 丢入formData -> 
   *   上传成功后-> this.dataURL渲染头像
   */
    // 将src丢入Image标签生成canvas
    this.props.onHandleUploadCanvasChange({ provideSrc: avatar });
  }

  handleProvideAvatarCloseClick() {
    this.setState({
      showProvidePanel: false
    });
  }

  handleSaveClick() {
    // 执行保存，再执行关闭操作
    this.props.toUploadAvatar().then((bool) => {
      if (!bool) {
        return;
      }
      this.props.onHandleBackBtnClick();
      // 关闭自身面板
      this.handleProvideAvatarCloseClick();
    });
  }

  renderProvideAvatar() {
    const { selectedIndex, avatarList } = this.state;
    return avatarList.map((avatar, index) => {
      return (
        <li key={index}
          className={styles.kidsCanvas__avatarArea__liItem}
          onClick={this.handleProvideAvatarClick.bind(this, avatar, index)}
        >
          <div className={
            styles.kidsCanvas__avatarArea__liBox + ' ' +
            (selectedIndex === index && styles['kidsCanvas__avatarArea__liBox--selected'])
          }>
            <img className={styles.kidsCanvas__avatarArea__avatar}
              src={avatar} alt="学生头像"
            />
          </div>
        </li>
      )
    })
  }

  render() {
    const { showProvidePanel } = this.state;
    return (
      <div className={styles.kidsCanvas__add}
        onClick={this.handleAddBtnClick}
      >
        <img className={styles.kidsCanvas__addIcon}
          src={addBtnIcon} alt="初始头像"
        />
        {showProvidePanel && (
          <div className={styles.kidsCanvas__select}>
            <div className={styles.kidsCanvas__studentHead}>学生头像</div>
            <ul className={styles.kidsCanvas__avatarArea}>
              {this.renderProvideAvatar()}
            </ul>
            <div className={styles.kidsCanvas__select__btn}>
              <div className={styles.kidsCanvas__select__close}
                onClick={this.handleProvideAvatarCloseClick}
              >关闭</div>
              {/* <div className={styles.kidsCanvas__select__save}
                onClick={this.handleSaveClick}
              >保存</div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InfoProvideAvatar;