import React, { Component } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
const { actions: { setToastShow, setProgress } } = require('../../../../../reducers/kids-app');
const { actions: {
  setShowSaveSpritePanel, setSpriteName, setSpriteComment,
  setShowLabelPanel, setSelectedSpriteLabel, setSpriteList
} } = require('../../../../../reducers/kids-user-panel');
import { convertBase64ToBlob } from '../../../../../lib/utils/kids-tools';
import { uploadSpriteInfo } from '../../../../../lib/fetch-api';
import { getSpriteCoverPath, getSpriteFilePath } from '../../../../../lib/utils/config';
import { randomId } from '../../../../../lib/utils/kids-tools';
import createOSS from '../../../../../lib/utils/kids-oss-operator';
import closeIcon from 'static/closeIcon.png';

class SaveSprite extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCancelClick',
      'handleSpriteNameChange',
      'handleSpriteCommentChange',
      'handlePreSaveClick',
      'handleSaveSpriteClick',
      'handleManageLabelClick',
      'handleSelectChange'
    ]);
    this.spriteInfo = {};
    this.uploadTimer = null; // 上传间隔
    this.uploadFlag = false; // 上传标志位
    this.second = 5;
  }

  handleSelectChange(e) {
    const index = e.target.selectedIndex;
    if (index < 0) {
      // -1 即为无标签
      this.props.setSelectedSpriteLabel(0);
      return;
    }
    const { spriteLabelList } = this.props;
    const { id } = spriteLabelList[index];
    this.props.setSelectedSpriteLabel(id);
  }

  handleManageLabelClick() {
    this.props.setShowLabelPanel(2);
  }

  handleCancelClick() {
    this.props.closePanel();
  }

  handleSpriteNameChange(e) {
    this.props.vm.renameSprite(this.props.editingTarget, e.target.value); // 改vm sprite名
  }

  handleSpriteCommentChange(e) {
    this.props.setSpriteComment(e.target.value);
  }

  handlePreSaveClick() {
    return new Promise((resolve, reject) => {
      const {
        createSpriteBlobById, getSpriteById,
        spriteId, setSpriteName,
      } = this.props;
      // 获取素材
      const sp = getSpriteById(spriteId);
      // 获取名称
      setSpriteName(sp.name);
      // 获取素材二进制
      createSpriteBlobById(spriteId).then(blob => {
        // 获取素材封面
        const coverBlob = convertBase64ToBlob(sp.costume.asset.encodeDataURI());
        const { type: coverType } = coverBlob; // 获取后缀
        let ext = coverType.split('/')[1];
        if (ext.includes('svg+xml')){ // 兼容 svg+xml 后缀
           ext = 'svg';
        }
        const sprite = new File([blob], `${randomId()}.sprite3`, { type: 'application/zip' });
        const cover = new File([coverBlob], `${randomId()}.${ext}`, { type: coverType });

        this.spriteInfo.sprite = sprite;
        this.spriteInfo.cover = cover;
        resolve();
      });
    })
  }

  handleSaveSpriteClick() {
    // console.log(this.uploadFlag);
    const {
      editingTarget,
      spriteComment,
      selectedSpriteLabelId: spriteLabelId,
      getSpriteById,
      showError,
      showSuccess,
      setProgress,
      setSpriteList,
      spriteList
    } = this.props;

    if (this.uploadFlag) {
      showError('素材上传中，请等待');
      return;
    }
    // 防止上传轰炸
    if (this.uploadTimer) {
      showError('请等待' + this.second + '秒再尝试');
      return;
    }
    this.uploadFlag = true;
    // 生成素材数据
    this.handlePreSaveClick().then(() => {
      this.spriteInfo.sprite_name = getSpriteById(editingTarget).name;
      this.spriteInfo.sprite_comment = spriteComment;
      this.spriteInfo.sprite_labelId = spriteLabelId;

      for (let key in this.spriteInfo) {
        if (key === 'sprite_name' && !this.spriteInfo[key]) {
          showError('请输入素材名称');
          return;
        }
      }

      // 确认无误，先向OSS发送存储请求，再往后端携带数据
      let p1, p2; // 双文件双进度合并
      Promise.all([
        createOSS().upload(
          getSpriteCoverPath(this.spriteInfo.cover.name),
          this.spriteInfo.cover,
          (progress) => {
            p1 = progress;
            setProgress(parseInt((p1 + p2) / 2 * 100));
          }
        ),
        createOSS().upload(
          getSpriteFilePath(this.spriteInfo.sprite.name),
          this.spriteInfo.sprite,
          (progress) => {
            p2 = progress;
            setProgress(parseInt((p1 + p2) / 2 * 100));
          }
        ),
      ]).then(() => {
        // OSS上传完毕，后端入库
        return uploadSpriteInfo({
          spriteInfo: {
            ...this.spriteInfo,
            file_path: this.spriteInfo.sprite.name,
            cover_path: this.spriteInfo.cover.name
          }
        })
      }).then(({ errno, data }) => {
        if (errno !== 0) {
          showError('保存失败');
          return;
        }
        showSuccess('保存素材成功');
        // 更新redux
        setSpriteList([data, ...spriteList]);
        this.handleCancelClick();
        // 解除标志
        this.uploadFlag = false;
        // console.log(this.uploadFlag);
        // 防止上传轰炸
        this.uploadTimer = setInterval(() => {
          this.second -= 1;
          if (this.second <= 0) {
            clearInterval(this.uploadTimer);
            this.uploadTimer = null;
            this.second = 5;
          }
        }, 1000);
      }).catch(e => {
        showError('网络异常，请重新上传')
        // 重置
        this.uploadFlag = false;
        clearInterval(this.uploadTimer)
        this.uploadTimer = null
        this.second = 0;
        setProgress(0);
      })
    })

  }

  render() {
    const {
      isLogin, spriteId, getSpriteById, spriteComment,
      spriteLabelList, selectedSpriteLabelId
    } = this.props;
    const spriteName = spriteId && getSpriteById(spriteId).name;

    return (
      <div className={styles.kidsOther__saveBtnWrapper}>
        {isLogin && spriteId ? (
          <div>
            <div className={styles.kidsOther__windowMask}></div>
            <div className={styles.kidsOther__saveWrapper}>
              <div className={styles.kidsOther__saveWrapper__header}>
                <p className={styles.kidsOther__saveWrapper__title}>保存素材</p>
                <img className={styles.kidsOther__saveWrapper__close}
                  src={closeIcon}
                  onClick={this.handleCancelClick}
                />
              </div>
              <div className={styles.kidsOther__saveWrapper__section}>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={styles.kidsOther__saveWrapper__item__title}>素材名称:</p>
                  <input
                    className={styles.kidsOther__saveWrapper__item__input}
                    type="text"
                    maxLength='20'
                    placeholder='输入素材名称，不超过20个字'
                    value={spriteName}
                    onChange={this.handleSpriteNameChange}
                  />
                </div>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={styles.kidsOther__saveWrapper__item__title}>素材说明:</p>
                  <textarea
                    className={styles.kidsOther__saveWrapper__item__textarea}
                    maxLength='300'
                    placeholder='输入素材说明，可不填写。'
                    value={spriteComment}
                    onChange={this.handleSpriteCommentChange}
                  />
                  <span className={styles.kidsOther__saveWrapper__item__count}>
                    {spriteComment.length} /300
                  </span>
                </div>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={styles.kidsOther__saveWrapper__item__title}>素材标签:</p>
                  <select
                    className={styles.kidsOther__saveWrapper__select}
                    value={selectedSpriteLabelId}
                    onChange={this.handleSelectChange}
                  >
                    {spriteLabelList.map(
                      (item, index) =>
                        <option key={index} value={item.id}>{item.label}</option>
                    )}

                  </select>
                  <div className={styles.kidsOther__saveWrapper__managerSelect}
                    onClick={this.handleManageLabelClick}
                  >
                    管理标签
                  </div>
                </div>
              </div>
              <div className={styles.kidsOther__saveWrapper__btn}>
                <div
                  className={styles.kidsOther__saveWrapper__save}
                  onClick={this.handleSaveSpriteClick}
                >保存</div>
                <div className={styles.kidsOther__saveWrapper__cancel}
                  onClick={this.handleCancelClick}>取消</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  spriteId: state.kids.userPanel.showSaveSpritePanel,
  isLogin: state.kids.userPanel.isLogin,
  spriteName: state.kids.userPanel.spriteName,
  spriteComment: state.kids.userPanel.spriteComment,
  selectedSpriteLabelId: state.kids.userPanel.selectedSpriteLabelId,
  spriteLabelList: state.kids.userPanel.spriteLabelList,
  progress: state.kids.app.progress,

  spriteList: state.kids.userPanel.spriteList,

  createSpriteBlobById: (spriteId) => state.scratchGui.vm.exportSprite.call(state.scratchGui.vm, spriteId),
  getSpriteById: (id) => state.scratchGui.targets.sprites[id],

  editingTarget: state.scratchGui.targets.editingTarget, // 当前编辑的素材
  vm: state.scratchGui.vm,

});

const mapDispatchToProps = (dispatch) => ({
  // setPanelShow: (id) => dispatch(setShowSaveSpritePanel(id)),
  setShowLabelPanel: (win) => dispatch(setShowLabelPanel(win)),
  setSelectedSpriteLabel: (id) => dispatch(setSelectedSpriteLabel(id)),

  closePanel: () => dispatch(setShowSaveSpritePanel('')),
  setSpriteName: (name) => dispatch(setSpriteName(name)),
  setSpriteComment: (comment) => dispatch(setSpriteComment(comment)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
  setProgress: (progress) => dispatch(setProgress(progress)),
  setSpriteList: (list) => dispatch(setSpriteList(list)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveSprite);