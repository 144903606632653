import React, { Component } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import InfoBasic from '../InfoBasic';
import InfoMore from '../InfoMore';
import { connect } from 'react-redux';
const { actions: {
  setAvatar, setUserPanelMode, getLoginInfo, setDrawBoard
} } = require('../../../../../reducers/kids-user-panel');
const { actions: { setToastShow } } = require('../../../../../reducers/kids-app');
import closeIcon from 'static/closeIcon.png';
import ToolsDraw from 'static/tools_draw.svg';

class Info extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCloseClick',
      'handleDrawBoardClick',
      'changeUsername',
      'changeEmail',
      'changeBirthday',
      'changeSchool',
      'changeClassroom',
      'changeGender'
    ]);
    const { username, email, gender, birthday, school, classroom } = this.props;
    this.state = {
      tabBar: 1,
      username,
      email,
      // avatar, // 头像通过redux直接丢给子组件，此处父子需要耦合
      gender,
      birthday,
      school,
      classroom
    };
  }

  componentDidMount() { }

  changeUsername(username) {
    this.setState({ username });
  }

  changeEmail(email) {
    this.setState({ email });
  }

  changeBirthday(birthday) {
    this.setState({ birthday });
  }

  changeSchool(school) {
    this.setState({ school });
  }

  changeClassroom(classroom) {
    this.setState({ classroom });
  }

  changeGender(gender) {
    this.setState({ gender });
  }

  handleCloseClick() {
    this.props.closePanel();
  }

  handleDrawBoardClick() {
    this.props.openDrawBoard();
    this.props.closePanel();
  }

  render() {
    const {
      tabBar, username, email, gender,
      avatar, birthday,
      school, classroom
    } = this.state;
    return (
      <div className={styles.kidsPerson__create}>
        <div className={styles.kidsPerson__createWrapper + ' ' + styles.kidsPerson__infoWrapper}>

          <div className={styles.kidsPerson__drawBoard}
            onClick={this.handleDrawBoardClick}
          >
            <img src={ToolsDraw} />
          </div>


          <div className={styles.kidsPerson__header}>

            <div className={
              styles.kidsPerson__title + ' ' +
              styles['kidsPerson__title--selected'] + ' ' +
              (tabBar !== 1 && styles['kidsPerson__title--unSelected'])
            }
              onClick={() => { this.setState({ tabBar: 1 }) }}
            >基本资料</div>

            <div className={
              styles.kidsPerson__title + ' ' +
              styles['kidsPerson__title--selected'] + ' ' +
              (tabBar !== 2 && styles['kidsPerson__title--unSelected'])
            }
              onClick={() => { this.setState({ tabBar: 2 }) }}
            >更多信息</div>

            <img className={styles.kidsPerson__close}
              src={closeIcon}
              onClick={this.handleCloseClick}
            />
          </div>
          {tabBar === 1
            ? (
              <InfoBasic
                username={username}
                email={email}
                avatar={this.props.avatar} // 菜单栏头像和此处需同步发生变化，均响应redux的变化
                gender={gender}
                setAvatar={this.props.setAvatar}
                changeUsername={this.changeUsername}
                changeGender={this.changeGender}
                changeEmail={this.changeEmail}
                changeAvatar={this.changeAvatar}
                getLoginInfo={this.props.getLoginInfo}
                showSuccess={this.props.showSuccess}
                showError={this.props.showError}
              />
            )
            : (
              <InfoMore
                birthday={birthday}
                school={school}
                classroom={classroom}
                changeBirthday={this.changeBirthday}
                changeSchool={this.changeSchool}
                changeClassroom={this.changeClassroom}
                getLoginInfo={this.props.getLoginInfo}
                showSuccess={this.props.showSuccess}
                showError={this.props.showError}
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.kids.userPanel.username,
  email: state.kids.userPanel.email,
  birthday: state.kids.userPanel.birthday,
  school: state.kids.userPanel.school,
  classroom: state.kids.userPanel.classroom,
  avatar: state.kids.userPanel.avatar,
  gender: state.kids.userPanel.gender
});

const mapDispatchToProps = (dispatch) => ({
  closePanel: () => dispatch(setUserPanelMode(0)),
  openDrawBoard: () => dispatch(setDrawBoard(1)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
  getLoginInfo: () => getLoginInfo(dispatch),
  setAvatar: (avatar) => dispatch(setAvatar(avatar))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Info);