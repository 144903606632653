import React, { Component } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import { updateUserInfo } from '../../../../../lib/fetch-api';

class InfoMore extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleBirthdayChange',
      'handleSchoolChange',
      'handleClassroomChange',
      'handleSaveMoreInfoClick'
    ]);
  }

  handleBirthdayChange(e) {
    this.props.changeBirthday(e.target.value);
  }

  handleSchoolChange(e) {
    this.props.changeSchool(e.target.value);
  }

  handleClassroomChange(e) {
    this.props.changeClassroom(e.target.value);
  }

  handleSaveMoreInfoClick() {
    // 更多信息提交
    const {
      birthday, school, classroom,
      showError, showSuccess, getLoginInfo
    } = this.props;
    // 如果生日存在，则判断birthday正则
    if (birthday && !/\d{4}-\d{2}-\d{2}/.test(birthday)) {
      showError('生日格式错误哦，填写如：2012-03-08')
      return;
    }
    // 其余为空也可以提交
    updateUserInfo({
      birthday,
      school,
      classroom
    }).then(res => {
      if (res.errno === -1) {
        showError('保存失败');
        return;
      }
      showSuccess('保存成功');
      // onCloseBtnClick();
      getLoginInfo(); // 强制重新获取数据并渲染视图
    })
  }

  render() {
    const { birthday, school, classroom } = this.props;;
    return (
      <div>
        <div style={{ padding: '20px' }}>
          <div className={styles.kidsPerson__moreInfo}>
            <div className={styles.kidsPerson__userInfo__info}>
              <div className={styles.kidsPerson__userInfoItem}>
                <div className={styles.kidsPerson__userInfoItem__text}>出生年月:</div>
                <input
                  className={styles.kidsPerson__userInfoItem__input}
                  type="text"
                  placeholder='填写如: 2012-01-30'
                  value={birthday || ''}
                  onChange={this.handleBirthdayChange}
                />
              </div>
              <div className={styles.kidsPerson__userInfoItem}>
                <div className={styles.kidsPerson__userInfoItem__text}>学校 :</div>
                <input
                  className={styles.kidsPerson__userInfoItem__input}
                  type="text"
                  placeholder='填写你的学校'
                  value={school || ''}
                  onChange={this.handleSchoolChange}
                />
              </div>
              <div className={styles.kidsPerson__userInfoItem}>
                <div className={styles.kidsPerson__userInfoItem__text}>班级 :</div>
                <input
                  className={styles.kidsPerson__userInfoItem__input}
                  type="text"
                  placeholder='填写你的班级'
                  value={classroom || ''}
                  onChange={this.handleClassroomChange}
                />
              </div>
              <div className={styles.kidsPerson__userInfoSave}
                onClick={this.handleSaveMoreInfoClick}
              >保存</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoMore;