/** 
 * kids-app.js: 通用状态：
 * toast confirm popup(带输入框提醒) select
 * loading progress
 */

const setToastShow = 'kids/app/SET_TOAST_SHOW';
const setToastClose = 'kids/app/SET_TOAST_CLOSE';
const setConfirmShow = 'kids/app/SET_CONFIRM_SHOW';
const setConfirmClose = 'kids/app/SET_CONFIRM_CLOSE';
const setPopupShow = 'kids/app/SET_POPUP_SHOW';
const setPopupInput = 'kids/app/SET_POPUP_INPUT'; // 设计跨组件传值，暂用redux传递
const setPopupClose = 'kids/app/SET_POPUP_CLOSE';
const setSelectShow = 'kids/app/SET_SELECT_SHOW';
const setSelectClose = 'kids/app/SET_SELECT_CLOSE';
const setSelectIndex = 'kids/app/SET_SELECT_INDEX'; // 设计跨组件传值，暂用redux传递
const setProgress = 'kids/app/SET_PROGRESS';
const setLoading = 'kids/app/SET_LOADING';
const setOfficialSite = 'kids/userPanel/SET_OFFICIAL_SITE'; // 是否是官方站点

const initialState = {
  // 弹窗相关
  toast: {
    mode: '',
    text: ''
  },
  confirm: {
    content: '',
    confirmEvent: () => { },
    confirmText: '',
    cancelEvent: () => { },
    cancelText: ''
  },
  popup: {
    title: '',
    tip: '',
    input: '',
    placeholder: '',
    confirmText: '',
    confirmEvent: () => { },
    cancelText: '',
    cancelEvent: () => { },
    top: null,
    left: null
  },
  select: {
    title: '',
    index: '',
    list: [],
    confirmText: '',
    confirmEvent: () => { },
    cancelText: '',
    cancelEvent: () => { },
  },
  // 进度条
  progress: 0,
  // 加载中
  loading: false,
  officialSite: false
};

const actions = {
  // 弹窗相关
  setToastShow: (mode, text) => ({
    type: setToastShow,
    mode,
    text
  }),
  setToastClose: () => ({
    type: setToastClose
  }),
  setConfirmShow: ({
    content = '',
    confirmEvent = '',
    confirmText = '确认',
    cancelEvent = '',
    cancelText = '取消'
  }) => ({
    type: setConfirmShow,
    content,
    confirmEvent,
    confirmText,
    cancelEvent,
    cancelText
  }),
  setConfirmClose: () => ({
    type: setConfirmClose
  }),
  setPopupShow: ({
    title = '输入内容',
    tip = '',
    input = '',
    placeholder = '',
    confirmText = '确定',
    confirmEvent = '',
    cancelText = '取消',
    cancelEvent = '',
    top = null, // 默认为居中，设值使弹窗更灵活
    left = null
  }) => ({
    type: setPopupShow,
    title,
    tip,
    input,
    placeholder,
    confirmText,
    confirmEvent,
    cancelText,
    cancelEvent,
    top,
    left
  }),
  setPopupClose: () => ({
    type: setPopupClose
  }),
  setPopupInput: (input) => ({
    type: setPopupInput,
    input
  }),
  setSelectShow: ({
    title = '输入内容',
    index = '',
    list = [],
    confirmText = '确定',
    confirmEvent = '',
    cancelText = '取消',
    cancelEvent = ''
  }) => ({
    type: setSelectShow,
    title,
    index,
    list,
    confirmText,
    confirmEvent,
    cancelText,
    cancelEvent,
  }),
  setSelectClose: () => ({
    type: setSelectClose
  }),
  setSelectIndex: (index) => ({
    type: setSelectIndex,
    index
  }),
  setProgress: (progress) => ({
    type: setProgress,
    progress
  }),
  setLoading: (status) => ({
    type: setLoading,
    status
  }),
  setOfficialSite: (mode) => ({
    type: setOfficialSite,
    mode
  })
};

const reducer = (state = initialState, action) => {
  const {
    title, tip, input, placeholder, top, left,
    content, confirmEvent, confirmText, cancelEvent, cancelText,
    index, list
  } = action; // 解构所有switch可能要使用的字段
  switch (action.type) {
    case setToastShow:
      return {
        ...state,
        toast: {
          mode: action.mode,
          text: action.text
        }
      };
    case setToastClose:
      return {
        ...state,
        toast: initialState.toast
      };
    case setConfirmShow:
      return {
        ...state,
        confirm: {
          content,
          confirmEvent,
          confirmText,
          cancelEvent,
          cancelText
        }
      };
    case setConfirmClose:
      return {
        ...state,
        confirm: initialState.confirm
      }
    case setPopupShow:
      return {
        ...state,
        popup: {
          title,
          tip,
          input,
          placeholder,
          confirmText,
          confirmEvent,
          cancelText,
          cancelEvent,
          top,
          left
        }
      };
    case setPopupClose:
      return {
        ...state,
        popup: initialState.popup
      }
    case setPopupInput:
      return {
        ...state,
        popup: {
          ...state.popup,
          input
        }
      }
    case setSelectShow:
      return {
        ...state,
        select: {
          title,
          index,
          list,
          confirmText,
          confirmEvent,
          cancelText,
          cancelEvent
        }
      }
    case setSelectClose:
      return {
        ...state,
        select: initialState.select
      }
    case setSelectIndex:
      return {
        ...state,
        select: {
          ...state.select,
          index
        }
      }
    case setProgress:
      return {
        ...state,
        progress: action.progress
      }
    case setLoading:
      return {
        ...state,
        loading: action.status
      }
    case setOfficialSite:
      return {
        ...state,
        officialSite: action.mode
      }
    default:
      return state;
  }
};

export {
  reducer as default,
  actions
};