import ls from './localStorage'

const mainDefaultColor = 'hsla(215, 100%, 65%, 1)'
const rootDefaultColor = 'hsla(215, 100%, 95%, 1)'
const workLeftDefaultColor = `fill: #F9F9F9;`
const sideDefaultColor = `background-color: #FFFFFF;`
const sideSelectedDefaultColor = 'background: #E9EEF2'

let originStylesInnerHTML = null
let originStyles = null

const changeText2text = (from, to) => {
  if (!from || !to) {
    return
  }
  originStyles.forEach(style => {
    const { innerHTML } = style
    if (innerHTML.includes(from)) {
      style.innerHTML = innerHTML.replaceAll(from, to)
    }
  })
}

const resetColor = (key) => {
  // 手动还原颜色
  if (!key) {
    // 还原颜色
    originStyles.forEach((style, index) => {
      style.innerHTML = originStylesInnerHTML[index]
    })

    // 还原ls
    ls.set('mainColor', '')
    ls.set('rootColor', '')
    ls.set('workLeftColor', '')
    ls.set('sideColor', '')
    ls.set('sideSelectedColor', '')
  }
}

const initialDraw = () => {
  document.addEventListener('DOMContentLoaded', () => {
    originStyles = Array.from(document.querySelectorAll('style'))
    originStylesInnerHTML = Array.from(document.querySelectorAll('style')).map(style => style.innerHTML)

    changeText2text(mainDefaultColor, ls.get('mainColor'))
    changeText2text(rootDefaultColor, ls.get('rootColor'))
    changeText2text(workLeftDefaultColor, ls.get('workLeftColor'))
    changeText2text(sideDefaultColor, ls.get('sideColor'))
    changeText2text(sideSelectedDefaultColor, ls.get('sideSelectedColor'))
  });
}

const getColor = (name) => {
  switch (name) {
    case 'mainColor':
      return ls.get(name) || mainDefaultColor
    case 'rootColor':
      return ls.get(name) || rootDefaultColor
    case 'workLeftColor':
      return ls.get(name) || workLeftDefaultColor
    case 'sideColor':
      return ls.get(name) || sideDefaultColor
    case 'sideSelectedColor':
      return ls.get(name) || sideSelectedDefaultColor
  }
}

export {
  mainDefaultColor,
  rootDefaultColor,
  workLeftDefaultColor,
  sideDefaultColor,
  sideSelectedDefaultColor,
  getColor,
  changeText2text,
  resetColor,
  initialDraw
}