import React, { Component } from 'react';
import styles from './style.css'
import classNames from 'classnames'
import closeIcon from 'static/closeIcon.png';
import bindAll from 'lodash.bindall'
import { connect } from 'react-redux';
import { getExampleWork } from '../../../../../lib/fetch-api'
import { fetchWork } from '../../../../../lib/utils/mixin-api';
const { actions: {
  setToastShow, setLoading
} } = require('../../../../../reducers/kids-app');
const { actions: {
  setProjectName, setProjectComment,
} } = require('../../../../../reducers/kids-user-panel');

class ExampleWork extends Component {

  constructor(props) {
    super(props)
    bindAll(this, [
      'handleBarBtnClick',
      'handleCloseBtnClick',
      'handleWorkClick'
    ])

    this.state = {
      list: [],
      showPanel: false
    }
  }

  componentDidMount() {
    this._getWorks()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.showPanel !== nextState.showPanel) {
      this._getWorks()
      return true;
    }
    if (this.state.list !== nextState.list) {
      return true;
    }
    return false;
  }

  _getWorks() {
    getExampleWork().then(res => {
      if (res.errno === 0) {
        this.setState({ list: res.data })
      }
    })
  }

  handleWorkClick(work) {
    const {
      showSuccess,
      showError,
      setProjectName,
      setProjectComment
    } = this.props;
    // 先请求sb3文件，再丢给loadProject
    fetchWork(this.props)(work.file_path).then(() => {
      showSuccess('作品打开成功');
      // 将name comment存入redux
      setProjectName(work.name);
      setProjectComment(work.comment);
      this.toSetPanel(false)
    }).catch(err => {
      console.log(err)
      showError('作品打开失败');
    })
  }

  handleBarBtnClick() {
    this.toSetPanel(true)
  }

  handleCloseBtnClick() {
    this.toSetPanel(false)
  }

  toSetPanel(mode) {
    this.setState({ showPanel: mode })
  }

  render() {

    const { list, showPanel } = this.state;

    return (
      <div className={styles.example}>
        <div className={styles.exampleBtn}
          onClick={this.handleBarBtnClick}
        >
          示例作品
        </div>

        {/* 逻辑控制显隐 */}
        {
          showPanel ? (
            <div className={styles.exampleModal}>
              <div className={styles.exampleModalContainer}>
                <div className={styles.exampleModalContainerHeader}>
                  <p className={styles.exampleModalContainerHeaderTitle}>示例作品</p>
                  <img className={styles.exampleModalContainerHeaderCloseIcon}
                    src={closeIcon}
                    onClick={this.handleCloseBtnClick}
                  />
                </div>
                <div className={styles.work}>
                  <div className={styles.workContainer}>
                    {
                      list.map(work => (
                        <div
                          className={styles.workBox}
                          key={work.id}
                          onClick={() => this.handleWorkClick(work)}
                        >
                          <img
                            className={styles.workBoxImg}
                            src={work.cover_path}
                          />
                          <p className={styles.workBoxTitle}>{work.name}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (<></>)
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vm: state.scratchGui.vm,
  loadProject: state.scratchGui.vm.loadProject.bind(state.scratchGui.vm),
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (status) => dispatch(setLoading(status)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
  setProjectName: (name) => dispatch(setProjectName(name)),
  setProjectComment: (comment) => dispatch(setProjectComment(comment)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExampleWork);
