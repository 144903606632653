import React, { Component } from "react";
import bindAll from "lodash.bindall";
import styles from "./style.css";
import { connect } from "react-redux";
const {
  actions: {
    setShowLabelPanel,
    updateUserWorkLabel,
    updateUserSpriteLabel,
    setSelectedWorkLabel,
    setCurrentWorkLabel,
    setSelectedSpriteLabel,
    setCurrentSpriteLabel,
  },
} = require("../../../../../reducers/kids-user-panel");
const {
  actions: {
    setPopupShow,
    setPopupClose,
    setConfirmShow,
    setConfirmClose,
    setToastShow,
  },
} = require("../../../../../reducers/kids-app");
import {
  addUserWorkLabel,
  deleteUserWorkLabel,
  editUserWorkLabel,
  updateUserWorkLabelShareCode,
  checkUserWorkLabelShareCode,
  addUserSpriteLabel,
  deleteUserSpriteLabel,
  editUserSpriteLabel,
  updateUserSpriteLabelShareCode,
  checkUserSpriteLabelShareCode,
} from "../../../../../lib/fetch-api";
import closeIcon from "static/closeIcon.png";

class Label extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      "handleCancelClick",
      "renderLabelList",
      "handleSwitchClick",
      "handleBottomBtnClick",
      "handleLabelItemClick",
    ]);
    this.state = {
      status: 0, // 我们规定 0无 1加 2删 3编 4共享
    };
  }

  handleBottomBtnClick(index) {
    // 获取作品标签视窗数据，用于定位popup
    const { status } = this.state;
    if (index === 1) {
      this.setState({ status: status === 1 ? 0 : 1 });
      this.addBeforePupup(); // 呼出popup窗口
    } else if (index === 2) {
      this.setState({ status: status === 2 ? 0 : 2 });
    } else if (index === 3) {
      this.setState({ status: status === 3 ? 0 : 3 });
    } else if (index === 4) {
      this.setState({ status: status === 4 ? 0 : 4 });
    }
  }

  addBeforePupup() {
    const wrapper = this.refs.wrapper.getBoundingClientRect();
    const { panel } = this.props;
    this.props.setPopupShow({
      title: `增加${panel === 1 ? "作品标签" : "素材标签"}`,
      tip: `${panel === 1 ? "作品标签" : "素材标签"}不要重名哦`,
      placeholder: "输入标签名称",
      confirmText: "添加",
      confirmEvent:
        panel === 1
          ? this.execAddLabel.bind(this, "work")
          : this.execAddLabel.bind(this, "sprite"),
      cancelEvent: () => {
        this.setState({ status: 0 });
        this.props.setPopupClose();
      },
      top: wrapper.top,
      left: wrapper.left + wrapper.width,
    });
  }

  handleLabelItemClick(label) {
    const { panel } = this.props; // 获取当前面板
    const { status } = this.state; // 获取按钮状态
    const wrapper = this.refs.wrapper.getBoundingClientRect();
    if (status === 2) {
      // 弹出Confirm
      this.props.setConfirmShow({
        content: `确认删除${panel === 1 ? "作品标签" : "素材标签"}?`,
        confirmEvent:
          panel === 1
            ? this.execDeleteLabel.bind(this, "work", label)
            : this.execDeleteLabel.bind(this, "sprite", label),
      });
    } else if (status === 3) {
      // 弹出Popup
      this.props.setPopupShow({
        title: "修改名称",
        tip: "",
        input: label.label,
        confirmText: "修改",
        confirmEvent:
          panel === 1
            ? this.execEditLabel.bind(this, "work", label)
            : this.execEditLabel.bind(this, "sprite", label),
        top: wrapper.top,
        left: wrapper.left + wrapper.width,
      });
    } else if (status === 4) {
      // 弹出Popup
      const code = label.share_code;
      this.props.setPopupShow({
        title: `${code ? "修改" : "创建"}${
          panel === 1 ? "作品" : "素材"
        }共享码`,
        tip: `无共享码，即关闭共享`,
        // tip: '共享码不要含有特殊字符',
        input: code === null ? "" : code,
        confirmText: `${code ? "修改" : "创建"}`,
        confirmEvent:
          panel === 1
            ? this.execShareLabel.bind(this, "work", label)
            : this.execShareLabel.bind(this, "sprite", label),
        top: wrapper.top,
        left: wrapper.left + wrapper.width,
      });
    }
  }

  duplicateLabel(type) {
    const { labelInput, showError, workLabelList, spriteLabelList } =
      this.props;
    // 作品 | 素材
    let list;
    if (type === "work") {
      list = workLabelList;
    } else if (type === "sprite") {
      list = spriteLabelList;
    }
    if (!labelInput) {
      showError("标签名不能为空");
      return;
    }
    if (list.some((item) => item.label === labelInput)) {
      showError("标签重名，换一个名称吧");
      return;
    }
    return true;
  }

  execAddLabel(type) {
    const {
      labelInput,
      showSuccess,
      setPopupClose,
      updateUserWorkLabel,
      updateUserSpriteLabel,
    } = this.props;
    let currentAddFn = type === "work" ? addUserWorkLabel : addUserSpriteLabel;
    let currentUpdate =
      type === "work" ? updateUserWorkLabel : updateUserSpriteLabel;
    // 比对作品label的标签名称，是否重名
    if (!this.duplicateLabel(type)) {
      return;
    }
    currentAddFn({
      label: labelInput,
    }).then((res) => {
      if (res.errno === -1) {
        return;
      }
      showSuccess("添加成功");
      this.setState({ status: 0 });
      currentUpdate().then(() => {
        setPopupClose();
      });
    });
  }

  execShareLabel(type, label) {
    const {
      labelInput,
      showSuccess,
      showError,
      setPopupClose,
      updateUserWorkLabel,
      updateUserSpriteLabel,
    } = this.props;
    let currentDuplicateFn =
      type === "work"
        ? checkUserWorkLabelShareCode
        : checkUserSpriteLabelShareCode;
    let currentAddFn =
      type === "work"
        ? updateUserWorkLabelShareCode
        : updateUserSpriteLabelShareCode;
    let currentUpdate =
      type === "work" ? updateUserWorkLabel : updateUserSpriteLabel;
    // 若共享码为空，则为清除共享码
    if (!labelInput) {
      // 清除共享码
      currentAddFn({
        labelId: label.id,
        share_code: "",
      }).then((res) => {
        if (res.errno === -1) {
          showError(res.msg);
          return;
        }
        showSuccess("清除共享码成功");
        currentUpdate().then(() => {
          setPopupClose();
        });
      });
      return;
    }
    if (/\s+/.test(labelInput)) {
      showError("请不要输入空格");
      return;
    }
    // 查重共享码
    currentDuplicateFn({
      share_code: labelInput,
    }).then((res) => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      // 执更新共享码
      currentAddFn({
        labelId: label.id,
        share_code: labelInput,
      }).then((res2) => {
        if (res.errno === -1) {
          showError(res2.msg);
          return;
        }
        showSuccess(res2.msg);
        currentUpdate().then(() => {
          setPopupClose();
        });
      });
    });
  }

  execDeleteLabel(type, label) {
    const {
      showSuccess,
      updateUserWorkLabel,
      updateUserSpriteLabel,
      setConfirmClose,
      selectedWorkLabelId,
      setSelectedWorkLabel,
      currentWorkLabelId,
      setCurrentWorkLabel,
      selectedSpriteLabelId,
      setSelectedSpriteLabel,
      currentSpriteLabelId,
      setCurrentSpriteLabel,
    } = this.props;
    let currentDeleteFn =
      type === "work" ? deleteUserWorkLabel : deleteUserSpriteLabel;
    let currentUpdate =
      type === "work" ? updateUserWorkLabel : updateUserSpriteLabel;
    currentDeleteFn({
      labelId: label.id,
    }).then((res) => {
      if (res.errno === -1) {
        return;
      }
      showSuccess("删除成功");
      currentUpdate().then((list) => {
        // 判断是否删除了redux中selectedWorkLabelId(保存作品页)
        if (type === "work") {
          if (label.id === selectedWorkLabelId) {
            // 使标签id置0
            setSelectedWorkLabel(0);
          }
          // 或者删除的标签是redux中currentWorkLabelId(我的创作页)
          if (label.id === currentWorkLabelId) {
            setCurrentWorkLabel(0);
          }
        } else {
          if (label.id === selectedSpriteLabelId) {
            // 使标签id置0
            setSelectedSpriteLabel(0);
          }
          // 或者删除的标签是redux中currentWorkLabelId(我的创作页)
          if (label.id === currentSpriteLabelId) {
            setCurrentSpriteLabel(0);
          }
        }
        setConfirmClose();
      });
    });
  }

  execEditLabel(type, label) {
    const {
      showSuccess,
      setPopupClose,
      labelInput,
      updateUserWorkLabel,
      updateUserSpriteLabel,
    } = this.props;
    let currentEditFn =
      type === "work" ? editUserWorkLabel : editUserSpriteLabel;
    let currentUpdate =
      type === "work" ? updateUserWorkLabel : updateUserSpriteLabel;
    if (!this.duplicateLabel("work")) {
      return;
    }
    currentEditFn({
      labelId: label.id,
      label: labelInput,
    }).then((res) => {
      if (res.errno === -1) {
        return;
      }
      showSuccess("修改成功");
      currentUpdate().then((list) => {
        setPopupClose();
      });
    });
  }

  handleCancelClick() {
    this.props.changePanel(0);
    this.setState({ status: 0 });
  }

  handleSwitchClick() {
    const { panel } = this.props;
    this.props.changePanel(panel === 1 ? 2 : 1);
  }

  renderLabelList(list) {
    const { status } = this.state;
    const { panel } = this.props;
    if (list.length === 1) {
      return (
        <p className={styles.kidsLabel__labelNoItemText}>
          还没有标签哦，赶紧创建吧
        </p>
      );
    }
    return list.slice(1).map((label, index) => {
      return (
        <li
          key={index}
          className={
            styles.kidsLabel__labelItem +
            " " +
            (status === 2 && styles["kidsLabel__labelItemDelete--active"]) +
            " " +
            (status === 3 && styles["kidsLabel__labelItemEdit--active"]) +
            " " +
            (status === 4 && styles["kidsLabel__labelItemShare--active"])
          }
          onClick={this.handleLabelItemClick.bind(this, label)}
        >
          <div
            className={
              "iconfont" +
              " " +
              (status === 2 && "icon-jian1") +
              " " +
              (status === 3 && "icon-bianji1") +
              " " +
              (status === 4 && !label.share_code && "icon-wenjianjia") +
              " " +
              (status === 4 && label.share_code && "icon-wenjianjia2") +
              " " +
              styles.kidsLabel__labelIconFloat +
              " " +
              (status === 2 && styles.kidsLabel__labelIconDelete) +
              " " +
              (status === 3 && styles.kidsLabel__labelIconEdit) +
              " " +
              (status === 4 && styles.kidsLabel__labelIconShare)
            }
          ></div>
          <div className={styles.kidsLabel__labelItemText}>{label.label}</div>
        </li>
      );
    });
  }

  render() {
    const { status } = this.state;
    const { panel, workLabelList, spriteLabelList } = this.props;
    return panel > 0 ? (
      <div className={styles.kidsLabel__mask}>
        <div className={styles.kidsLabel__wrapper} ref="wrapper">
          <div className={styles.kidsLabel__header}>
            <div className={styles.kidsLabel__title}>
              {panel === 1 ? "作品标签" : "素材标签"}
            </div>
            <img
              className={styles.kidsLabel__close}
              src={closeIcon}
              onClick={this.handleCancelClick}
            />
          </div>
          <div
            className={
              styles.kidsLabel__labelContainer +
              " " +
              (panel === 1 && styles["kidsLabel__labelContainer--Left"]) +
              " " +
              (panel === 2 && styles["kidsLabel__labelContainer--right"])
            }
          >
            <ul className={styles.kidsLabel__labelBox}>
              {this.renderLabelList(workLabelList)}
            </ul>
            <ul className={styles.kidsLabel__labelBox}>
              {this.renderLabelList(spriteLabelList)}
            </ul>
          </div>
          <div className={styles.kidsLabel__footer}>
            <div className={styles.kidsLabel__labelBtn}>
              <div
                className={
                  "iconfont icon-jia " +
                  styles.kidsLabel__labelBtnIconAdd +
                  " " +
                  (status === 1 && styles["kidsLabel__labelBtnIcon--selected"])
                }
                onClick={this.handleBottomBtnClick.bind(this, 1)}
              ></div>
              <div
                className={
                  "iconfont icon-jian " +
                  styles.kidsLabel__labelBtnIconDelete +
                  " " +
                  (status === 2 && styles["kidsLabel__labelBtnIcon--selected"])
                }
                onClick={this.handleBottomBtnClick.bind(this, 2)}
              ></div>
              <div
                className={
                  "iconfont icon-bianji " +
                  styles.kidsLabel__labelBtnIconEdit +
                  " " +
                  (status === 3 && styles["kidsLabel__labelBtnIcon--selected"])
                }
                onClick={this.handleBottomBtnClick.bind(this, 3)}
              ></div>
              <div
                className={
                  "iconfont icon-wenjianjia " +
                  styles.kidsLabel__labelBtnIconFolder +
                  " " +
                  (status === 4 && styles["kidsLabel__labelBtnIcon--selected"])
                }
                onClick={this.handleBottomBtnClick.bind(this, 4)}
              ></div>
            </div>
            <div className={styles.kidsLabel__labelBtnBox}>
              <div className={styles.kidsLabel__labelBtnText}>添加</div>
              <div className={styles.kidsLabel__labelBtnText}>删除</div>
              <div className={styles.kidsLabel__labelBtnText}>编辑</div>
              <div className={styles.kidsLabel__labelBtnText}>共享</div>
            </div>
            <div
              className={styles.kidsLabel__switch}
              onClick={this.handleSwitchClick}
            >
              {panel === 1 ? "查看素材标签" : "查看作品标签"}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  panel: state.kids.userPanel.showLabelPanel,
  workLabelList: state.kids.userPanel.workLabelList,
  spriteLabelList: state.kids.userPanel.spriteLabelList,
  labelInput: state.kids.app.popup.input,
  selectedWorkLabelId: state.kids.userPanel.selectedWorkLabelId,
  currentWorkLabelId: state.kids.userPanel.currentWorkLabelId,
});
const mapDispatchToProps = (dispatch) => ({
  changePanel: (win) => dispatch(setShowLabelPanel(win)),
  setPopupShow: (obj) => dispatch(setPopupShow(obj)),
  setPopupClose: () => dispatch(setPopupClose()),
  setConfirmShow: (obj) => dispatch(setConfirmShow(obj)),
  setConfirmClose: () => dispatch(setConfirmClose()),
  updateUserWorkLabel: () => updateUserWorkLabel(dispatch),
  updateUserSpriteLabel: () => updateUserSpriteLabel(dispatch),
  showSuccess: (text) => dispatch(setToastShow("success", text)),
  showError: (text) => dispatch(setToastShow("error", text)),
  setSelectedWorkLabel: (id) => dispatch(setSelectedWorkLabel(id)),
  setCurrentWorkLabel: (id) => dispatch(setCurrentWorkLabel(id)),
  setSelectedSpriteLabel: (id) => dispatch(setSelectedSpriteLabel(id)),
  setCurrentSpriteLabel: (id) => dispatch(setCurrentSpriteLabel(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Label);
