import React, { Component } from 'react';
import styles from './style.css';

const OFFSET_LEFT = 16;
const OFFSET_TOP = 2;

/**
 * Bubble: 气泡框组件
 * @param {string} text 冒泡文字
 * @param {number} top 距离顶部位置 
 * @param {number} left 距离侧边位置 
 */
class Bubble extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { text, top, left } = this.props;
    return (
      <div
        className={styles.bubbleBox}
        style={{
          top: `${top + OFFSET_TOP}px`,
          left: `${left + OFFSET_LEFT}px`
        }}
      >
        {text}
      </div>
    );
  }
}

export default Bubble;