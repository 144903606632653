/**
 * kids-outer-panel.js: GUI窗口状态，注册，第三方窗口状态
 * 设计较早，逻辑较杂，暂不优化。
 */

const setOuterPanelMode = 'kids/outerPanel/SET_OUTER_PANEL_MODE';

const setLoginPhone = 'kids/outerPanel/SET_LOGIN_PHONE';
const setLoginPassword = 'kids/outerPanel/SET_LOGIN_PASSWORD';
const setLoginCode = 'kids/outerPanel/SET_LOGIN_CODE';

const setRole = 'kids/outerPanel/SET_ROLE';
const setPhone = 'kids/outerPanel/SET_PHONE';
const setCode = 'kids/outerPanel/SET_CODE';
const setPassword = 'kids/outerPanel/SET_PASSWORD';
const setRePassword = 'kids/outerPanel/SET_RE_PASSWORD';
const setInviteCode = 'kids/outerPanel/SET_INVITE_CODE';
const setRegisterLoginCode = 'kids/outerPanel/SET_REGISTER_LOGIN_CODE';
const setIsAgree = 'kids/outerPanel/SET_IS_AGREE';
const setPhoneTipSuccess = 'kids/outerPanel/SET_PHONE_TIP_SUCCESS';
const setPhoneTipError = 'kids/outerPanel/SET_PHONE_TIP_ERROR';

const setRecoverProgress = 'kids/outerPanel/SET_RECOVER_PROGRESS';
const setRecoverCode = 'kids/outerPanel/SET_RECOVER_CODE';
const setRecoverPassword = 'kids/outerPanel/SET_RECOVER_PASSWORD';
const setRecoverRePassword = 'kids/outerPanel/SET_RECOVER_RE_PASSWORD';


const initialState = {
  // 外窗口，0隐藏1登2注册3找回4微信
  outerPanelMode: 0,
  // 登录页状态
  loginPhone: '',
  loginPassword: '',
  loginCode: '',
  // 注册页状态
  role: 1,
  phone: '',
  code: '',
  password: '',
  rePassword: '',
  inviteCode: '',
  registerLoginCode: '',
  isAgree: false,
  phoneTipSuccess: '',
  phoneTipError: '',
  // 密码找回页状态
  recoverProgress: 1,
  recoverCode: '',
  recoverPassword: '',
  recoverRePassword: ''
  // 微信登录页状态
};

const actions = {
  // 切换外窗口
  setOuterPanelMode: (mode) => ({
    type: setOuterPanelMode,
    mode
  }),
  // 登录页状态
  setLoginPhone: (phone) => ({
    type: setLoginPhone,
    phone
  }),
  setLoginPassword: (password) => ({
    type: setLoginPassword,
    password
  }),
  setLoginCode: (code) => ({
    type: setLoginCode,
    code
  }),
  // 注册页状态
  setRole: (role) => ({
    type: setRole,
    role
  }),
  setPhone: (phone) => ({
    type: setPhone,
    phone
  }),
  setCode: (code) => ({
    type: setCode,
    code
  }),
  setPassword: (password) => ({
    type: setPassword,
    password
  }),
  setRePassword: (password) => ({
    type: setRePassword,
    password
  }),
  setInviteCode: (code) => ({
    type: setInviteCode,
    code
  }),
  setRegisterLoginCode: (code) => ({
    type: setRegisterLoginCode,
    code
  }),
  setIsAgree: (isAgree) => ({
    type: setIsAgree,
    isAgree
  }),
  setPhoneTipSuccess: (text) => ({
    type: setPhoneTipSuccess,
    text
  }),
  setPhoneTipError: (text) => ({
    type: setPhoneTipError,
    text
  }),
  // 密码找回页状态
  setRecoverProgress: (progress) => ({
    type: setRecoverProgress,
    progress
  }),
  setRecoverCode: (code) => ({
    type: setRecoverCode,
    code
  }),
  setRecoverPassword: (password) => ({
    type: setRecoverPassword,
    password
  }),
  setRecoverRePassword: (password) => ({
    type: setRecoverRePassword,
    password
  })
  // 微信登录页状态
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setOuterPanelMode:
      return { ...state, outerPanelMode: action.mode };

    case setLoginPhone:
      return { ...state, loginPhone: action.phone };
    case setLoginPassword:
      return { ...state, loginPassword: action.password };
    case setLoginCode:
      return { ...state, loginCode: action.code };

    case setRole:
      return { ...state, role: action.role };
    case setPhone:
      return { ...state, phone: action.phone };
    case setCode:
      return { ...state, code: action.code };
    case setPassword:
      return { ...state, password: action.password };
    case setRePassword:
      return { ...state, rePassword: action.password };
    case setInviteCode:
      return { ...state, inviteCode: action.code };
    case setRegisterLoginCode:
      return { ...state, registerLoginCode: action.code };
    case setIsAgree:
      return { ...state, isAgree: action.isAgree };
    case setPhoneTipSuccess:
      return { ...state, phoneTipSuccess: action.text };
    case setPhoneTipError:
      return { ...state, phoneTipError: action.text };

    case setRecoverProgress:
      return { ...state, recoverProgress: action.progress };
    case setRecoverCode:
      return { ...state, recoverCode: action.code };
    case setRecoverPassword:
      return { ...state, recoverPassword: action.password };
    case setRecoverRePassword:
      return { ...state, recoverRePassword: action.password };

    default: return state;
  }
};

export {
  reducer as default,
  actions
};
