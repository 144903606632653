import React, { Component } from "react";
import styles from "./style.css";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
const {
  actions: {
    setToastShow,
    setProgress,
    setLoading,
    setConfirmShow,
    setConfirmClose,
  },
} = require("../../../../../reducers/kids-app");
const {
  actions: {
    setShowSaveProjectPanel,
    setProjectName,
    setProjectComment,
    setShowLabelPanel,
    setSelectedWorkLabel,
    setProjectList,
    setShareLabel,
    logout,
  },
} = require("../../../../../reducers/kids-user-panel");
import {
  convertBase64ToBlob,
  debounce,
} from "../../../../../lib/utils/kids-tools";
import {
  uploadProjectInfo,
  reportError,
  checkLogin,
  findWorkByShareCode,
} from "../../../../../lib/fetch-api";
import { randomId } from "../../../../../lib/utils/kids-tools";
import createOSS from "../../../../../lib/utils/kids-oss-operator";
import {
  getWorkCoverPath,
  getWorkFilePath,
} from "../../../../../lib/utils/config";
import {
  autoSaveWorkController
} from "../../../../../lib/utils/auto-save-work";
import cn from "classnames";
import errorIcon from "static/icon-error.png";
import successIcon from "static/icon-success.png";

class SaveWork extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      "handleCancelClick",
      "handleProjectNameChange",
      "handleShareLabelChange",
      "handleProjectCommentChange",
      "handleSaveClick",
      "handleSaveProjectClick",
      "handleManageLabelClick",
      "handleSelectChange",
    ]);

    // 将实例（单例）方法导出
    autoSaveWorkController.handler = this.handleSaveClick

    this.toGetShareLabelInfo = debounce(this.toGetShareLabelInfo, 200);

    this.projectInfo = {};
    this.uploadTimer = null; // 防轰炸定时器
    this.uploadFlag = false; // 上传标志位
    this.isAliOSSUploadAbort = false; // 阿里中断逻辑
    this.createOSSCoverInstance = null; // 上传 cover 实例
    this.createOSSSB3Instance = null; // 上传 sb3 实例
    this.coverProgress = 0; // 封面进度
    this.sb3Progress = 0; // 作品进度
    this.second = 10;

    this.state = {
      sb3Size: "",
      shareLabelOk: false,
      shareLabelInfo: {},
    };
  }

  handleSelectChange(e) {
    const index = e.target.selectedIndex;
    if (index < 0) {
      // -1 即为无标签
      this.props.setSelectedWorkLabel(0);
      return;
    }
    const { workLabelList } = this.props;
    const { id } = workLabelList[index];
    this.props.setSelectedWorkLabel(id);
  }

  handleManageLabelClick() {
    this.props.setShowLabelPanel(1);
  }

  handleCancelClick() {
    // 检测到上传逻辑
    if (this.uploadFlag) {
      this._abortUploadConfirm();
    } else {
      this.props.closePanel();
    }
  }

  handleProjectNameChange(e) {
    this.props.setProjectName(e.target.value);
  }

  // 共享码变更
  handleShareLabelChange(e) {
    const value = e.target.value;
    this.props.setShareLabel(value);
    if (value) {
      this.toGetShareLabelInfo(value);
    } else {
      this.setState({
        shareLabelOk: false,
        shareLabelInfo: {},
      });
    }
  }

  toGetShareLabelInfo(shareLabel) {
    const { userId } = this.props;

    findWorkByShareCode({
      share_code: shareLabel,
    }).then((res) => {
      const { errno, data } = res;
      if (errno === -1) {
        this.setState({
          shareLabelOk: false,
          shareLabelInfo: {error: "共享码错误"},
        });
      } else {
        // 不允许上传到自己的共享标签
        if (data.userId === userId) {
          this.setState({
            shareLabelOk: false,
            shareLabelInfo: { ...data, error: "不允许上传至自己的共享标签，请以作品标签进行上传" },
          });
        } else {
          this.setState({
            shareLabelOk: true,
            shareLabelInfo: data,
          });
        }
      }
    });
  }

  handleProjectCommentChange(e) {
    this.props.setProjectComment(e.target.value);
  }

  handleSaveClick() {
    const {
      isLogin,
      showError,
      setPanelShow,
      setLoading,
      saveProjectSb3,
      saveProjectCover,
    } = this.props;

    // if (!isLogin) {
    //   showError('请先登录哦');
    //   return;
    // }

    // ++ 这里需要 请求一下后端 是否真的有登录态
    checkLogin()
      .then(({ errno, data }) => {
        if (errno !== 0) {
          return Promise.reject("账号未登录，请重新登录");
        }
      })
      .then(() => {
        setPanelShow(true);
        // 先获取舞台的作品
        setLoading(true); // 解析舞台作品 解析未完毕前，不允许保存
        saveProjectSb3().then((sb3Blob) => {
          // 获取作品体积
          this.setState({
            sb3Size: Number(sb3Blob.size / 1024 / 1024).toFixed(2),
          });
          // 再获取舞台的封面
          const coverBlob = convertBase64ToBlob(saveProjectCover());
          // 将blob均转成file,携带标识
          const sb3 = new File([sb3Blob], `${randomId()}.sb3`, {
            type: "application/x.scratch.sb3",
          });
          const cover = new File([coverBlob], `${randomId()}.png`, {
            type: "image/png",
          });
          // 存入实例变量
          this.projectInfo.sb3 = sb3;
          this.projectInfo.cover = cover;
          setLoading(false);

          // 清除共享码
          this.handleShareLabelChange({
            target: {
              value: ""
            }
          })

        });
      })
      .catch((err) => {
        showError(err);
        // 退出登录
        this.props.logout();
      });
  }

  handleSaveProjectClick() {
    const {
      projectName,
      projectComment,
      shareLabel,
      selectedWorkLabelId: projectLabelId,
      showError,
      showSuccess,
      setProgress,
      projectList,
      setProjectList,
    } = this.props;

    const {
      shareLabelOk,
      shareLabelInfo: {id: tbl_share_label_id}
    } = this.state

    // 如果共享码存在，且共享标识错误
    if(shareLabel && !shareLabelOk) {
      showError('请填写正确的共享码，或清除它')
      return
    }

    if (this.uploadFlag) {
      // 检测到当前作品正在上传中，是否中断并重新上传
      this._abortUploadConfirm();
      return;
    }

    // 防止上传轰炸
    if (this.uploadTimer) {
      showError("请等待" + this.second + "秒再尝试");
      return;
    }

    // 存入实例变量
    this.projectInfo.project_name = projectName;
    this.projectInfo.project_comment = projectComment;
    this.projectInfo.project_labelId = projectLabelId;
    // Object.entries(this.projectInfo).forEach(([key, value]) => {

    // })
    if (!this.projectInfo.project_name) {
      showError("请输入作品名称");
      return;
    }

    // 提前设定进度条读数（避免用户感知异常）
    setProgress(1);

    // 开始上传逻辑
    this.uploadFlag = true;

    // 确认无误，先向OSS发送存储请求，再往后端携带数据
    this.createOSSCoverInstance = createOSS();
    this.createOSSSB3Instance = createOSS();
    Promise.all([
      this.createOSSCoverInstance.upload(
        getWorkCoverPath(this.projectInfo.cover.name),
        this.projectInfo.cover,
        (progress) => {
          // 避免延迟不同步导致进度条再次出现
          if (!this.uploadFlag) {
            return;
          }
          this.coverProgress = progress;
          setProgress(
            parseInt(((this.coverProgress + this.sb3Progress) / 2) * 100) || 1
          );
        }
      ),
      this.createOSSSB3Instance.upload(
        getWorkFilePath(this.projectInfo.sb3.name),
        this.projectInfo.sb3,
        (progress) => {
          // 避免延迟不同步导致进度条再次出现
          if (!this.uploadFlag) {
            return;
          }
          this.sb3Progress = progress;
          setProgress(
            parseInt(((this.coverProgress + this.sb3Progress) / 2) * 100) || 1
          );
        }
      ),
    ])
      .then(() => {
        // OSS上传完毕，后端入库
        return uploadProjectInfo({
          workInfo: {
            ...this.projectInfo,
            tbl_share_label_id,
            file_path: this.projectInfo.sb3.name,
            cover_path: this.projectInfo.cover.name,
          },
        });
      })
      .then(({ errno, data }) => {
        if (errno !== 0) {
          // ex: 捕获错误
          reportError({
            error: {
              errno,
              data,
            },
          });
          showError("保存失败");
          return;
        }
        showSuccess("保存作品成功");
        // 更新redux
        setProjectList([data, ...projectList]);
        this.props.closePanel();
        this.uploadFlag = false;
        // 计时器倒计时
        this.uploadTimer = setInterval(() => {
          this.second -= 1;
          if (this.second <= 0) {
            clearInterval(this.uploadTimer);
            this.uploadTimer = null;
            this.second = 10;
          }
        }, 1000);

        // 重置 autoSaveWork
        autoSaveWorkController.init()
      })
      .catch((err) => {
        reportError({
          error: err,
        });
        // console.dir(err)
        // 1、abort 产生的 e
        if (err.name === "cancel") {
          // 不作任何逻辑
        } else if (err.name === "RequestError") {
          showError("网络中断，请确认网络环境");
          this.resetUploadFlag();
        } else if (err.name === "ConnectionTimeoutError") {
          showError("网络过慢，超60秒未应答，请切换网络");
          this.resetUploadFlag();
        } else {
          showError("未知错误: " + err.name);
          this.resetUploadFlag();
        }
      });
  }

  // 中断上传提示框
  _abortUploadConfirm() {
    const { showError, showConfirm, closeConfirm, setLoading } = this.props;
    showConfirm({
      content: "检测到有作品正在上传中，是否中断",
      confirmEvent: () => {
        setLoading(true);
        // 运行态中断上传 ajax
        Promise.all([
          this.createOSSCoverInstance.abort(),
          this.createOSSSB3Instance.abort(),
        ]).finally((e) => {
          setLoading(false);
          showError("上传已中断");
          this.isAliOSSUploadAbort = true;
          closeConfirm();
          this.resetUploadFlag();
        });
      },
    });
  }

  resetUploadFlag() {
    // 重置
    this.uploadFlag = false;
    clearInterval(this.uploadTimer);
    this.uploadTimer = null;
    this.second = 0;
    this.props.setProgress(0);
  }

  render() {
    const {
      isLogin,
      showPanel,
      projectName,
      projectComment,
      shareLabel,
      workLabelList,
      selectedWorkLabelId,
    } = this.props;

    const { sb3Size, shareLabelOk, shareLabelInfo } = this.state;

    return (
      <div className={styles.kidsOther__saveBtnWrapper}>
        <div
          className={styles.kidsOther__saveBtn}
          onClick={this.handleSaveClick}
        >
          保存
        </div>
        {isLogin && showPanel ? (
          <div>
            <div className={styles.kidsOther__windowMask}></div>
            <div className={styles.kidsOther__saveWrapper}>
              <div className={styles.kidsOther__saveWrapper__header}>
                <p className={styles.kidsOther__saveWrapper__title}>保存作品</p>
                <img
                  className={styles.kidsOther__saveWrapper__close}
                  src="/static/closeIcon.png"
                  onClick={this.handleCancelClick}
                />
              </div>
              <div className={styles.kidsOther__saveWrapper__section}>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p
                    className={cn(
                      styles.kidsOther__saveWrapper__item__title,
                      styles.required
                    )}
                  >
                    作品名称:
                  </p>
                  <input
                    className={styles.kidsOther__saveWrapper__item__input}
                    type="text"
                    maxLength="20"
                    placeholder="输入作品名称，不超过20个字"
                    value={projectName}
                    onChange={this.handleProjectNameChange}
                  />
                </div>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={styles.kidsOther__saveWrapper__item__title}>
                    作品说明:
                  </p>
                  <textarea
                    className={styles.kidsOther__saveWrapper__item__textarea}
                    maxLength="300"
                    placeholder="输入作品说明，不超过300个字，默认为空"
                    value={projectComment}
                    onChange={this.handleProjectCommentChange}
                  />
                  <span className={styles.kidsOther__saveWrapper__item__count}>
                    {projectComment.length} /300
                  </span>
                </div>
                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={styles.kidsOther__saveWrapper__item__title}>
                    作品标签:
                  </p>
                  <select
                    className={cn(
                      styles.kidsOther__saveWrapper__select,
                      styles.input__auto
                    )}
                    value={selectedWorkLabelId}
                    onChange={this.handleSelectChange}
                  >
                    {workLabelList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <div
                    className={styles.kidsOther__saveWrapper__managerSelect}
                    onClick={this.handleManageLabelClick}
                  >
                    管理标签
                  </div>
                </div>

                <div className={styles.kidsOther__saveWrapper__item}>
                  <p className={cn(styles.kidsOther__saveWrapper__item__title)}>
                    作品共享:
                  </p>
                  <input
                    className={cn(
                      styles.kidsOther__saveWrapper__item__input,
                      styles.input__auto
                    )}
                    name="shareLabel"
                    autoComplete="on"
                    type="text"
                    maxLength="20"
                    placeholder="请输入老师提供的共享码"
                    value={shareLabel}
                    onChange={this.handleShareLabelChange}
                  />
                  {shareLabel &&
                    (shareLabelOk ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "38px",
                        }}
                      >
                        <img
                          style={{ margin: "0 6px" }}
                          src={successIcon}
                          width="16"
                        />
                        <span style={{ color: "#3e77ff" }}>
                          {shareLabelInfo.label}
                        </span>
                        <span style={{ color: "#aaa", paddingLeft: "3px" }}>
                          ({shareLabelInfo.username})
                        </span>
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "38px",
                        }}
                      >
                        <img
                          style={{ margin: "0 6px" }}
                          src={errorIcon}
                          width="16"
                        />
                        <span style={{ color: "red" }}>{shareLabelInfo.error}</span>
                      </span>
                    ))}
                </div>
                <p className={cn(styles.tip)}>
                  输入共享码后，作品会分享至对应的目录下。
                </p>
              </div>
              <div className={styles.kidsOther__saveWrapper__btn}>
                <div
                  className={styles.kidsOther__saveWrapper__save}
                  onClick={this.handleSaveProjectClick}
                >
                  保存
                  <span style={{ letterSpacing: "0px" }}>
                    {sb3Size ? `(${sb3Size}mb)` : ""}
                  </span>
                </div>
                <div
                  className={styles.kidsOther__saveWrapper__cancel}
                  onClick={this.handleCancelClick}
                >
                  取消
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  showPanel: state.kids.userPanel.showSaveProjectPanel,
  isLogin: state.kids.userPanel.isLogin,
  userId: state.kids.userPanel.id,
  projectName: state.kids.userPanel.projectName || "",
  projectComment: state.kids.userPanel.projectComment || "",
  shareLabel: state.kids.userPanel.shareLabel,
  selectedWorkLabelId: state.kids.userPanel.selectedWorkLabelId,
  selectedLabelName: state.kids.userPanel.selectedLabelName,
  workLabelList: state.kids.userPanel.workLabelList,
  progress: state.kids.app.progress,

  projectList: state.kids.userPanel.projectList,

  saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
  saveProjectCover: state.scratchGui.vm.saveProjectCover.bind(
    state.scratchGui.vm
  ),
});

const mapDispatchToProps = (dispatch) => ({
  setPanelShow: () => dispatch(setShowSaveProjectPanel(true)),
  setShowLabelPanel: (win) => dispatch(setShowLabelPanel(win)),
  setShareLabel: (shareLabel) => dispatch(setShareLabel(shareLabel)),
  setSelectedWorkLabel: (id) => dispatch(setSelectedWorkLabel(id)),

  closePanel: () => dispatch(setShowSaveProjectPanel(false)),
  setProjectName: (name) => dispatch(setProjectName(name)),
  setProjectComment: (comment) => dispatch(setProjectComment(comment)),
  showSuccess: (text) => dispatch(setToastShow("success", text)),
  showError: (text) => dispatch(setToastShow("error", text)),
  showConfirm: (objArgs) => dispatch(setConfirmShow(objArgs)),
  closeConfirm: () => dispatch(setConfirmClose()),

  setProgress: (progress) => dispatch(setProgress(progress)),
  setLoading: (mode) => dispatch(setLoading(mode)),

  setProjectList: (list) => dispatch(setProjectList(list)),

  logout: () => logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveWork);
