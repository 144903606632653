/**
 * 领域实体状态：充分对已浏览的UserPage或WorkContent进行缓存
 * 注：原本打算使用react-thunk，进行异步使用。
 *  但是scratch自身使用了中间件，而且似乎已破坏了最原始的工作流。
 *   故只能按原始方法传递dispatch，在kids-page中注入实体
 *   其实对于实体的开设，我觉得更适合移动端，但是我觉得PC端也可以（配合响应式布局）
 *   然而实体开放后，对用户的行为需要做出及时的数据变化，故entities数据量较大
 *   尚不清楚影响，但是单页的效果颇佳。
 *   下述reducer的数据也是遵循immutable规范的，但是解构方式显得有些不优雅，也暂不论。
 */

// action type
const addWorkContentEntity = 'kids/entities/ADD_WORK_CONTENT_ENTITY';
const addUserEntity = 'kids/entities/ADD_USER_ENTITY';
const addPublishWork = 'kids/entities/ADD_PUBLISH_WORK'; // 已发布作品推入
const delMyWork = 'kids/entities/DEL_MY_WORK'; // 我的作品删除
const delPublishWork = 'kids/entities/DEL_PUBLISH_WORK'; // 已发布作品删除
const addUserFan = 'kids/entities/ADD_USER_FAN'; //（对方） 增加粉丝（关注）
const addUserConcern = 'kids/entities/ADD_USER_CONCERN'; //（自身）增加关注（关注）
const delUserFan = 'kids/entities/DEL_USER_FAN'; //（对方）删除粉丝（取消关注）
const delUserConcern = 'kids/entities/DEL_USER_CONCERN'; //（自身）删除关注（取消关注）
const updateConcerns = 'kids/entities/UPDATE_CONCERNS'; // 每次单击关注面板，进行刷新
const addWorkCollected = 'kids/entities/ADD_WORK_COLLECTED'; // 修改收藏关系
const delWorkCollected = 'kids/entities/DEL_WORK_COLLECTED'; // 修改收藏关系

const setWorkZanCount = 'kids/entities/SET_WORK_ZAN_COUNT'; // 修改赞数量
const setClearEntities = 'kids/entities/SET_CLEAR_ENTITIES'; // 重置领域实体

// action state
const initialState = {
  workContent: {},
  user: {}
}

// action creator
const actions = {
  addWorkContentEntity: (entity) => ({
    type: addWorkContentEntity,
    entity
  }),
  addUserEntity: (entity) => ({
    type: addUserEntity,
    entity
  }),
  addUserFan: (id, info) => ({
    type: addUserFan,
    id,
    info
  }),
  addPublishWork: (id, info) => ({
    type: addPublishWork,
    id,
    info
  }),
  delMyWork: (id, workId) => ({
    type: delMyWork,
    id,
    workId
  }),
  delPublishWork: (id, workId) => ({
    type: delPublishWork,
    id,
    workId
  }),
  addUserConcern: (id, info) => ({
    type: addUserConcern,
    id,
    info
  }),
  delUserFan: (id, userId) => ({
    type: delUserFan,
    id,
    userId
  }),
  delUserConcern: (id, userId) => ({
    type: delUserConcern,
    id,
    userId
  }),
  updateConcerns: (id, info) => ({
    type: updateConcerns,
    id,
    info
  }),
  setWorkZanCount: (id, count = 1) => ({
    type: setWorkZanCount,
    id,
    count
  }),
  addWorkCollected: (id, info) => ({
    type: addWorkCollected,
    id,
    info
  }),
  delWorkCollected: (id, workId) => ({
    type: delWorkCollected,
    id,
    workId
  }),
  setClearEntities: () => ({
    type: setClearEntities
  })
}

const reducer = (state = initialState, action) => {
  const { entity, id, userId, workId, mode, count, info } = action;
  switch (action.type) {
    case addWorkContentEntity:
      return {
        ...state, workContent: {
          ...state.workContent, ...entity
        }
      }
    case addUserEntity:
      return {
        ...state, user: {
          ...state.user, ...entity
        }
      }
    /**
     * 以下API是对实体数据进行修改
     */
    // 已发布作品推入
    case addPublishWork:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            publishWork: [...state.user[id].publishWork, info]
          }
        }
      }
    // 我的作品删除
    case delMyWork:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            myWork: state.user[id].myWork.filter(i => i.id !== workId)
          }
        }
      }
    // 已发布作品删除
    case delPublishWork:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            publishWork: state.user[id].publishWork.filter(i => i.id !== workId)
          }
        }
      }
    // 添加 & 删除 粉丝
    case addUserFan:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            fans: [...state.user[id].fans, info]
          }
        }
      }
    case delUserFan:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            fans: state.user[id].fans.filter(f => f.userId != userId)
          }
        }
      }
    // 添加 & 删除 关注
    case addUserConcern:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            concerns: [...state.user[id].concerns, info]
          }
        }
      }
    case delUserConcern:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            concerns: state.user[id].concerns.filter(f => f.userId != userId)
          }
        }
      }
    // 更新用户关注面板
    case updateConcerns:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            ...info,
          }
        }
      }
    // 添加 & 删除 已收藏
    case addWorkCollected:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            collect: [...state.user[id].collect, info]
          }
        }
      }
    case delWorkCollected:
      return {
        ...state, user: {
          ...state.user,
          [id]: {
            ...state.user[id],
            collect: state.user[id].collect.filter(i => i.id != workId)
          }
        }
      }
    // 更改 点赞 & 已赞
    case setWorkZanCount:
      return {
        ...state, workContent: {
          ...state.workContent,
          [id]: {
            ...state.workContent[id],
            zaned: true,
            workInfo: {
              ...state.workContent[id].workInfo,
              zan: state.workContent[id].workInfo.zan + count
            }
          }
        }
      }
    case setClearEntities:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// selector
const getUserCollectedByWordId = (state, userId, workId) => {
  const entity = state.kids.entities.user[userId];
  return entity &&
    entity.collect &&
    entity.collect.some(i => i.id == workId)
}

export {
  reducer as default,
  actions,
  getUserCollectedByWordId
}