import ScratchBlocks from 'scratch-blocks'

// 固钉状态
window.nailFixed = false
let injectionDiv = null
let blocklyFlyout = null

// 样式选择器
const $ = (selector) => {
  return document.querySelector(selector);
}

/** 添加垃圾桶 */
export const addTrash = () => {

  // 相关元素获取
  injectionDiv = $('.injectionDiv');
  const blocklyWorkspaceDoms = document.querySelectorAll('.blocklyWorkspace')

  // 鼠标按下标志位
  let isMouseDown = false

  // 插入垃圾桶节点
  const trash = document.createElement('div');

  trash.className = 'blocklyFlyout__trash';
  injectionDiv.appendChild(trash);

  // 呼出垃圾桶
  const showTrash = (bool) => {
    if (bool) {
      trash.style.visibility = 'visible';
      trash.style.opacity = 1;
      trash.style.pointerEvents = 'all';
    } else {
      trash.style.visibility = 'hidden';
      trash.style.opacity = 0;
      trash.style.pointerEvents = 'none';
    }
  }

  function blockMouseDown(e) {
    const findBlocklyBlockCanvas = findParentDomSelector(e.target, '.blocklyBlockCanvas')
    const findBlocklyFlyout = findParentDomSelector(e.target, '.blocklyFlyout')
    // console.log({ findBlocklyBlockCanvas, findBlocklyFlyout })
    // 是积木 且非菜单
    if (findBlocklyBlockCanvas && !findBlocklyFlyout) {
      isMouseDown = true
      // 展示垃圾桶
      showTrash(true)
    }
  }

  function blockMouseMove(e) {
    if (!isMouseDown) {
      return
    }

    let x = 0;

    // 如果是触摸事件
    if (e.touches) {
      x = e.touches[0].clientX
    } else {
      x = e.clientX
    }

  }

  function blockMouseup(e) {
    if (!isMouseDown) {
      return
    }

    showTrash(false);
    isMouseDown = false
  }

  function workspaceMouseDown(e) {
    let currentDom = e.target;

    const findBlocklyWorkspace = findParentDomSelector(currentDom, '.blocklyWorkspace')

    // 如果工作区为第1个工作区（舞台）
    if (findBlocklyWorkspace === blocklyWorkspaceDoms[0] && !window.nailFixed) {
      // console.log('hideSidebar')
      hideSidebar()
    }

  }

  // 垃圾桶 - 按下
  document.addEventListener('mousedown', blockMouseDown, true);
  document.addEventListener('touchstart', blockMouseDown, true);

  // 垃圾桶 - 移动
  document.addEventListener('mousemove', blockMouseMove, true);
  document.addEventListener('touchmove', blockMouseMove, true);

  // 垃圾桶 - 抬起
  document.addEventListener('mouseup', blockMouseup, true);
  document.addEventListener('touchend', blockMouseup, true);

  // 舞台 - 点击
  document.addEventListener('mousedown', workspaceMouseDown, true);
  document.addEventListener('touchstart', workspaceMouseDown, true);

}

/** 添加固钉 */
export const addNail = () => {

  // 相关元素获取
  injectionDiv = $('.injectionDiv');
  blocklyFlyout = $('.blocklyFlyout');

  // 设置固钉
  const div = document.createElement('div');
  div.innerHTML = `
      <svg id="nail"  viewBox="0 -50 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11131" xmlns:xlink="http://www.w3.org/1999/xlink" width="440" height="30">
        <defs>
          <style type="text/css">@font-face { font-family: feedback-iconfont; src: url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff2?t=1630033759944") format("woff2"), url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff?t=1630033759944") format("woff"), url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.ttf?t=1630033759944") format("truetype"); }
          </style>
        </defs>
        <path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3-15.4 12.3-16.7 35.4-2.7 49.4l181.7 181.7-215.4 215.2c-2.6 2.6-4.3 6.1-4.6 9.8l-3.4 37.2c-0.9 9.4 6.6 17.4 15.9 17.4 0.5 0 1 0 1.5-0.1l37.2-3.4c3.7-0.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8zM666.2 549.3l-24.5 24.5 3.8 34.4c3.7 33.7 1 67.2-8.2 99.7-5.4 19-12.8 37.1-22.2 54.2L262 408.8c12.9-7.1 26.3-13.1 40.3-17.9 27.2-9.4 55.7-14.1 84.7-14.1 9.6 0 19.3 0.5 28.9 1.6l34.4 3.8 24.5-24.5L608.5 224 800 415.5 666.2 549.3z" p-id="11132" fill="#8a8a8a"></path>
        <rect class="injectionDiv__nail" fill-opacity="0" width="1024" height="1024">
        </rect>
      </svg>

      <svg id="nail-active" style="display: none" viewBox="0 -50 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11131" xmlns:xlink="http://www.w3.org/1999/xlink" width="440" height="30">
        <defs>
          <style type="text/css">@font-face { font-family: feedback-iconfont; src: url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff2?t=1630033759944") format("woff2"), url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff?t=1630033759944") format("woff"), url("//at.alicdn.com/t/font_1031158_u69w8yhxdu.ttf?t=1630033759944") format("truetype"); }
          </style>
        </defs>
        <path  d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3-15.4 12.3-16.6 35.4-2.7 49.4l181.7 181.7-215.4 215.2c-2.6 2.6-4.3 6.1-4.6 9.8l-3.4 37.2c-0.9 9.4 6.6 17.4 15.9 17.4 0.5 0 1 0 1.5-0.1l37.2-3.4c3.7-0.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8z" p-id="11671" fill="#2a99e3"></path>
        <rect class="injectionDiv__nail" fill-opacity="0" width="1024" height="1024">
        </rect>
      </svg>
    `
  const nail = div.children[0];
  const nailActive = div.children[1];

  // 插入固钉
  blocklyFlyout.appendChild(nail);
  blocklyFlyout.appendChild(nailActive);

  // 设置监听事件
  nail.querySelector(".injectionDiv__nail").addEventListener("touchend", toggleNail)
  nailActive.querySelector(".injectionDiv__nail").addEventListener("touchend", toggleNail)
  nail.querySelector(".injectionDiv__nail").addEventListener("mouseup", toggleNail)
  nailActive.querySelector(".injectionDiv__nail").addEventListener("mouseup", toggleNail)

  // 是否切换固钉
  function toggleNail() {
    window.nailFixed = !window.nailFixed

    // 固定模式
    if (window.nailFixed) {
      nail.style.display = "none"
      nailActive.style.display = "block"
    } else {
      nail.style.display = "block"
      nailActive.style.display = "none"
    }
  }

}

// 是否存在父级
function findParentDomSelector(dom, parentDomSelector) {

  // 如果是 类选择器
  if (parentDomSelector.startsWith('.')) {
    parentDomSelector = parentDomSelector.slice(1)
    while (dom !== null) {
      if (dom.classList && dom.classList.contains(parentDomSelector)) {
        return dom
      }
      dom = dom.parentNode
    }
    return false
  }

}

function hideSidebar() {
  const flyout = ScratchBlocks.getMainWorkspace().getFlyout()
  flyout.simpleHide()
}

function showSiderbar() {
  // const flyout = ScratchBlocks.getMainWorkspace().getFlyout()
  // flyout.show()
}
