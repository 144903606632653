import React, { Component } from 'react';
import bindAll from 'lodash.bindall';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux'
import { actions as userPanelActions } from '../../../reducers/kids-user-panel'
import { actions as appActions } from '../../../reducers/kids-app'
import styles from './style.css';
import closeIcon from 'static/closeIcon.png';
import ls from '../../../lib/utils/localStorage'
import { resetColor, changeText2text, getColor } from '../../../lib/utils/draw-board'

const { setDrawBoard } = userPanelActions
const { setToastShow } = appActions

class DrawBoard extends Component {

  constructor(props) {
    super(props)
    bindAll(this, [
      'handleCloseClick',
      'toHandleGlobalBtnsChange',
      'toHandleDarkBcgChange',
      'toHandleWorkspaceLeftBcgChange',
      'toHandleSideBarChange',
      'toHandleSideBarSelectedChange',
      'handleGlobalBtnsChange',
      'handleDarkBcgChange',
      'handleWorkspaceLeftBcgChange',
      'handleSideBarChange',
      'handleSideBarSelectedChange',
      'handleReset'
    ]);

    this.state = {
      mainColor: getColor('mainColor'),
      rootColor: getColor('rootColor'),
      workLeftColor: getColor('workLeftColor'),
      sideColor: getColor('sideColor'),
      sideSelectedColor: getColor('sideSelectedColor')
    }

    this.handleGlobalBtnsChange = debounce(this.handleGlobalBtnsChange, 50)
    this.handleDarkBcgChange = debounce(this.handleDarkBcgChange, 50)
    this.handleWorkspaceLeftBcgChange = debounce(this.handleWorkspaceLeftBcgChange, 50)
    this.handleSideBarChange = debounce(this.handleSideBarChange, 50)
    this.handleSideBarSelectedChange = debounce(this.handleSideBarSelectedChange, 50)

  }

  toHandleGlobalBtnsChange(e) {
    this.handleGlobalBtnsChange(e.target.value)
  }

  toHandleDarkBcgChange(e) {
    this.handleDarkBcgChange(e.target.value)
  }

  toHandleWorkspaceLeftBcgChange(e) {
    this.handleWorkspaceLeftBcgChange(e.target.value)
  }

  toHandleSideBarChange(e) {
    this.handleSideBarChange(e.target.value)
  }

  toHandleSideBarSelectedChange(e) {
    this.handleSideBarSelectedChange(e.target.value)
  }

  // 主色
  handleGlobalBtnsChange(currentColor) {
    changeText2text(this.state.mainColor, currentColor)
    this.setState({
      mainColor: currentColor
    })
    ls.set('mainColor', currentColor)
  }

  // 底色
  handleDarkBcgChange(currentColor) {
    changeText2text(this.state.rootColor, currentColor)
    this.setState({
      rootColor: currentColor
    })
    ls.set('rootColor', currentColor)
  }

  // 工作区左侧
  handleWorkspaceLeftBcgChange(currentColor) {
    changeText2text(this.state.workLeftColor, `fill: ${currentColor};`)
    this.setState({
      workLeftColor: `fill: ${currentColor};`
    })
    ls.set('workLeftColor', `fill: ${currentColor};`)
  }

  // 侧边栏
  handleSideBarChange(currentColor) {
    changeText2text(this.state.sideColor, `background-color: ${currentColor};`)
    this.setState({
      sideColor: `background-color: ${currentColor};`
    })
    ls.set('sideColor', `background-color: ${currentColor};`)
  }

  // 侧边栏选中色
  handleSideBarSelectedChange(currentColor) {
    changeText2text(this.state.sideSelectedColor, `background: ${currentColor};`)
    this.setState({
      sideSelectedColor: `background: ${currentColor};`
    })
    ls.set('sideSelectedColor', `background: ${currentColor};`)
  }

  // 全部还原
  handleReset() {
    resetColor()
    this.getLSColor()
  }

  // 获取存储的颜色
  getLSColor() {
    this.setState({
      mainColor: getColor('mainColor'),
      rootColor: getColor('rootColor'),
      workLeftColor: getColor('workLeftColor'),
      sideColor: getColor('sideColor'),
      sideSelectedColor: getColor('sideSelectedColor')
    })
  }

  // 转换颜色
  transferColor(char) {
    if (char.includes('hsla')) {
      if (char === 'hsla(215, 100%, 65%, 1)') {
        return '#4C97FF'
      } else if (char === 'hsla(215, 100%, 95%, 1)') {
        return '#E5F0FF'
      }
    } else {
      var reg = /(#.{6})/
      return (char.match(reg) && char.match(reg)[0]) || ''
    }
  }

  handleCloseClick() {
    this.props.closeDrawBoard()
  }

  render() {
    const { mainColor, rootColor, workLeftColor, sideColor, sideSelectedColor } = this.state
    return (
      <div className={styles.drawBoard}>

        <div className={styles.drawBoard__header}>
          <img className={styles.drawBoard__header__close}
            src={closeIcon}
            onClick={this.handleCloseClick}
          />
        </div>

        <div className={styles.drawBoard__work}>
          <dl>
            <dt>颜色修改：</dt>
            <dd>
              <span>1 主题色</span>
              <input
                type="color"
                value={this.transferColor(mainColor)}
                onChange={this.toHandleGlobalBtnsChange}
                ref="btnsRef"
              />
            </dd>
            <dd>
              <span>2 背景填充色</span>
              <input
                type="color"
                value={this.transferColor(rootColor)}
                onChange={this.toHandleDarkBcgChange}
                ref="darkBcgRef"
              />
            </dd>
            <dd>
              <span>3 工作区左侧颜色</span>
              <input
                type="color"
                value={this.transferColor(workLeftColor)}
                onChange={this.toHandleWorkspaceLeftBcgChange}
                ref="workspaceLeftRef"
              />
            </dd>
            <dd>
              <span>4.1 侧边栏颜色</span>
              <input
                type="color"
                value={this.transferColor(sideColor)}
                onChange={this.toHandleSideBarChange}
                ref="sideBarRef"
              />
            </dd>
            <dd>
              <span>4.2 侧边栏选中色</span>
              <input
                type="color"
                value={this.transferColor(sideSelectedColor)}
                onChange={this.toHandleSideBarSelectedChange}
                ref="sideBarSelectedRef"
              />
            </dd>
          </dl>
          <button className={styles.drawBoard__btn} onClick={this.handleReset}>全部还原</button>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  closeDrawBoard: () => dispatch(setDrawBoard(0)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawBoard);