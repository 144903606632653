import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  defaultProjectId,
  getIsFetchingWithoutId,
  setProjectId
} from '../reducers/project-state';

/* Higher Order Component to get the project id from location.hash
 * @param {React.Component} WrappedComponent: component to render
 * @returns {React.Component} component with hash parsing behavior
 */
const HashParserHOC = function (WrappedComponent) {
  class HashParserComponent extends React.Component {
    constructor(props) {
      super(props);
      bindAll(this, [
        'handleHashChange'
      ]);
    }
    componentDidMount() {
      window.addEventListener('hashchange', this.handleHashChange);
      this.handleHashChange();
    }
    componentDidUpdate(prevProps) {
      // if we are newly fetching a non-hash project...
      if (this.props.isFetchingWithoutId && !prevProps.isFetchingWithoutId) {
        // ...clear the hash from the url
        history.pushState('new-project', 'new-project',
          window.location.pathname + window.location.search);
      }
    }
    componentWillUnmount() {
      window.removeEventListener('hashchange', this.handleHashChange);
    }
    handleHashChange() {
      // const hashMatch = window.location.hash.match(/#(\d+)/); // scratch自身 仅匹配#就触发
      // const hashMatch = window.location.pathname.match(/^\/work\/(\d+)/); // 截取数字部分
      // const hashProjectId = hashMatch === null ? defaultProjectId : hashMatch[1];
      // this.props.setProjectId(`sb3_${hashProjectId.toString()}.sb3`);

      // const hashMatch = window.location.search && window.location.search.split('?')[1];
      // const params = {};
      // hashMatch.split('&').forEach(item => {
      //   const [field, value] = item.split('=');
      //   params[field] = value;
      // });
      // // 遍历后 params: {type: '1', wid: '20200117524434'}
      // this.props.setProjectId(params.wid);
    }
    render() {
      const {
        /* eslint-disable no-unused-vars */
        isFetchingWithoutId: isFetchingWithoutIdProp,
        reduxProjectId,
        setProjectId: setProjectIdProp,
        /* eslint-enable no-unused-vars */
        ...componentProps
      } = this.props;
      return (
        <WrappedComponent
          {...componentProps}
        />
      );
    }
  }
  HashParserComponent.propTypes = {
    isFetchingWithoutId: PropTypes.bool,
    reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setProjectId: PropTypes.func
  };
  const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
      isFetchingWithoutId: getIsFetchingWithoutId(loadingState),
      reduxProjectId: state.scratchGui.projectState.projectId
    };
  };
  const mapDispatchToProps = dispatch => ({
    setProjectId: projectId => {
      dispatch(setProjectId(projectId));
    }
  });
  // Allow incoming props to override redux-provided props. Used to mock in tests.
  const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, stateProps, dispatchProps, ownProps
  );
  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(HashParserComponent);
};

export {
  HashParserHOC as default
};
