import React, { Component } from 'react';
import styles from './style.css';

class Select extends Component {
  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  renderList() {
    const { list, index } = this.props;
    return list.map((item, idx) => (
      <li key={idx} className={
        styles.kidsSelect__item + ' ' +
        (idx === index && styles['kidsSelect__item--selected'])
      }
        onClick={this.handleItemClick.bind(this, idx)}
      >{item.label}</li>
    ));
  }

  handleItemClick(index) {
    this.props.setSelectIndex(index)
  }

  render() {
    const {
      title,
      confirmText, confirmEvent,
      cancelText, cancelEvent,
    } = this.props;
    return (
      <div className={styles.kidsSelect__wrapper}>
        <div className={styles.kidsSelect__main}>
          <div className={styles.kidsSelect__header}>
            <div className={styles.kidsSelect__title}>
              {title}
            </div>
          </div>
          <ul className={styles.kidsSelect__ul}>
            {this.renderList()}
          </ul>
          <div className={styles.kidsSelect__button}>
            <div className={styles.kidsSelect__btn}
              onClick={confirmEvent}
            >
              {confirmText}
            </div>
            <div className={styles.kidsSelect__btn}
              onClick={cancelEvent}
            >
              {cancelText}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Select;