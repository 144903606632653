import React, { Component, Fragment } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
const { actions: { setToastShow } } = require('../../../../../reducers/kids-app');
const { actions: { setOuterPanelMode } } = require('../../../../../reducers/kids-outer-panel');
const { actions: { setUserPanelMode, logout } } = require('../../../../../reducers/kids-user-panel');
import { defaultAvatar } from '../../../../../lib/utils/kids-config';
import menuUserIcon from 'static/menu_userIcon.png';
import menuPenIcon from 'static/menu_penIcon.png';
import menuPaintIcon from 'static/menu_paintIcon.png';
import menuExitIcon from 'static/menu_exitIcon.png';

class UserBtn extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleLoginBtnClick',
      'handleMenuItemClick'
    ]);
  }

  handleLoginBtnClick() {
    // 呼出登录页
    this.props.setOuterPanelMode(1);
  }

  handleMenuItemClick(index) {
    const { setUserPanelMode, showSuccess } = this.props;
    if (index === 1) {
      setUserPanelMode(1);
    }
    else if (index === 2) {
      setUserPanelMode(2);
    }
    else if (index === 3) {
      setUserPanelMode(3);
    }
    else if (index === 4) {
      this.props.logout();
    }
  }

  renderUserMenu() {
    return (
      <ul className={styles.kidsLogin__userMenu}>
        <li className={styles.kidsLogin__userMenu__item}
          onClick={this.handleMenuItemClick.bind(this, 1)}
        >
          <img className={styles.kidsLogin__userMenu__icon}
            src={menuUserIcon} />
          <p className={styles.kidsLogin__userMenu__text}>个人中心</p>
        </li>
        <li className={styles.kidsLogin__userMenu__item}
          onClick={this.handleMenuItemClick.bind(this, 2)}
        >
          <img className={styles.kidsLogin__userMenu__icon}
            src={menuPenIcon} />
          <p className={styles.kidsLogin__userMenu__text}>我的创作</p>
        </li>
        <li className={styles.kidsLogin__userMenu__item}
          onClick={this.handleMenuItemClick.bind(this, 3)}
        >
          <img className={styles.kidsLogin__userMenu__icon}
            src={menuPaintIcon} />
          <p className={styles.kidsLogin__userMenu__text}>我的素材</p>
        </li>
        <li className={styles.kidsLogin__userMenu__item}
          onClick={this.handleMenuItemClick.bind(this, 4)}
        >
          <img className={styles.kidsLogin__userMenu__icon}
            src={menuExitIcon} />
          <p className={styles.kidsLogin__userMenu__text}>退出登录</p>
        </li>
      </ul>
    )
  }

  render() {
    const { isLogin, avatar, role, username } = this.props;
    return (
      <Fragment>
        {isLogin ? (
          <div className={styles.kidsLogin__avatarWrapper}>
            <div className={styles.kidsLogin__avatarContent}>
              <div className={styles.kidsLogin__avatarInner}>
                <img
                  className={styles.kidsLogin__avatar}
                  src={!avatar ? defaultAvatar : avatar}
                  alt="user"
                />
              </div>
              <p className={styles.kidsLogin__avatar__name}>
                {role == 1 ? '学生:' : role == 2 ? '老师:' : ''} {username}
              </p>
              {/* 用户下拉菜单 */}
              {this.renderUserMenu()}
            </div>
          </div>
        ) : (
            <div className={styles.kidsLogin__avatarLogin}
              onClick={this.handleLoginBtnClick}
            >登录</div>
          )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  isLogin: state.kids.userPanel.isLogin,
  avatar: state.kids.userPanel.avatar,
  role: state.kids.userPanel.role,
  username: state.kids.userPanel.username,

  outerPanelMode: state.kids.outerPanel.outerPanelMode
});

const mapDispatchToProps = (dispatch) => ({
  setOuterPanelMode: (mode) => dispatch(setOuterPanelMode(mode)),
  setUserPanelMode: (mode) => dispatch(setUserPanelMode(mode)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
  logout: () => logout(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBtn);