import React, { Component } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
const { actions: {
  setRole, setPhone, setCode,
  setPassword, setRePassword, setInviteCode, setRegisterLoginCode,
  setIsAgree,
  setOuterPanelMode, setPhoneTipSuccess, setPhoneTipError
} } = require('../../../../../reducers/kids-outer-panel');
const { actions: { setToastShow } } = require('../../../../../reducers/kids-app');
const { actions: { submitLogin } } = require('../../../../../reducers/kids-user-panel');
import { register, checkPhoneExist, getCaptcha } from '../../../../../lib/fetch-api';
import closeIcon from 'static/closeIcon.png';
import studentIcon from 'static/studentIcon.png';
import teacherIcon from 'static/teacherIcon.png';
import userIcon from 'static/userIcon.png';
import correctIcon from 'static/correctIcon.png';
import codeIcon from 'static/codeIcon.png';
import passIcon from 'static/passIcon.png';
import invateIcon from 'static/invateIcon.png';

class Register extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCloseBtnClick',
      'handleRegisterClick',
      'handlePhoneInputChange',
      'toLoginArea',
      'refreshCaptcha'
    ]);

    this.state = {
      captcha: '' // 验证码svg
    };
  }

  // mounted 主要开设获取和刷新验证码
  componentDidMount() {
    this.refreshCaptcha();
  }

  // 重新验证码
  refreshCaptcha() {
    getCaptcha().then(rs => {
      this.setState({
        captcha: rs.data.svg
      });
    })
  }

  handleCloseBtnClick() {
    this.props.changePanel(0);
  }

  handlePhoneInputChange(e) {
    const { setPhone, setPhoneTipSuccess, setPhoneTipError } = this.props;
    // 清理残留
    setPhone(e.target.value);
    setPhoneTipSuccess('');
    setPhoneTipError('');
    // 待state渲染完毕，进行正则
    setTimeout(() => {
      const { phone } = this.props;
      const TEL_REGEXP = /^\d{11}$/;
      if (TEL_REGEXP.test(phone)) {
        this.onCheckPhoneExist(phone).then(res => {
          if (res.errno === -1) {
            setPhoneTipError('已被注册');
          } else {
            setPhoneTipSuccess('可以注册');
          }
        });
      } else {
        setPhoneTipError('格式有误');
      }
    }, 20);
  }

  onCheckPhoneExist(phone) {
    return checkPhoneExist({
      phone
    })
  }

  handleRegisterClick() {
    const { showError, showSuccess, officialSite } = this.props;
    // 表单验证 理论应采用validate 此处简判断
    const { role, phone, code, password, rePassword, inviteCode, registerLoginCode, isAgree } = this.props;

    /** 判断是否输入有空（目前仅判断，手机，密码，二次密码，验证码） */
    const checkInfo = { phone, password, rePassword, registerLoginCode };
    for (let key in checkInfo) {
      if (!checkInfo[key]) {
        showError('请填写完整信息');
        return;
      }
    }

    /** 1、手机号码判断 */
    const TEL_REGEXP = /^\d{11}$/;
    if (!TEL_REGEXP.test(phone)) {
      showError('请填写正确格式的手机号码');
      return;
    }

    /** 2、手机验证码判断 */
    // ...省略服务端验证相关逻辑

    /** 3、密码判断 */
    if (password.length < 6) {
      showError('密码不能小于6位');
      return;
    } else if (password !== rePassword) {
      showError('两次密码输入不一致');
      return;
    }

    /** 4、验证码判断 */
    // ... 暂略

    /** 5、协议同意判断 */
    if (!isAgree) {
      showError('请阅读并同意《KidsCoding用户服务协议》');
      return;
    }

    // 验证无误，采用fetch发送请求注册
    const registerInfo = { role, phone, password, inviteCode, captcha: registerLoginCode }
    register(registerInfo).then(res => {
      if (res.errno === -1) {
        showError(res.msg);
      } else {
        // console.log(res.data);
        showSuccess('注册成功!');
        /**
         * 发送登录请求
         * 状态入redux
         * 关闭视窗
         */
        this.props.submitLogin({
          phone,
          password,
          captcha: '', // 此处贴合登录的逻辑复用，后端将验证码清空 还是等于的关系。
          officialSite
        });
      }
    });

  }

  toLoginArea() {
    this.props.changePanel(1);
  }

  render() {
    const {
      role, phone, code,
      password, rePassword,
      inviteCode, registerLoginCode, isAgree,
      phoneTipSuccess, phoneTipError,
      setRole, setPhone, setCode, setPassword, setRePassword, setInviteCode, setRegisterLoginCode, setIsAgree
    } = this.props;
    return (
      <div>
        <div className={styles.kidsLogin__header}>
          <p className={styles.kidsLogin__title}>注册</p>
          <img className={styles.kidsLogin__close}
            src={closeIcon}
            onClick={this.handleCloseBtnClick} />
        </div>
        <div className={styles.kidsLogin__form}>
          <div className={styles.kidsLogin__roleWrapper}>
            {/* <p className={styles.kidsLogin__roleTitle}>选择身份</p> */}
            <div className={styles.kidsLogin__selectRole + ' ' +
              (role === 1 && styles['kidsLogin__selectRole--selected'])}
              onClick={() => setRole(1)}
            >
              <img className={styles.kidsLogin__roleIcon}
                src={studentIcon} />
              <span className={styles.kidsLogin__roleText}>学生</span>
            </div>
            <div className={styles.kidsLogin__selectRole + ' ' +
              (role === 2 && styles['kidsLogin__selectRole--selected'])}
              onClick={() => setRole(2)}
            >
              <img className={styles.kidsLogin__roleIcon}
                src={teacherIcon} />
              <span className={styles.kidsLogin__roleText}>老师</span>
            </div>
          </div>
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={userIcon} />
            <input type="text" placeholder='请输入手机号码'
              onChange={this.handlePhoneInputChange}
              maxLength='11'
              value={phone}
            />
            <div className={
              styles.kidsLogin__form__tip + ' ' +
              (phoneTipError && styles.kidsLogin__form__tipError)
            }>
              {phone.length === 11 && phoneTipError && phoneTipError}
              {phone.length === 11 && phoneTipSuccess && <img src={correctIcon} />}
            </div>
          </div>
          {/* <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={codeIcon} />
            <input type="text" placeholder='请输入短信验证码'
              onChange={(e) => setCode(e.target.value)}
              maxLength='4'
              value={code}
            />
            <p className={styles.kidsLogin__form__text}>获取短信验证码</p>
          </div> */}
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={passIcon} />
            <input type="password" placeholder='请输入密码'
              onChange={(e) => setPassword(e.target.value)}
              maxLength='20'
              value={password}
            />
          </div>
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={passIcon} />
            <input type="password" placeholder='请再次输入密码'
              onChange={(e) => setRePassword(e.target.value)}
              maxLength='20'
              value={rePassword}
            />
          </div>
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={invateIcon} />
            <input type="text" placeholder='请输入验证码'
              onChange={(e) => setRegisterLoginCode(e.target.value)}
              value={registerLoginCode}
            />
            <div
              className={styles.kidsLogin__form__item__code}
              onClick={this.refreshCaptcha}
              dangerouslySetInnerHTML={{ __html: this.state.captcha }}
            >
            </div>
            <div></div>
          </div>
          <div className={styles.kidsLogin__agreementWrapper}>
            <div className={styles.kidsLogin__agreementWrapper__btn}
              onClick={(e) => setIsAgree(!isAgree)}
            >
              <div className={
                `${styles.kidsLogin__agreementWrapper__btn__circle}
                  ${isAgree && styles['kidsLogin__agreementWrapper__btn__circle--selected']}`
              }></div>
            </div>
            <p className={styles.kidsLogin__agreementWrapper__text}
              onClick={(e) => setIsAgree(!isAgree)}
            >
              我已阅读并同意
                <a
                className={styles.kidsLogin__agreementWrapper__agreeLink}
                href='https://www.baidu.com'
                target='_blank'
              >《KidsCoding用户服务协议》</a>
            </p>
          </div>
          <div className={styles.kidsLogin__login}
            onClick={this.handleRegisterClick}>确定</div>
        </div>
        <div className={styles.kidsLogin__footer__backToLogin}
          onClick={this.toLoginArea}>
          返回登录
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.kids.outerPanel.role,
  phone: state.kids.outerPanel.phone,
  code: state.kids.outerPanel.code,
  password: state.kids.outerPanel.password,
  rePassword: state.kids.outerPanel.rePassword,
  inviteCode: state.kids.outerPanel.inviteCode,
  registerLoginCode: state.kids.outerPanel.registerLoginCode,
  isAgree: state.kids.outerPanel.isAgree,
  phoneTipSuccess: state.kids.outerPanel.phoneTipSuccess,
  phoneTipError: state.kids.outerPanel.phoneTipError,

  officialSite: state.kids.app.officialSite
});

const mapDispatchToProps = (dispatch) => ({
  changePanel: (mode) => dispatch(setOuterPanelMode(mode)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),

  setRole: (role) => dispatch(setRole(role)),
  setPhone: (phone) => dispatch(setPhone(phone)),
  setCode: (code) => dispatch(setCode(code)),
  setPassword: (password) => dispatch(setPassword(password)),
  setRePassword: (password) => dispatch(setRePassword(password)),
  setInviteCode: (code) => dispatch(setInviteCode(code)),
  setRegisterLoginCode: (code) => dispatch(setRegisterLoginCode(code)),
  setIsAgree: (agree) => dispatch(setIsAgree(agree)),
  setPhoneTipSuccess: (text) => dispatch(setPhoneTipSuccess(text)),
  setPhoneTipError: (text) => dispatch(setPhoneTipError(text)),

  submitLogin: (loginToken) => submitLogin(dispatch, loginToken)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
