// --参数适配
import { ENV, SERVER_HOST } from './utils/config';

console.log('current enviroment: ', ENV);

const _url = SERVER_HOST;

// --api接口地址
const url = {
  // 用户 user
  register: `${_url}/user/register`,
  login: `${_url}/user/login`,
  logout: `${_url}/user/logout`,
  checkLogin: `${_url}/user/checkLogin`,
  checkPhoneExist: `${_url}/user/checkPhoneExist`,
  getUserBasicInfo: `${_url}/user/getUserBasicInfo`,
  getUserAllInfo: `${_url}/user/getUserAllInfo`,
  updateUserInfo: `${_url}/user/updateUserInfo`,
  getUserPageInfo: `${_url}/user/getUserPageInfo`,
  getUsernameByPhone: `${_url}/user/getUsernameByPhone`,
  addUserPWRecord: `${_url}/user/addUserPWRecord`,
  delUserPWRecord: `${_url}/user/delUserPWRecord`,
  addUserURecord: `${_url}/user/addUserURecord`,
  delUserURecord: `${_url}/user/delUserURecord`,
  getUserURecordByUserId: `${_url}/user/getUserURecordByUserId`,
  getUserConcerns: `${_url}/user/getUserConcerns`,
  getUserConcerns: `${_url}/user/getUserConcerns`,
  updateAutoSaveWork: `${_url}/user/updateAutoSaveWork`,

  // 作品 work
  getUserWork: `${_url}/work/getUserWork`,
  getExampleWork: `${_url}/work/getExampleWork`,
  getUserWorkById: `${_url}/work/getUserWorkById`,
  deleteUserWork: `${_url}/work/deleteUserWork`,
  unbindUserWorkShareLabel: `${_url}/work/unbindUserWorkShareLabel`,
  deletePublishWork: `${_url}/work/deletePublishWork`,
  getUserWorkWithCondition: `${_url}/work/getUserWorkWithCondition`,
  getPublishWorkById: `${_url}/work/getPublishWorkById`,
  getUserPWRecordByWorkId: `${_url}/work/getUserPWRecordByWorkId`,
  addPublishWorkZanByUser: `${_url}/work/addPublishWorkZanByUser`,
  getPublishWorkWithCondition: `${_url}/work/getPublishWorkWithCondition`,

  // 素材相关 sprite
  getUserSprite: `${_url}/sprite/getUserSprite`,
  deleteUserSprite: `${_url}/sprite/deleteUserSprite`,

  // 标签相关 label
  getUserWorkLabel: `${_url}/label/getUserWorkLabel`,
  deleteUserWorkLabel: `${_url}/label/deleteUserWorkLabel`,
  addUserWorkLabel: `${_url}/label/addUserWorkLabel`,
  editUserWorkLabel: `${_url}/label/editUserWorkLabel`,
  removeUserWorkLabel: `${_url}/label/removeUserWorkLabel`,

  updateUserWorkLabelShareCode: `${_url}/label/updateUserWorkLabelShareCode`,
  checkUserWorkLabelShareCode: `${_url}/label/checkUserWorkLabelShareCode`,
  findWorkByShareCode: `${_url}/label/findWorkByShareCode`,

  getUserSpriteLabel: `${_url}/label/getUserSpriteLabel`,
  deleteUserSpriteLabel: `${_url}/label/deleteUserSpriteLabel`,
  addUserSpriteLabel: `${_url}/label/addUserSpriteLabel`,
  editUserSpriteLabel: `${_url}/label/editUserSpriteLabel`,
  removeUserSpriteLabel: `${_url}/label/removeUserSpriteLabel`,

  updateUserSpriteLabelShareCode: `${_url}/label/updateUserSpriteLabelShareCode`,
  checkUserSpriteLabelShareCode: `${_url}/label/checkUserSpriteLabelShareCode`,
  findSpriteByShareCode: `${_url}/label/findSpriteByShareCode`,

  // 上传 upload
  uploadUserAvatar: `${_url}/upload/uploadUserAvatar`,
  uploadProjectInfo: `${_url}/upload/uploadProjectInfo`,
  uploadSpriteInfo: `${_url}/upload/uploadSpriteInfo`,
  uploadPublishFile: `${_url}/upload/uploadPublishFile`,

  // 发布相关 publish
  publishUserWork: `${_url}/publish/publishUserWork`,

  // 公共资源接口 public
  getPublicAvatar: `${_url}/public/getPublicAvatar`,
  getPublicPublishTags: `${_url}/public/getPublicPublishTags`,
  getPublishTopWorkMap: `${_url}/public/getPublishTopWorkMap`,
  reptileWorkCode: `${_url}/public/reptileWorkCode`,
  getCaptcha: `${_url}/public/getCaptcha`,
  getWorkTags: `${_url}/public/getWorkTags`,

  // 消息相关 msg
  getUserMsgUnreadCount: `${_url}/msg/getUserMsgUnreadCount`,
  getUserMsgById: `${_url}/msg/getUserMsgById`,
  clearUserMsgUnreadCount: `${_url}/msg/clearUserMsgUnreadCount`,
  getUserMsgWithCondition: `${_url}/msg/getUserMsgWithCondition`,

  // 评论相关 comment
  addUserPWComment: `${_url}/comment/addUserPWComment`,
  addUserUComment: `${_url}/comment/addUserUComment`,
  getUserPWComment: `${_url}/comment/getUserPWComment`,

  // 三方相关third
  getWXShareSignature: `${_url}/third/getWXShareSignature`,

  // 资源管理 lessonResource
  outerGetAllLessonResource: `${_url}/lessonResource/outerGetAllLessonResource`,
  outerGetLessonResource: `${_url}/lessonResource/outerGetLessonResource`,
  getAllLessonFolder: `${_url}/lessonResource/getAllLessonFolder`,

  // 上报错误
  reportError: `${_url}/error/set`,
};

// --基础方法
// params|query参数字符化
export const queryStringify = (params) => {
  /**
   * params: {
   *  name: 'shou',
   *   age: 23
   * } => ?name=shou&age=23
   */
  // 空参
  if (!params) {
    return '';
  }
  let content = '?';
  for (let key in params) {
    content += `${key}=${params[key]}&`;
  }
  return content.match(/(.*)&$/)[1];
}

// --封装请求 get
const get = (url, params = null) => {
  return fetch(url + queryStringify(params), {
    credentials: 'include'
  }).then(response => response.json());
}

// --封装请求 post
const post = (url, params) => {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
    credentials: 'include'
  }).then(response => response.json());
}

// --暴露使用接口
// 用户注册
export const register = ({ phone, password, role, inviteCode, captcha }) => {
  return post(url.register, { phone, password, role, invite_code: inviteCode, captcha });
}

// 用户登录(手机号)
export const login = ({ phone, password, captcha }) => {
  return post(url.login, {
    phone,
    password,
    // captcha: captcha.toLowerCase() // 兼容小写
  });
}

// 用户退出
export const logout = () => {
  return post(url.logout);
}

// 用户登录态
export const checkLogin = () => {
  return get(url.checkLogin);
}

// 手机号存在验证
export const checkPhoneExist = ({ phone }) => {
  return get(url.checkPhoneExist, { phone });
}

// 用户基本信息获取
export const getUserBasicInfo = () => {
  return get(url.getUserBasicInfo);
}

// 用户所有信息获取
export const getUserAllInfo = () => {
  return get(url.getUserAllInfo);
}

// 用户信息修改
export const updateUserInfo = (info) => {
  return post(url.updateUserInfo, info);
}

// 上传用户头像
export const uploadUserAvatar = ({ avatarName }) => {
  return post(url.uploadUserAvatar, { avatarName });
};

// 上传作品信息
export const uploadProjectInfo = ({ workInfo }) => {
  return post(url.uploadProjectInfo, { workInfo });
}

// 获取用户所有作品(个人)
export const getUserWork = () => {
  return get(url.getUserWork);
}

// 获取示例作品（默认示例作品账号 默认 id 10000)
export const getExampleWork = (id = 10000) => {
  return get(url.getExampleWork, { id });
}

// id获取作品(发布)
export const getPublishWorkById = ({ id }) => {
  return get(url.getPublishWorkById, { id });
}

// 分页获取作品
export const getUserWorkWithCondition = (params) => {
  return get(url.getUserWorkWithCondition, params);
}

// 删除用户作品
export const deleteUserWork = ({ id, cover_path, file_path }) => {
  return post(url.deleteUserWork, { id, cover_path, file_path });
}

// 解绑用户作品
export const unbindUserWorkShareLabel = ({ id }) => {
  return post(url.unbindUserWorkShareLabel, { id });
}

// 删除已发布作品
export const deletePublishWork = ({ id }) => {
  return post(url.deletePublishWork, { id });
}

// 获取用户指定作品
export const getUserWorkById = ({ id }) => {
  return get(url.getUserWorkById, { id })
}
// 获取用户所有素材
export const getUserSprite = () => {
  return get(url.getUserSprite);
}

// 通过号码获取用户昵称
export const getUsernameByPhone = (params) => {
  return get(url.getUsernameByPhone, params);
}

// 删除用户素材
export const deleteUserSprite = (params) => {
  return post(url.deleteUserSprite, params);
};

// 上传素材接口
export const uploadSpriteInfo = ({ spriteInfo }) => {
  return post(url.uploadSpriteInfo, { spriteInfo });
}

// 上传发布页文件接口
export const uploadPublishFile = (formData, cb) => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url.uploadPublishFile);
    // xhr.setRequestHeader('Content-Type','application/x-www-form-urlencoded')
    xhr.withCredentials = true;
    xhr.onreadystatechange = function () {
      if (xhr.status === 200 && xhr.readyState === 4) {
        resolve(JSON.parse(xhr.responseText));
      }
    }
    xhr.upload.onprogress = (e) => {
      cb(e);
    }
    xhr.send(formData);
  });
}

// 获取公共头像
export const getPublicAvatar = () => {
  return get(url.getPublicAvatar);
}

// 获取公共发布作品标签
export const getPublicPublishTags = () => {
  return get(url.getPublicPublishTags);
}

// 获取作品展示页首页数据
export const getPublishTopWorkMap = () => {
  return get(url.getPublishTopWorkMap);
}

// 爬取草料二维码地址
export const reptileWorkCode = ({ url: urlLink }) => {
  return get(url.reptileWorkCode, { url: urlLink });
}

// 获取验证码
export const getCaptcha = () => {
  return get(url.getCaptcha);
}

// 获取公共页作品标签
export const getWorkTags = () => {
  return get(url.getWorkTags);
}

// 获取用户全部作品标签
export const getUserWorkLabel = () => {
  return get(url.getUserWorkLabel);
}

// 增加作品标签
export const addUserWorkLabel = (params) => {
  return post(url.addUserWorkLabel, params);
}

// 删除作品标签
export const deleteUserWorkLabel = (params) => {
  return post(url.deleteUserWorkLabel, params);
}

// 编辑作品标签
export const editUserWorkLabel = (params) => {
  return post(url.editUserWorkLabel, params);
}

// 更新作品共享码
export const updateUserWorkLabelShareCode = (params) => {
  return post(url.updateUserWorkLabelShareCode, params);
}

// 查重作品共享码
export const checkUserWorkLabelShareCode = (params) => {
  return post(url.checkUserWorkLabelShareCode, params);
}

// 根据共享码查找指定作品集
export const findWorkByShareCode = (params) => {
  return get(url.findWorkByShareCode, params);
}

// 改变作品对应标签
export const removeUserWorkLabel = (params) => {
  return post(url.removeUserWorkLabel, params);
}

// 获取用户全部素材标签
export const getUserSpriteLabel = () => {
  return get(url.getUserSpriteLabel);
}

// 增加标签
export const addUserSpriteLabel = (params) => {
  return post(url.addUserSpriteLabel, params);
}

// 删除标签
export const deleteUserSpriteLabel = (params) => {
  return post(url.deleteUserSpriteLabel, params);
}

// 编辑标签
export const editUserSpriteLabel = (params) => {
  return post(url.editUserSpriteLabel, params);
}

// 更新素材共享码
export const updateUserSpriteLabelShareCode = (params) => {
  return post(url.updateUserSpriteLabelShareCode, params);
}

// 查重素材共享码
export const checkUserSpriteLabelShareCode = (params) => {
  return post(url.checkUserSpriteLabelShareCode, params);
}

// 根据共享码查找指定作品集
export const findSpriteByShareCode = (params) => {
  return get(url.findSpriteByShareCode, params);
}

// 改变作品对应标签
export const removeUserSpriteLabel = (params) => {
  return post(url.removeUserSpriteLabel, params);
}

// 获取UserPage所有信息
export const getUserPageInfo = ({ userId = '' }) => {
  return get(url.getUserPageInfo, { userId });
}

// 发布作品
export const publishUserWork = (publishInfo) => {
  return post(url.publishUserWork, publishInfo);
}

// 作品与用户映射记录
export const getUserPWRecordByWorkId = ({ id }) => {
  return get(url.getUserPWRecordByWorkId, { id });
}

// 作品点赞
export const addPublishWorkZanByUser = ({ workId, workUserId }) => {
  return post(url.addPublishWorkZanByUser, { workId, workUserId })
}

// 分页 -- 已发布作品
export const getPublishWorkWithCondition = ({
  page,
  pageSize,
  keyword,
  type,
  tag,
  order
}) => {
  if (tag === '全部') {
    tag = ''
  }

  return get(url.getPublishWorkWithCondition, {
    page,
    pageSize,
    keyword,
    type,
    tag,
    order
  });
}

// 轮询未读信息
export const getUserMsgUnreadCount = () => {
  return get(url.getUserMsgUnreadCount);
}

// 获取指定用户信息
export const getUserMsgById = () => {
  return get(url.getUserMsgById);
}

// 清除用户未读信息
export const clearUserMsgUnreadCount = () => {
  return post(url.clearUserMsgUnreadCount);
}

// 获取用户信息条目（分页接口）
export const getUserMsgWithCondition = ({
  page = 1,
  pageSize = 8,
  userId = '',
  type = 'system',
  sortBy = 'updatedAt'
}) => {
  return get(url.getUserMsgWithCondition, {
    page,
    pageSize,
    userId,
    sortBy,
    type,
  });
}

// 增加映射 用户&作品
export const addUserPWRecord = ({ toUserId, workId, msgFlag, type }) => {
  return post(url.addUserPWRecord, { toUserId, workId, msgFlag, type });
}

// 删除映射 用户&作品
export const delUserPWRecord = ({ workId, type }) => {
  return post(url.delUserPWRecord, { workId, type });
}

// 增加映射 用户&用户
export const addUserURecord = ({ toUserId, type, msgFlag }) => {
  return post(url.addUserURecord, { toUserId, type, msgFlag });
}

// 删除映射 用户&用户
export const delUserURecord = ({ toUserId, type }) => {
  return post(url.delUserURecord, { toUserId, type });
}

// 获取映射 用户&用户
export const getUserURecordByUserId = ({ toUserId }) => {
  return post(url.getUserURecordByUserId, { toUserId, type });
}

// 获取映射 用户&用户
export const getUserConcerns = ({ userId }) => {
  return get(url.getUserConcerns, { userId });
}

// 更新 自动保存作品
export const updateAutoSaveWork = ({checked})=>{
  return post(url.updateAutoSaveWork, { checked });
}

// 新增评论 用户&作品
export const addUserPWComment = ({
  content,
  target_comment_id,
  tbl_publish_work_id,
  msgToUid
}) => {
  return post(url.addUserPWComment, {
    content,
    target_comment_id,
    tbl_publish_work_id,
    msgToUid
  });
}

// 获取 用户 & 已发布作品 映射信息
export const getUserPWComment = ({ workId }) => {
  return get(url.getUserPWComment, { workId });
}

// 获取微信分享票据
export const getWXShareSignature = ({ url: urlParam }) => {
  return get(url.getWXShareSignature, {
    url: urlParam
  });
}

// 对外获取资源列表
export const outerGetAllLessonResource = ({ dirId = "" }) => {
  return get(url.outerGetAllLessonResource, {
    dirId
  });
}

// 对外获取资源详情
export const outerGetLessonResource = ({ id, password = "" }) => {
  return get(url.outerGetLessonResource, {
    id, password
  });
}

// 对外获取文件夹列表
export const getAllLessonFolder = () => {
  return get(url.getAllLessonFolder);
}

// 上报异常
export const reportError = ({ error }) => {
  // 转义对象
  try {
    error = JSON.stringify(error)
  } catch (err) {
    console.error(err)
    return
  }

  return get(url.reportError, {
    error
  });
}
