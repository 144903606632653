import React, { Component } from 'react';
import bindAll from 'lodash.bindall';
import styles from './style.css';
import { connect } from 'react-redux';
import { wxLoginUrl } from '../../../../../lib/utils/kids-config';
const { actions: { setOuterPanelMode } } = require('../../../../../reducers/kids-outer-panel');
const { actions: { setToastShow } } = require('../../../../../reducers/kids-app');
const { actions: { submitLogin } } = require('../../../../../reducers/kids-user-panel');
const { actions: {
  setLoginPhone, setLoginPassword, setLoginCode
} } = require('../../../../../reducers/kids-outer-panel');
import { getCaptcha } from '../../../../../lib/fetch-api';
import closeIcon from 'static/closeIcon.png';
import userIcon from 'static/userIcon.png';
import passIcon from 'static/passIcon.png';
import codeIcon from 'static/codeIcon.png';
import wxIcon from 'static/wxIcon.png';
import wxLogin from 'static/wx_login.png';
import SliderCaptcha from '../../../../components/SliderCaptcha';

class Login extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCloseBtnClick',
      'handleRefreshCode',
      'handleLoginClick',
      'handleLoginKeyDown',
      'toLoginArea',
      'toRegisterArea',
      'toRecoverPwdArea',
      'toThirdPartRegisterArea',
      // 'refreshCaptcha'
      'openSliderCaptcha',
      'onCaptchaClose'
    ]);

    this.state = {
      sliderCaptcha: false,
      isMobile: false
      // captcha: '' // 验证码svg
    };
  }

  onCaptchaClose(e) {
    e.stopPropagation();
    this.setState({
      sliderCaptcha: false
    })
  }

  openSliderCaptcha() {
    // 取消滑块验证码 直接 true
    // if(this.state.isMobile){
    if(true){
      this.handleLoginClick()
      return
    }

    const { showError, loginPhone, loginPassword } = this.props;
    // 校验 用户名和密码是否输入
    if (!loginPhone) {
      showError('请输入用户名');
    } else if (!loginPassword) {
      showError('请输入密码');
    } else {
      this.setState({
        sliderCaptcha: true
      });
    }
  }

  handleLoginClick() {

    this.setState({
      sliderCaptcha: false
    });

    const { loginPhone, loginPassword, loginCode, officialSite, submitLogin } = this.props;
    submitLogin({
      phone: loginPhone,
      password: loginPassword,
      // captcha: loginCode,
      officialSite
    });
  }

  handleLoginKeyDown(e) {
    if (e.keyCode !== 13) {
      return;
    }
    this.openSliderCaptcha();
    // this.handleLoginClick();
  }

  handleCloseBtnClick() {
    this.props.changePanel(0);
  }

  handleRefreshCode() {
    console.log('刷新验证码');
  }

  toLoginArea() {
    this.props.changePanel(1);
  }

  toRegisterArea() {
    this.props.changePanel(2);
  }

  toRecoverPwdArea() {
    this.props.changePanel(3);
  }

  toThirdPartRegisterArea() {
    this.props.changePanel(4);
  }

  handleWXLoginClick() {
    window.location.href = wxLoginUrl();
  }

  // mounted 主要开设获取和刷新验证码
  componentDidMount() {
    // this.refreshCaptcha();
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    this.setState({
      isMobile: !!isMobile
    })
  }

  // 重新验证码
  // refreshCaptcha() {
  //   getCaptcha().then(rs => {
  //     this.setState({
  //       captcha: rs.data.svg
  //     });
  //   })
  // }

  render() {

    const {
      loginPhone, loginPassword, loginCode,
      setLoginPhone, setLoginPassword, setLoginCode,
    } = this.props;

    const {
      sliderCaptcha
    } = this.state;

    return (
      <div>
        <div className={styles.kidsLogin__header}>
          <p className={styles.kidsLogin__title}>登录</p>
          <img className={styles.kidsLogin__close}
            src={closeIcon}
            onClick={this.handleCloseBtnClick} />
        </div>
        <div className={styles.kidsLogin__form}>
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={userIcon} />
            <input type="text" name='phone' placeholder='输入用户名/手机号'
              autoComplete="on"
              maxLength='11'
              value={loginPhone}
              onChange={(e) => setLoginPhone(e.target.value)}
            />
          </div>
          <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={passIcon} />
            <input type="password" name='password' placeholder='输入密码'
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              onKeyDown={this.handleLoginKeyDown}
            />
            {/* <p className={styles.kidsLogin__form__item__forget}
              onClick={this.toRecoverPwdArea}
            >忘记了密码?</p> */}
          </div>

          {/* <div className={styles.kidsLogin__form__item}>
            <img className={styles.kidsLogin__form__icon}
              src={codeIcon} />
            <input type="text" placeholder='输入验证码'
              value={loginCode}
              onChange={(e) => setLoginCode(e.target.value)}
              onKeyDown={this.handleLoginKeyDown}
            />
            <div
              className={styles.kidsLogin__form__item__code}
              onClick={this.refreshCaptcha}
              dangerouslySetInnerHTML={{ __html: this.state.captcha }}
            >
            </div>
          </div> */}

          <div
            className={styles.kidsLogin__login}
            onClick={this.openSliderCaptcha}
          >
            登录
          {
              sliderCaptcha &&
              <SliderCaptcha
                onCaptchaClose={this.onCaptchaClose}
                onSuccess={this.handleLoginClick}
              />
            }

          </div>

        </div>
        <div className={styles.kidsLogin__footer}>
          <div className={styles.kidsLogin__footer__wx}>
            <img className={styles.kidsLogin__footer__wx__icon}
              onClick={this.handleWXLoginClick}
              src={wxLogin}
            />
          </div>
          <div className={styles.kidsLogin__footer__register}>
            <span className={styles.kidsLogin__footer__register__text}
              onClick={this.toRegisterArea}
            >注册账号</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginPhone: state.kids.outerPanel.loginPhone,
  loginPassword: state.kids.outerPanel.loginPassword,
  loginCode: state.kids.outerPanel.loginCode,
  officialSite: state.kids.app.officialSite
});

const mapDispatchToProps = (dispatch) => ({
  changePanel: (mode) => dispatch(setOuterPanelMode(mode)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),
  setLoginPhone: (phone) => dispatch(setLoginPhone(phone)),
  setLoginPassword: (password) => dispatch(setLoginPassword(password)),
  setLoginCode: (code) => dispatch(setLoginCode(code)),
  // 异步action
  submitLogin: (loginToken) => submitLogin(dispatch, loginToken)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
