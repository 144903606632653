exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".index_fullscreen_jnkNq {\n  width: 48px;\n  height: 48px;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-right: 6px;\n\n}\n\n.index_fullscreen_jnkNq:hover {\n  cursor: pointer;\n  opacity: 0.85;\n}\n\n.index_icon_1cw7E {\n  width: 26px;\n  height: 26px;\n}\n", ""]);

// exports
exports.locals = {
	"fullscreen": "index_fullscreen_jnkNq",
	"icon": "index_icon_1cw7E"
};