import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import { STAGE_DISPLAY_SIZES } from '../../lib/layout-constants.js';
import StageHeader from '../../containers/stage-header.jsx';
import Stage from '../../containers/stage.jsx';
import Loader from '../loader/loader.jsx';

import styles from './stage-wrapper.css';

const StageWrapperComponent = function (props) {
  const {
    isPlayerOnly,
    isFullScreen,
    isRtl,
    isRendererSupported,
    loading,
    playerSize,
    stageSize,
    vm
  } = props;
  return (
    <Box
      className={styles.stageWrapper}
      dir={isRtl ? 'rtl' : 'ltr'}
    >
      <Box className={styles.stageMenuWrapper}>
        <StageHeader
          stageSize={stageSize}
          vm={vm}
        />
      </Box>
      <Box className={styles.stageCanvasWrapper}>
        {
          isRendererSupported ?
            <Stage
              isPlayerOnly={isPlayerOnly}
              playerSize={playerSize}
              stageSize={stageSize}
              vm={vm}
            /> :
            null
        }
      </Box>

      {/* SB3播放器，不触发全屏过渡动画*/}
      {loading && !isPlayerOnly ? (
        <Loader isFullScreen={isFullScreen} />
      ) : null}

      {/* 自定义加载动画，暂使用loader */}
      {loading && isPlayerOnly ? (
        <Loader isFullScreen={false} />
      ) : null}
    </Box>
  );
};

StageWrapperComponent.propTypes = {
  isPlayerOnly: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isRendererSupported: PropTypes.bool.isRequired,
  isRtl: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  playerSize: PropTypes.array,
  stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
  vm: PropTypes.instanceOf(VM).isRequired
};

export default StageWrapperComponent;
