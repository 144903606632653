// 开发环境或上线环境区分对待
const ENV = process.env.NODE_ENV; // 环境参数

// 后台服务器地址
let SERVER_HOST;

// GUI地址(站点1)
let IDE_URL;

// HOME地址(www站点2)
let HOME_URL;

// MAIN地址(main站点3)
let MAIN_URL;

// OSS地址
let AGENT_HOST, TEMP_PATH,
  PRIVATE_PROEJECT_PATH, PRIVATE_PROEJECT_COVER_PATH,
  PRIVATE_SPRITE_PATH, PRIVATE_SPRITE_COVER_PATH,
  USER_AVATAR_PATH,
  PUBLIC_AVATAR_PATH, PUBLISH_RESOURCE_PATH,
  HOME_RESOURCE_PATH;

if (ENV === 'dev') {
  SERVER_HOST = 'http://localhost:3000'; // 1.正常测试
  // SERVER_HOST = 'http://192.168.1.6:3000'; // 2.Home WorkContentPage 移动端测试（请自行修改局域网ip）
  // SERVER_HOST = 'http://passport.k12-code.com'; // 3.需修改本机drivers/etc/HOSTS映射 wx登录测试（请切换）
  IDE_URL = 'http://localhost:3009';
  HOME_URL = 'http://localhost:3010';
  MAIN_URL = 'https://main.k12-code.com/'; // 线上线下一致

  AGENT_HOST = 'https://shou-upload-test.oss-cn-hangzhou.aliyuncs.com'; // 资源根地址
  TEMP_PATH = 'temp';
  PRIVATE_PROEJECT_PATH = 'private_project';
  PRIVATE_PROEJECT_COVER_PATH = 'private_project_cover';
  PRIVATE_SPRITE_PATH = 'private_sprite';
  PRIVATE_SPRITE_COVER_PATH = 'private_sprite_cover';
  USER_AVATAR_PATH = 'user_avatar';
  PUBLIC_AVATAR_PATH = 'public_avatar';
  PUBLISH_RESOURCE_PATH = 'publish_resource';
  HOME_RESOURCE_PATH = 'home_resources';
} else {
  SERVER_HOST = 'https://passport.k12-code.com'; // 由服务器nginx代理转发至3000端口下
  IDE_URL = 'https://scratch3.k12-code.com';
  HOME_URL = 'https://k12-code.com';
  MAIN_URL = 'https://main.k12-code.com/'; // 线上线下一致

  AGENT_HOST = 'https://kaisiao-users-bucket.oss-cn-hangzhou.aliyuncs.com'; // 资源根地址
  TEMP_PATH = 'temp';
  PRIVATE_PROEJECT_PATH = 'private_project';
  PRIVATE_PROEJECT_COVER_PATH = 'private_project_cover';
  PRIVATE_SPRITE_COVER_PATH = 'private_sprite_cover';
  PRIVATE_SPRITE_PATH = 'private_sprite';
  USER_AVATAR_PATH = 'user_avatar';
  PUBLIC_AVATAR_PATH = 'public_avatar';
  PUBLISH_RESOURCE_PATH = 'publish_resource';
  HOME_RESOURCE_PATH = 'home_resources';
}

// 解析资源路径
const resolve = (dirname) => {
  return dirname;
}
// 解析资源地址
const getAvatarUrl = (avatar) => {
  if (avatar === null || avatar === '') {
    return ''
  }
  return `${AGENT_HOST}/${USER_AVATAR_PATH}/${avatar}`;
}

const getWorkCoverUrl = (cover) => {
  return `${AGENT_HOST}/${PRIVATE_PROEJECT_COVER_PATH}/${cover}`;
}

const getSpriteCoverUrl = (cover) => {
  return `${AGENT_HOST}/${PRIVATE_SPRITE_COVER_PATH}/${cover}`;
}

const getWorkFileUrl = (file) => {
  return `${AGENT_HOST}/${PRIVATE_PROEJECT_PATH}/${file}`;
}

const getSpriteFileUrl = (file) => {
  return `${AGENT_HOST}/${PRIVATE_SPRITE_PATH}/${file}`;
}

const getPublicAvatarUrl = (file) => {
  return `${AGENT_HOST}/${PUBLIC_AVATAR_PATH}/${file}`
}

const getPublishResourceUrl = (file) => {
  return `${AGENT_HOST}/${PUBLISH_RESOURCE_PATH}/${file}`;
}

const getHomeResourceUrl = (file) => {
  return `${AGENT_HOST}/${HOME_RESOURCE_PATH}/${file}`;
}

// 解析OSS路径
const getTempPath = (file) => {
  return `${resolve(TEMP_PATH)}/${file}`;
}
const getAvatarPath = (avatar) => {
  return `${resolve(USER_AVATAR_PATH)}/${avatar}`;
}

const getWorkCoverPath = (cover) => {
  return `${resolve(PRIVATE_PROEJECT_COVER_PATH)}/${cover}`;
}

const getSpriteCoverPath = (cover) => {
  return `${resolve(PRIVATE_SPRITE_COVER_PATH)}/${cover}`;
}

const getWorkFilePath = (file) => {
  return `${resolve(PRIVATE_PROEJECT_PATH)}/${file}`;
}

const getSpriteFilePath = (file) => {
  return `${resolve(PRIVATE_SPRITE_PATH)}/${file}`;
}

const getPublishResourcePath = (file) => {
  return `${resolve(PUBLISH_RESOURCE_PATH)}/${file}`;
}

export {
  ENV,
  SERVER_HOST,
  IDE_URL,
  HOME_URL,
  MAIN_URL,

  getAvatarUrl,
  getAvatarPath,

  getWorkCoverUrl,
  getWorkCoverPath,
  getWorkFileUrl,
  getWorkFilePath,

  getSpriteCoverUrl,
  getSpriteCoverPath,
  getSpriteFileUrl,
  getSpriteFilePath,

  getHomeResourceUrl, // 站点静态资源

  getTempPath,

  getPublicAvatarUrl,

  getPublishResourceUrl,
  getPublishResourcePath

};
