import React, { Component } from 'react';
import styles from './style.css';
import closeIcon from 'static/closeIcon.png';

class Progress extends Component {
  constructor(props) {
    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this)
  }

  handleCloseClick() {
    const { setProgress } = this.props;
    setProgress(0);
  }

  componentDidUpdate() {
    const { showError, progress, setProgress } = this.props;
    // 当更新后进度为 100%
    if (progress >= 100) {
      setProgress(0);
    }
  }

  render() {
    const { progress } = this.props;
    return (
      <div className={styles.kidsProgress__wrapper}
        style={{ backgroundSize: `${progress}% 100%` }}
      >
        <img className={styles.kidsProgress__close}
          src={closeIcon}
          onClick={this.handleCloseClick}
        />
        上传进度: {progress}%
      </div>
    );
  }
}

export default Progress;