import React, { Component } from 'react';
import styles from './style.css';
import loadingSpokes from 'static/loading-spokes.svg';

class Loading extends Component {

  componentDidMount() {
    // 最大延迟15s，之后自动关闭
    clearTimeout(this._timer)
    this._timer = setTimeout(() => {
      this.props.setLoading(false);
      this.props.showError('超15秒未响应，加载异常');
    }, 15000);
  }

  componentWillUnmount() {
    clearTimeout(this._timer)
  }

  render() {
    return (
      <div className={styles.kidsProgress__wrapper}>
        <img src={loadingSpokes} alt="loading" />
      </div>
    );
  }
}

export default Loading;