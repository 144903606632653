exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.style_kidsLogin__windowMask_2X8Lf {\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  position: fixed;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.3);\n  z-index: 10001;\n  -webkit-transition: all 0.5s;\n  -o-transition: all 0.5s;\n  transition: all 0.5s;\n}\n.style_kidsLogin__windowMask--hide_2bQ4b {\n  opacity: 0;\n}\n.style_kidsLogin__Window_2tYo2 {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  position: fixed;\n  top: 50%;\n  min-width: 400px;\n  left: 50%;\n  margin-left: -200px;\n  background: #fff;\n  z-index: 10002;\n  color: #333;\n  font-size: 14px;\n  font-weight: 300;\n  border-radius: 6px;\n  /* transition: all 0.2s ease; */\n}\n.style_kidsLogin__WindowLogin_c8fIm {\n  margin-top: -220px;\n  margin-left: -200px;\n  padding: 20px 10px;\n}\n.style_kidsLogin__WindowRegister_1tAIv {\n  margin-top: -270px;\n  margin-left: -200px;\n}\n.style_kidsLogin__WindowForget_1Lups {\n  margin-top: -200px;\n  margin-left: -200px;\n}\n.style_kidsLogin__Window--hide_1Id0I {\n  -webkit-transform: scale(0);\n      -ms-transform: scale(0);\n          transform: scale(0);\n  z-index: 10002;\n}", ""]);

// exports
exports.locals = {
	"kidsLogin__windowMask": "style_kidsLogin__windowMask_2X8Lf",
	"kidsLoginWindowMask": "style_kidsLogin__windowMask_2X8Lf",
	"kidsLogin__windowMask--hide": "style_kidsLogin__windowMask--hide_2bQ4b",
	"kidsLoginWindowMaskHide": "style_kidsLogin__windowMask--hide_2bQ4b",
	"kidsLogin__Window": "style_kidsLogin__Window_2tYo2",
	"kidsLoginWindow": "style_kidsLogin__Window_2tYo2",
	"kidsLogin__WindowLogin": "style_kidsLogin__WindowLogin_c8fIm",
	"kidsLoginWindowLogin": "style_kidsLogin__WindowLogin_c8fIm",
	"kidsLogin__WindowRegister": "style_kidsLogin__WindowRegister_1tAIv",
	"kidsLoginWindowRegister": "style_kidsLogin__WindowRegister_1tAIv",
	"kidsLogin__WindowForget": "style_kidsLogin__WindowForget_1Lups",
	"kidsLoginWindowForget": "style_kidsLogin__WindowForget_1Lups",
	"kidsLogin__Window--hide": "style_kidsLogin__Window--hide_1Id0I",
	"kidsLoginWindowHide": "style_kidsLogin__Window--hide_1Id0I"
};