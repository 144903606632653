exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_kidsPerson__mask_2wiZB {\n  position: fixed;\n  z-index: 10011;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.15);\n}", ""]);

// exports
exports.locals = {
	"kidsPerson__mask": "style_kidsPerson__mask_2wiZB",
	"kidsPersonMask": "style_kidsPerson__mask_2wiZB"
};