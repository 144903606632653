// 公共页模块 用于存放即缓存各种首页的静态数据
import {
  getPublishTopWorkMap,
  getUserPageInfo,
  getPublishWorkById
} from '../lib/fetch-api';
const { actions: {
  addUserEntity,
  addWorkContentEntity
} } = require('./kids-entities');
import { getUserMsgById } from '../lib/fetch-api';
import { defaultAvatar } from '../lib/utils/kids-config';

const setWorkPageInfo = 'kids/page/SET_WORK_PAGE_INFO';
const setMsgPageInfo = 'kids/page/SET_MSG_PAGE_INFO';
const setCategoryInfo = 'kids/page/SET_CATEGORY_INFO'; // 空占 目前仅包含search的数据
const setCategorySearch = 'kids/page/SET_CATEGORY_SEARCH';

const initialState = {
  workPageInfo: {
    featuredList: [],
    latestList: []
  },
  msgPageInfo: {
    systemMsgList: [],
    interactionMsgList: []
  },
  categoryPageInfo: {
    search: {
      fetched: false, // 是否已加载一次
      page: 0, // 记录分页
      loadingVisible: true, // 加载图标
      searchList: []
    }
  }
};

const actions = {
  setWorkPageInfo: (info) => ({
    type: setWorkPageInfo,
    info
  }),
  setMsgPageInfo: (info) => ({
    type: setMsgPageInfo,
    info
  }),
  setCategorySearch: (search) => ({
    type: setCategorySearch,
    search
  }),
  // 异步actions
  getPublishTopWorkMap: (dispatch) =>
    onGetPublishTopWorkMap(dispatch),
  getUserPageInfo: (dispatch, userId = '') =>
    onGetUserPageInfo(dispatch, userId),
  getWorkContentPageInfo: (dispatch, id) =>
    onGetWorkContentPageInfo(dispatch, id),
  getMsgPageInfo: (dispatch) =>
    onGetMsgPageInfo(dispatch),
}

const onGetPublishTopWorkMap = (dispatch) => {
  const { setWorkPageInfo } = actions;
  getPublishTopWorkMap().then(({ data }) => {
    dispatch(setWorkPageInfo(data));
  });
}

// 实体方法：获取用户中心页UserPage数据
const onGetUserPageInfo = (dispatch, userId = '') => {
  return new Promise((resolve, reject) => {
    // 一个接口：获取获取用户页所有数据（作品集合、收藏集合、关注集合）
    // 包括用户&用户映射
    getUserPageInfo({ userId }).then(({ errno, data }) => {
      if (errno !== 0) {
        return reject();
      }
      /**
       * addUserEntity：来自entities的action
       * 理论是需要另开辟state存储ids，暂不实现
       */
      const { userId, info, myWork, publishWork, collect, fans, concerns } = data;
      if (!info.avatar) { // 若头像为空
        info.avatar = defaultAvatar;
      }
      const userEntity = {
        [userId]: {
          info,
          myWork: myWork.reverse(),
          publishWork: publishWork.reverse(),
          collect: collect.reverse(),
          fans: fans.reverse(),
          concerns: concerns.reverse(),
          zanLen: publishWork.reduce((total, { zan }) => total + zan, 0),
          workLen: publishWork.length
        }
      };
      dispatch(addUserEntity(userEntity));
      resolve(userEntity[userId]);
    })
  })
}

// 实体方法：获取用户中心页UserPage数据
const onGetMsgPageInfo = (dispatch) => {
  const { setMsgPageInfo } = actions;
  return new Promise((resolve, reject) => {
    getUserMsgById().then(({ errno, data: list }) => {
      if (errno !== 0) {
        return reject()
      }
      dispatch(setMsgPageInfo({
        // 解构出系统消息与互动消息
        systemMsgList: list.filter(m => m.type === 'system').reverse(),
        interactionMsgList: list.filter(m => m.type !== 'system').reverse()
      }));
    })
  })
}

// 实体方法：获取作品页WorkContentPage数据
const onGetWorkContentPageInfo = (dispatch, id) => {
  return new Promise((resolve, reject) => {
    // 异步任务集合：1.取数据 2.取映射
    getPublishWorkById({ id }).then(({
      errno,
      data
    }) => {
      if (errno !== 0) {
        return reject();
      }
      const { workId, workInfo, resources, tags, UWRecord } = data;
      const entity = {
        [workId]: {
          workInfo,
          resources,
          tags,
          zaned: UWRecord.some(i => i === 'zaned'),
          // collected: UWRecord.some(i => i === 'collected')
        }
      }
      dispatch(addWorkContentEntity(entity));
      resolve(entity);
    })
  });
}

const reducer = (state = initialState, action) => {
  const { info, search } = action;
  switch (action.type) {
    case setWorkPageInfo:
      return {
        ...state,
        workPageInfo: info
      }
    case setMsgPageInfo:
      return {
        ...state,
        msgPageInfo: info
      }
    case setCategorySearch:
      return {
        ...state,
        categoryPageInfo: {
          ...state.categoryPageInfo,
          search
        }
      }
    default:
      return state;
  }
}

export {
  reducer as default,
  actions
}
