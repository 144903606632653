import React, { Component } from 'react';
import ExampleWork from './components/ExampleWork';
import UserBtn from './components/UserBtn';
import SaveWork from './components/SaveWork';
import SaveSprite from './components/SaveSprite';
import Fullscreen from './components/Fullscreen';
import Label from './components/Label';
import { connect } from 'react-redux';
import styles from './style.css'

class Menubar extends Component {
  render() {
    return (
      <div className={styles.container}>
        {/* 示例作品 + 面板 */}
        {/* <ExampleWork /> */}

        {/* 全屏按钮 */}
        <Fullscreen />

        {/* 用户头像图标*/}
        <UserBtn />

        {/* 保存作品按钮 + 面板 */}
        <SaveWork />

        {/* 保存素材按钮 + 面板 */}
        <SaveSprite />

        {/* 标签管理面板 */}
        <Label />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menubar);
