exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* #E5F0FF */\n\n/* #E9F1FC */\n\n/* #D9E3F2 */\n\n/* 90% transparent version of motion-primary */\n\n/* #FFFFFF */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 15% transparent version of black */\n\n/* #575E75 */\n\n/* #4C97FF */\n\n/* #3373CC */\n\n/* 35% transparent version of motion-primary */\n\n/* 15% transparent version of motion-primary */\n\n/* #FF661A */\n\n/* #E64D00 */\n\n/* #CF63CF */\n\n/* #BD42BD */\n\n/* #FFAB19 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0FBD8C */\n\n/* #FF8C1A */\n\n/* #FFB366 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0B8E69 */\n\n/* 35% transparent version of extensions-primary */\n\n/* opaque version of extensions-transparent, on white bg */\n\n/* lighter than motion-primary */\n\n.stage-wrapper_stage-wrapper_2bejr {\n  position: relative;\n}\n\n.stage-wrapper_stage-wrapper_2bejr * {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n\n.stage-wrapper_stage-canvas-wrapper_3ewmd {\n  /* Hides negative space between edge of rounded corners + container, when selected */\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n", ""]);

// exports
exports.locals = {
	"stage-wrapper": "stage-wrapper_stage-wrapper_2bejr",
	"stageWrapper": "stage-wrapper_stage-wrapper_2bejr",
	"stage-canvas-wrapper": "stage-wrapper_stage-canvas-wrapper_3ewmd",
	"stageCanvasWrapper": "stage-wrapper_stage-canvas-wrapper_3ewmd"
};