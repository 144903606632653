import React, { Component } from "react";
import Toast from "../../Kids-Ide/components/Toast";
import Confirm from "../../Kids-Ide/components/Confirm";
import Popup from "../../Kids-Ide/components/Popup";
import Progress from "../../Kids-Ide/components/Progress";
import Loading from "../../Kids-Ide/components/Loading";
import Select from "../../Kids-Ide/components/Select";
import OuterPanel from "../../Kids-Ide/containers/OuterPanel";
import UserPanel from "../../Kids-Ide/containers/UserPanel";
import DrawBoard from "../../Kids-Ide/containers/DrawBoard";
import Menubar from "../../Kids-Ide/containers/Menubar";
import { connect } from "react-redux";
const {
  actions: {
    setToastClose,
    setToastShow,
    setConfirmClose,
    setPopupClose,
    setPopupInput,
    setProgress,
    setSelectClose,
    setSelectIndex,
    setLoading,
  },
} = require("../../reducers/kids-app");
const {
  actions: { getLoginInfo, setShowSaveProjectPanel },
} = require("../../reducers/kids-user-panel");
import { setProjectId } from "../../reducers/project-state";
import { fetchWork } from "../../lib/utils/mixin-api";
import { getPublishResourceUrl, getWorkFileUrl } from "../../lib/utils/config";
import {
  autoSaveWorkController
} from "../../lib/utils/auto-save-work";

class KidsLogin extends Component {
  componentDidMount() {
    // 有session情况
    this.updateUserNeedInfo();

    // 根据URL读取相应作品
    this.getSb3ByURL();

    // 策略执行自动保存作品
    autoSaveWorkController.init =  this.toAutoSaveWork;
    autoSaveWorkController.init = autoSaveWorkController.init.bind(this)
    autoSaveWorkController.init()
  }

  updateUserNeedInfo() {
    // LoginInfo包含用户信息 以及 标签数据
    this.props.getLoginInfo();
  }

  toAutoSaveWork() {
    console.log(this)
    const { showSuccess } = this.props;

    clearInterval(autoSaveWorkController.timer);

    autoSaveWorkController.timer = setInterval(() => {
      // 读取最新的自动保存作业状态
      if (!this.props.autoSaveWork || this.props.showSaveProjectPanel) {
        return;
      }

      autoSaveWorkController.handler();

      showSuccess("记得保存作品哦~");
    }, autoSaveWorkController.duration);
  }

  getSb3ByURL() {
    const { showSuccess, showError } = this.props;
    const { search } = window.location;
    // 根据 个人作品和 已发布作品 不同情况请求不同路径的资源
    const reg = /\?(work=.*)$|\?(publishwork=.*)$/;
    const matchRes = search.match(reg);
    let filename, fileUrl;
    // 区分环境变量
    if (search && matchRes) {
      if (matchRes[1]) {
        //捕获到work
        filename = matchRes[1].split("=")[1];
        fileUrl = getWorkFileUrl(filename);
      } else if (matchRes[2]) {
        // 捕获到publishwork
        filename = matchRes[2].split("=")[1];
        fileUrl = getPublishResourceUrl(filename);
      }
      fetchWork(this.props)(fileUrl)
        .then(() => {
          showSuccess("作品打开成功");
        })
        .catch(() => {
          // 若远程作品不存在，读默认作品0
          showError("作品不存在");
          this.props.setProjectId(0);
        });
    } else {
      // 若没有搜索则载入默认
      this.props.setProjectId(0);
    }
  }

  render() {
    const {
      showError,
      outerPanelMode,
      userPanelMode,
      showDrawBoard,
      toast,
      setToastClose,
      confirm,
      setConfirmClose,
      popup,
      setPopupClose,
      setPopupInput,
      progress,
      setProgress,
      loading,
      setLoading,
      select,
      setSelectClose,
      setSelectIndex,
    } = this.props;
    return (
      <div>
        {/* 横向菜单栏 -- 以及其他面板 */}
        <Menubar />

        {/* 外层面板 -- 大组件 */}
        {outerPanelMode ? <OuterPanel outerPanelMode={outerPanelMode} /> : null}

        {/* 用户面板 -- 大组件 */}
        {userPanelMode ? <UserPanel userPanelMode={userPanelMode} /> : null}

        {/* 画板窗口 -- 大组件 */}
        {showDrawBoard ? <DrawBoard /> : null}

        {/* 提示弹窗 */}
        {toast.mode ? (
          <Toast
            mode={toast.mode}
            text={toast.text}
            setToastClose={setToastClose}
          />
        ) : null}

        {/* 选择弹窗 */}
        {confirm.content ? (
          <Confirm
            content={confirm.content}
            confirmEvent={confirm.confirmEvent}
            confirmText={confirm.confirmText}
            cancelEvent={confirm.cancelEvent || setConfirmClose}
            cancelText={confirm.cancelText}
          />
        ) : null}

        {/* 输入弹窗 */}
        {popup.title ? (
          <Popup
            title={popup.title}
            tip={popup.tip}
            input={popup.input}
            placeholder={popup.placeholder}
            confirmText={popup.confirmText}
            confirmEvent={popup.confirmEvent}
            cancelText={popup.cancelText}
            cancelEvent={popup.cancelEvent || setPopupClose}
            top={popup.top}
            left={popup.left}
            setPopupInput={setPopupInput}
          />
        ) : null}

        {/* 进度条 */}
        {progress ? (
          <Progress
            progress={progress}
            setProgress={setProgress}
            showError={showError}
          />
        ) : null}

        {/* 加载中 */}
        {loading ? (
          <Loading setLoading={setLoading} showError={showError} />
        ) : null}

        {/* 列表弹窗 */}
        {select.title ? (
          <Select
            title={select.title}
            index={select.index}
            list={select.list}
            confirmText={select.confirmText}
            confirmEvent={select.confirmEvent}
            cancelText={select.cancelText}
            cancelEvent={select.cancelEvent || setSelectClose}
            setSelectIndex={setSelectIndex}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  outerPanelMode: state.kids.outerPanel.outerPanelMode,
  userPanelMode: state.kids.userPanel.userPanelMode,
  showDrawBoard: state.kids.userPanel.showDrawBoard,
  autoSaveWork: state.kids.userPanel.autoSaveWork,
  showSaveProjectPanel: state.kids.userPanel.showSaveProjectPanel,

  toast: state.kids.app.toast,
  confirm: state.kids.app.confirm,
  popup: state.kids.app.popup,
  select: state.kids.app.select,
  progress: state.kids.app.progress,
  loading: state.kids.app.loading,

  loadProject: state.scratchGui.vm.loadProject.bind(state.scratchGui.vm),
});

const mapDispatchToProps = (dispatch) => ({
  setToastClose: (mode, text) => dispatch(setToastClose(mode, text)),
  showSuccess: (text) => dispatch(setToastShow("success", text)),
  showError: (text) => dispatch(setToastShow("error", text)),
  setConfirmClose: () => dispatch(setConfirmClose()),
  setPopupClose: () => dispatch(setPopupClose()),
  setPopupInput: (input) => dispatch(setPopupInput(input)),
  setProgress: (progress) => dispatch(setProgress(progress)),
  setSelectClose: () => dispatch(setSelectClose()),
  setSelectIndex: (id) => dispatch(setSelectIndex(id)),
  setLoading: (status) => dispatch(setLoading(status)),
  getLoginInfo: () => getLoginInfo(dispatch),

  setShowSaveProjectPanel: (show) => dispatch(setShowSaveProjectPanel(show)),

  setProjectId: (id) => dispatch(setProjectId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KidsLogin);
