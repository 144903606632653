import React, { Component } from 'react';
import styles from './style.css';
import {
  convertFileToBase64,
  convertBase64ToBlob,
  randomId,
  appendTimestamp,
  // bindAll
} from '../../../../../lib/utils/kids-tools';
import bindAll from 'lodash.bindall';
import { uploadUserAvatar } from '../../../../../lib/fetch-api';
import InfoProvideAvatar from '../InfoProvideAvatar';
import { defaultAvatar } from '../../../../../lib/utils/kids-config';
import { getAvatarPath } from '../../../../../lib/utils/config';
import createOSS from '../../../../../lib/utils/kids-oss-operator';
/**
 * InfoCanvas: 基于Canvas的用户头像编辑组件
 * @param {string} avatar 用户头像
 * @param {method} setAvatar actions
 * @param {method} showSuccess actions 
 * @param {method} showError actions
 */
class InfoCanvas extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCanvasMouseDown',
      'handleCanvasMouseMove',
      'handleCanvasMouseOut',
      'handleCanvasMouseUp',
      'handleScaleBtnMouseDown',
      'handleScaleBtnMouseUp',
      'handleScaleBtnMouseOut',
      'handleModifyAvatarClick',
      'handleUploadCanvasChange',
      'handleBackBtnClick',
      'refreshCanvasAvatar',
      'toUploadAvatar'
    ]);
    this.state = {
      canvasAvatar: '',
      editCanvas: false,
    };
    // 临时变量
    this.uploadAvatar = ''; // 最终生成的file类型的头像
    this.base64 = ''; // 正常的base64，传递给父亲
    this.canvas = ''; // canvas画布
    this.ctx = ''; // 图画内容
    this.dataURL = ''; // canvas转化的base64，传递给父亲
    this.img = ''; // 图片数据，实时传入canvas去重绘出base64
    this.pressTimer = null; // 缩放按钮长按事件
    this._bodyClick = null; // 外部点击监听器
    this.imgOpts = {
      canvasWidth: 140, // 画布宽
      canvasHeight: 140, // 画布高
      width: 180, // 初始图片宽
      height: 180, // 初始图片高，兼容长方形需自适应改变
      kI: 1.02, // 放大系数
      kD: 0.98, // 缩小系数
      canMove: false,
      top: -20, // 兼容长方形需自适应改变
      left: -20,
      posX: 0,
      posY: 0,
      currentLeft: -20,
      currentTop: -20
    }
  }

  onSetAvatar() {
    // 未触发canvas编辑
    if (!this.dataURL) {
      return;
    }
    this.props.setAvatar(this.dataURL);
    this.setState({
      editCanvas: false
    });
  }

  handleCanvasMouseDown(e) {
    // 鼠标按下表示可以开始拖拽事件
    this.imgOpts.canMove = true;
    // 记录下鼠标当前位置
    this.imgOpts.left = e.target.offsetLeft;
    this.imgOpts.top = e.target.offsetTop;
    this.imgOpts.posX = e.clientX;
    this.imgOpts.posY = e.clientY;
  }

  handleCanvasMouseMove(e) {
    if (!this.imgOpts.canMove) {
      return;
    }
    // 当鼠标已按下时
    const { canvasImg } = this.refs;
    const { top, left, posX, posY, canvasWidth, canvasHeight, width, height } = this.imgOpts;
    const currentLeft = left + e.clientX - posX;
    const currentTop = top + e.clientY - posY;
    // 判断currentLeft和currentTop是否处于 负向间距~0之间 // 负向间距是canvasWith - width 多余的部分，是可以偏移的区域。
    if (currentLeft >= canvasWidth - width && currentLeft <= 0) {
      this.imgOpts.currentLeft = currentLeft;
      canvasImg.style.left = currentLeft + 'px';
      this.imgOpts.currentLeft = currentLeft;
    }
    if (currentTop >= canvasHeight - height && currentTop <= 0) {
      this.imgOpts.currentTop = currentTop;
      canvasImg.style.top = currentTop + 'px';
      this.imgOpts.currentTop = currentTop;
    }
  }

  handleCanvasMouseOut() {
    this.reGenerateImgPos();
  }

  handleCanvasMouseUp() {
    this.reGenerateImgPos();
  }

  reGenerateImgPos() {
    if (this.imgOpts.canMove) {
      // 重新渲染画图
      const { currentLeft, currentTop } = this.imgOpts;
      this.reRenderCanvas(currentLeft, currentTop)
      // 事件完毕，设置拦截
      this.imgOpts.canMove = false;
    }
  }

  reRenderCanvas(left = 0, top = 0, width = this.imgOpts.width, height = this.imgOpts.height) {
    // 画布锁定140 * 140
    this.canvas.width = this.imgOpts.canvasWidth;
    this.canvas.height = this.imgOpts.canvasHeight;

    this.setState({
      canvasAvatar: this.img.src
    });
    // 数据驱动需20ms,调整完毕之后，再近乎同步地改变图片尺寸
    // 尽可能避免图片的抖动
    setTimeout(() => {
      // 调整img宽高
      this.refs.canvasImg.style.width = width + 'px';
      this.refs.canvasImg.style.height = height + 'px';
      this.refs.canvasImg.style.left = left + 'px';
      this.refs.canvasImg.style.top = top + 'px';
    }, 16)
    // canvas重绘
    this.ctx.drawImage(this.img, left, top, width, height);
    this.dataURL = this.canvas.toDataURL('image/png');
    // this.setState({
    //   canvasPreview: this.dataURL
    // })
  }

  handleScaleBtnMouseDown(text) {
    clearInterval(this.pressTimer);
    this.pressTimer = setInterval(() => {
      this.execScaleCanvasAvatar(text);
    }, 50);
  }

  handleScaleBtnMouseUp() {
    clearInterval(this.pressTimer);
  }

  handleScaleBtnMouseOut() {
    clearInterval(this.pressTimer);
  }

  execScaleCanvasAvatar(text) {
    const { currentLeft, currentTop, kI, kD } = this.imgOpts;
    if (text === 'increase') {
      // 放大
      this.imgOpts.width *= kI;
      this.imgOpts.height *= kI;
      this.reRenderCanvas(currentLeft, currentTop, this.imgOpts.width, this.imgOpts.height);
    } else {
      // 缩小      
      this.imgOpts.width *= kD;
      this.imgOpts.height *= kD;
      this.reRenderCanvas(currentLeft, currentTop, this.imgOpts.width, this.imgOpts.height);
    }
  }

  handleModifyAvatarClick() {
    const { editCanvas } = this.state;
    if (!editCanvas) {
      const { upload } = this.refs;
      upload.click();
    } else {
      this.toUploadAvatar();
      // ...
    }
  }

  handleUploadCanvasChange(e) {
    // change则说明图片一定已上传,呼出修改面板
    // 创建canvas
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    // 创建新图片对象
    this.img = new Image();
    this.img.setAttribute("crossOrigin", 'Anonymous')
    // 渲染图片的监听函数，渲染canvas
    this.img.onload = () => {
      this.readImgOnload();
    }
    const file = e.provideSrc || e.target.files[0];
    this.setState({
      editCanvas: true
    });
    /**
     * 分两种情况：
     * 第一种：File，需要转成base64，再丢给this.img.src
     * 第二种：src(http连接), 直接丢给this.img.src
     */
    if (typeof file === 'string') {
      // 第一种，File -> base64 -> 丢给img
      this.img.src = appendTimestamp(file);
    } else {
      // 第二种，src -> 丢给img
      convertFileToBase64(file, (base64) => {
        this.img.src = base64;
      })
    }

  }

  readImgOnload() {
    this.props.showSuccess('预览成功，可以尝试缩放图片哦');
    const { left, width, canvasHeight } = this.imgOpts;
    /**
     * 读完之后再进行canvas画布第一次渲染，默认画布大小140px,图片宽度180px，故
     *   横向偏移 left: -20px  
     *   纵向偏移 top: ? px ，兼容长方形需自计算偏移 
     *    比例系数 * (画布高度 - 图片高度 - 4) 除以2
     *     画布不变，图像位置大小可变
     *   待重绘的dataURL渲染到canvasAvatar
     */
    const k = width / this.img.width;
    const height = k * this.img.height;
    const top = k * (canvasHeight - height - 4) / 2;
    this.reRenderCanvas(left, top, width, height);
    // 重置高度和偏移
    this.imgOpts.height = height;
    this.imgOpts.top = top;
    this.imgOpts.currentTop = top;
  }

  toUploadAvatar() {
    return new Promise(resolve => {
      // 将this.dataURL生成file类型
      if (!this.dataURL) {
        resolve(-1);
        return;
      }
      this.uploadAvatar = this.transferFatherBlobToFile();
      // 获取头像名称
      let avatarName = this.props.avatar && this.props.avatar.split('/').pop();
      if (!avatarName) {
        avatarName = `${randomId()}.png`; // 无头像数据
        uploadUserAvatar({ avatarName }); // 头像名称推入后端
      }
      createOSS().upload(getAvatarPath(avatarName), this.uploadAvatar).then(() => {
        resolve(1);
        // 更新单页头像
        this.onSetAvatar();
        this.props.showSuccess('上传成功');
      })
    });
  }

  transferFatherBlobToFile() {
    const blob = convertBase64ToBlob(this.dataURL);
    return new File([blob], Date.now() + '.png', { type: blob.type });
  }

  clearCanvasPanelAllInfo() {
    this.setState({
      editCanvas: false,
      canvasAvatar: ''
    });
    this.refreshCanvasAvatar();
    // 以及清理input[file]
    this.refs.upload.value = '';
  }

  refreshCanvasAvatar() {
    this.canvas = '';
    this.ctx = '';
    this.dataURL = '';
    this.img = '';
    this.imgOpts.width = 180;
    this.imgOpts.height = 180;
    this.imgOpts.top = -20;
    this.imgOpts.left = -20;
    this.imgOpts.posX = 0;
    this.imgOpts.posY = 0;
    this.imgOpts.currentLeft = -20;
    this.imgOpts.currentTop = -20;

  }

  handleBackBtnClick() {
    this.clearCanvasPanelAllInfo();
  }

  render() {
    const { canvasAvatar, editCanvas } = this.state;
    const { avatar } = this.props;
    return (
      <div className={
        styles.kidsPerson__userInfo__avatar + ' '
        // (editCanvas && styles.kidsPerson__userInfo__avatarCanvas)
      }>
        {editCanvas ? (
          <div className={styles.kidsPerson__userInfo__circleCanvasWrapper}>
            <div className={styles.kidsPerson__userInfo__circleCanvas}>
              <img className={styles.kidsPerson__userInfo__imgCanvas}
                src={canvasAvatar}
                ref='canvasImg'
                draggable={false}
                onMouseDown={this.handleCanvasMouseDown}
                onMouseMove={this.handleCanvasMouseMove}
                onMouseOut={this.handleCanvasMouseOut}
                onMouseUp={this.handleCanvasMouseUp}
              />
            </div>
            <div className={styles.kidsPerson__userInfo__scaleBtnWrapper}>
              <div className={styles.kidsPerson__userInfo__backBtn}
                onClick={this.handleBackBtnClick}
              >
                <img className={styles.kidsPerson__userInfo__backBtnIcon}
                  src="/static/canvas_backIcon.png" alt="返回"
                />
              </div>
              <div className={styles.kidsPerson__userInfo__scaleBtn}
                onMouseDown={this.handleScaleBtnMouseDown.bind(this, 'increase')}
                onMouseUp={this.handleScaleBtnMouseUp}
                onMouseOut={this.handleScaleBtnMouseOut}
              >+</div>
              <div className={styles.kidsPerson__userInfo__scaleBtn}
                onMouseDown={this.handleScaleBtnMouseDown.bind(this, 'decrease')}
                onMouseUp={this.handleScaleBtnMouseUp}
                onMouseOut={this.handleScaleBtnMouseOut}
              ></div>
            </div>
            {/* <div className={styles.kidsPerson__userInfo__canvasPreview}>
                    <img src={canvasPreview} alt="canvas预览" />
                  </div> */}
          </div>
        ) : (
            <div className={styles.kidsPerson__userInfo__circle}>
              <img className={styles.kidsPerson__userInfo__img}
                src={avatar || defaultAvatar}
              />
            </div>
          )}

        <div
          className={
            styles.kidsPerson__userInfo__modify + ' ' +
            (editCanvas && styles['kidsPerson__userInfo__modify--unClick'])
          }
          onClick={this.handleModifyAvatarClick}
        >{editCanvas ? '确认修改' : '上传头像'}</div>
        <input ref="upload"
          type="file"
          style={{ display: 'none' }}
          // onChange={this.handleUploadChange}
          onChange={this.handleUploadCanvasChange}
        />
        {/* 开设初始头像选择功能 */}
        <InfoProvideAvatar
          toUploadAvatar={this.toUploadAvatar}
          onHandleBackBtnClick={this.handleBackBtnClick}
          onHandleUploadCanvasChange={this.handleUploadCanvasChange}
          refreshCanvasAvatar={this.refreshCanvasAvatar}
        />

      </div>

    );
  }
}
export default InfoCanvas;