exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_bubble-box_26aq2 {\n  position: relative;\n  color: #333;\n  font-size: 18px;\n  position: fixed;\n  z-index: 10;\n  padding: 10px 16px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  border-top-left-radius: 0;\n  background: white;\n  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.25);\n          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.25);\n  -webkit-animation: style_up-opac-in_1n70h 0.3s;\n          animation: style_up-opac-in_1n70h 0.3s;\n}\n.style_bubble-box_26aq2::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 12px;\n  height: 12px;\n  -webkit-transform: translateX(-80%);\n  -ms-transform: translateX(-80%);\n  transform: translate3d(-100%, -11%, 0);\n  background-image: -o-radial-gradient(0 180%, 20px, transparent 20px, #ccc 23px, white 20px);\n  background-image: radial-gradient(20px at 0 180%, transparent 20px, #ccc 23px, white 20px);\n  border-top: 1.5px solid #ccc;\n}\n@-webkit-keyframes style_up-opac-in_1n70h {\n  from {\n    -webkit-transform: translateY(35%);\n            transform: translateY(35%);\n    opacity: 0;\n  }\n  to {\n    -webkit-transform: translateY(0);\n            transform: translateY(0);\n    opacity: 1;\n  }\n}\n@keyframes style_up-opac-in_1n70h {\n  from {\n    -webkit-transform: translateY(35%);\n            transform: translateY(35%);\n    opacity: 0;\n  }\n  to {\n    -webkit-transform: translateY(0);\n            transform: translateY(0);\n    opacity: 1;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"bubble-box": "style_bubble-box_26aq2",
	"bubbleBox": "style_bubble-box_26aq2",
	"up-opac-in": "style_up-opac-in_1n70h",
	"upOpacIn": "style_up-opac-in_1n70h"
};