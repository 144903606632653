import React, { Component } from 'react';
import styles from './style.css';
import throttle from 'lodash.throttle';

class SliderCaptcha extends Component {
  constructor(props) {
    super(props);
    this.imgSrc = require('./pic-2.png')

    this.mainCanvas = null; // 主画板
    this.blockCanvas = null; // 滑块画板
    this.thumb = null; // 拇指滑块
    this.thumbMask = null // 拇指进度条
    this.bar = null // 底部栏

    this.blockCtx = null; // 滑块画布
    this.blockCtx = null; // 滑块画布

    this.options = {
      width: 375,
      height: 347,
      sliderL: 58, // 滑块边长
      sliderR: 12, // 滑块半径
      r_offset: 3, // 圆偏移
      lineWidth: 3, // 画笔宽度
      allowed_error_offset: 10, // 允许偏移误差
      L: 'should calc',
      PI: Math.PI
    }
    this.options.L = this.options.sliderL + this.options.sliderR * 2;

    // 空缺图形
    this.vacancy = {
      x: 0,
      y: 0
    }

    // 拖拽情况
    this.dragInfo = {
      isDown: false,
      startLeft: 0, // 初始偏移
      originX: 0, // 拖拽初始 x 轴位置
    }

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragMove = throttle(this.handleDragMove.bind(this), 20);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }


  componentDidMount() {
    // 初始化 DOM 绑定
    this.initDom();
    // 注册 document 事件 监听滑块
    this.bindListener();
    // 初始化 载入的图片
    this.initImage();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  bindListener() {
    document.addEventListener('mousemove', this.handleDragMove);
    document.addEventListener('touchmove', this.handleDragMove);
    document.addEventListener('mouseup', this.handleDragEnd);
    document.addEventListener('touchend', this.handleDragEnd);
  }

  removeListener() {
    document.removeEventListener('mousemove', this.handleDragMove);
    document.removeEventListener('touchmove', this.handleDragMove);
    document.removeEventListener('mouseup', this.handleDragEnd);
    document.removeEventListener('touchend', this.handleDragEnd);
  }

  generateCanvas() {

    // 随机路径
    const getRandomNumberRange = (start, end) => {
      return Math.round(Math.random() * (end - start) + start);
    }

    // 绘制空缺图案
    const drawImg = (ctx, operation) => {
      const l = this.options.sliderL; // 滑块长
      const r = this.options.sliderR; // 圆半径
      const PI = this.options.PI;
      const r_offset = this.options.r_offset; // 圆偏移
      const { x, y } = this.vacancy // 绘点初始坐标
      // console.log('随机初始坐标点:', x, y)
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x + l / 2, y - r + r_offset, r, 0.75 * PI, 2.25 * PI);
      ctx.lineTo(x + l, y);
      ctx.arc(x + l + r - r_offset, y + l / 2, r, 1.25 * PI, 2.75 * PI);
      ctx.lineTo(x + l, y + l);
      ctx.lineTo(x, y + l);
      ctx.arc(x + r - r_offset, y + l / 2, r, 0.75 * PI, 1.25 * PI, true);
      ctx.lineTo(x, y);
      ctx.lineWidth = this.options.lineWidth;
      if (operation === 'fill') {
        ctx.fillStyle = 'rgba(255,255,255,0.95)';
        ctx.strokeStyle = 'rgba(0,0,0,0.5)';
      } else {
        ctx.fillStyle = 'rgba(255,255,255,0.95)';
        ctx.strokeStyle = 'rgba(255,255,255,0.95)';
      }

      ctx.stroke();
      ctx[operation]();
      ctx.globalCompositeOperation = 'destination-over';

    }

    var extraSpace = 5; // 额外的空间

    this.vacancy = {
      x: getRandomNumberRange(this.options.L + extraSpace, this.options.width - (this.options.L + extraSpace)),
      y: getRandomNumberRange(extraSpace + this.options.sliderR * 2, this.options.height - (this.options.L + extraSpace)),
    }

    // 绘制画布内容
    drawImg(this.mainCtx, 'fill');
    drawImg(this.blockCtx, 'clip');

  }

  initImage() {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {

      // 生成积木块绘图路径
      this.generateCanvas();
      // 图片渲染画布
      this.mainCtx.drawImage(img, 0, 0, this.options.width, this.options.height);
      this.blockCtx.drawImage(img, 0, 0, this.options.width, this.options.height);

      // 提取第二个画布的积木块
      // 提取第二个画布内容
      const ImageData = this.blockCtx.getImageData(this.vacancy.x, this.vacancy.y - this.options.sliderR * 2, this.options.L + this.options.lineWidth, this.options.L + this.options.lineWidth);
      this.blockCanvas.width = this.options.L;

      this.blockCtx.putImageData(ImageData, 0, this.vacancy.y - this.options.sliderR * 2);
    }

    img.src = this.imgSrc;

  }

  initDom() {
    // thumb 滑块 元素获取
    this.thumb = this.refs.thumb;
    this.thumbMask = this.refs.thumbMask;
    this.bar = this.refs.bar;
    // canvas 元素获取
    this.mainCanvas = this.refs.mainCanvas;
    this.blockCanvas = this.refs.blockCanvas;
    // canvas 画布 元素获取
    this.mainCtx = this.mainCanvas.getContext('2d');
    this.blockCtx = this.blockCanvas.getContext('2d');

  }

  handleDragStart(e) {
    const clientX = e.touches && e.touches[0] ? e.touches[0].clientX : e.clientX
    this.dragInfo = {
      isDown: true,
      originX: clientX,
      startLeft: parseInt(getComputedStyle(this.thumb).left)
    }
    this.thumb.children[0].style.color = '#fff';
    this.thumb.style.backgroundColor = 'skyblue';
  }

  handleDragMove(e) {
    if (!this.dragInfo.isDown) {
      return;
    }
    const clientX = e.touches && e.touches[0] ? e.touches[0].clientX : e.clientX
    // 获取 与 源位置 相差的 偏移
    const offsetX = clientX - this.dragInfo.originX;
    // 元素当前的 left = startLeft + offsetX
    const currentLeft = this.dragInfo.startLeft + offsetX;
    // 判断数值是否在范围内
    if (currentLeft < 0 || currentLeft > this.options.width - 40) {
      return;
    }
    this.thumb.style.left = currentLeft + 'px';
    this.blockCanvas.style.left = currentLeft + 'px';
    this.thumbMask.style.width = currentLeft + 'px';
  }

  handleDragEnd(e) {
    // ... 比较 拖拽的位置
    const blockLeft = parseInt(this.blockCanvas.style.left);
    const correctLeft = this.vacancy.x;

    // 防止刷新的 end 事件引起重新刷新
    if (blockLeft === 0) {
      return;
    }

    const verify = Math.abs(blockLeft - correctLeft) < this.options.allowed_error_offset;
    console.log(verify);
    if (verify) {
      this.props.onSuccess();
      this.removeListener();
    } else {
      this.bar.innerText = '再试一次';
      this.removeListener();
      setTimeout(() => {
        this.bar.innerText = '向右滑动填充拼图';
        this.resetAll();
      }, 1000)
    }
  }

  clearCanvas() {
    if (this.refs.blockCanvas) {
      this.refs.blockCanvas.width = this.options.width;
    }
    this.mainCtx.clearRect(0, 0, this.options.width, this.options.height);
    this.blockCtx.clearRect(0, 0, this.options.width, this.options.height);
  }

  resetAll() {
    this.dragInfo = {};
    this.vacancy = {};

    this.thumb.style.left = 0;
    this.blockCanvas.style.left = 0;
    this.thumbMask.style.width = 0;
    this.thumb.children[0].style.color = '';
    this.thumb.style.backgroundColor = '';

    this.clearCanvas();
    this.bindListener();
    this.initImage();
  }

  render() {
    return (
      <div className={styles.captcha}>
        <div className={styles.captcha__mask}>
        </div>
        <div
          className={styles.captcha__container}
          style={{
            width: this.options.width + 'px',
            height: `calc(${this.options.height}px + 46px)`
          }}
        >
          <canvas
            className={styles.captcha__canvas}
            style={{
              width: this.options.width + 'px',
              height: this.options.height + 'px'
            }}
            width={this.options.width}
            height={this.options.height}
            ref="mainCanvas"
          />
          <canvas
            style={{
              width: this.options.L,
              height: this.options.height + 'px'
            }}
            width={this.options.width}
            height={this.options.height}
            className={styles.captcha__canvas}
            ref="blockCanvas"
          />

          <i className={
            "iconfont icon-cha" + ' ' +
            styles.captcha__close
          }
            onClick={this.props.onCaptchaClose}
          />

          <div className={styles.captcha__drag}>

            <div
              className={styles.captcha__drag__mask}
              ref="thumbMask"
            ></div>
            <div
              className={styles.captcha__drag__thumb}
              ref="thumb"
              onMouseDown={this.handleDragStart}
              onTouchStart={this.handleDragStart}
            >
              <i className={
                "iconfont icon-jiantou1" + ' ' +
                styles.captcha__drag__thumb__arrow
              }
              ></i>
            </div>
            <div className={styles.captcha__drag__bar} ref="bar">
              向右滑动填充拼图
            </div>

          </div>
        </div>
      </div>
    );
  }


}

export default SliderCaptcha;