import React, { Component } from 'react';
import { connect } from 'react-redux';
import Info from './components/Info';
import Create from './components/Create';
import Sprite from './components/Sprite';
import styles from './style.css';

class UserPanel extends Component {
  render() {
    const { userPanelMode } = this.props;
    return (
      <div>
        <div className={styles.kidsPerson__mask}></div>
        {/* 此处通信发起者是 Menubar/UserBtn
          一样的，我们规定 0隐藏 1个人 2创作 3 素材 + 退出按钮 
         */}
        {/* 个人中心 */}
        {userPanelMode === 1 && <Info />}

        {/* 我的创作 */}
        {userPanelMode === 2 && <Create />}

        {/* 我的素材 */}
        {userPanelMode === 3 && <Sprite />}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  outerPanelMode: state.kids.outerPanel.outerPanelMode,
  userPanelMode: state.kids.userPanel.userPanelMode,
  showSaveProjectPanel: state.kids.userPanel.showSaveProjectPanel
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPanel);