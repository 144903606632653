/**
 * kids-user-panel.js: 基于GUI的用于大多信息状态
 * 代码行数较多
 */

import {
  getUserAllInfo,
  getUserSpriteLabel,
  getUserWorkLabel,
  getUserWork,
  getUserSprite,
  login,
  logout,
  getUserMsgUnreadCount,
} from "../lib/fetch-api";
import { defaultAvatar } from "../lib/utils/kids-config";
// 引入其他的actionTypes 异步action会使用到
const {
  actions: { setToastShow },
} = require("./kids-app");
const {
  actions: { setOuterPanelMode },
} = require("./kids-outer-panel");
const {
  actions: { getUserPageInfo },
} = require("./kids-page");
const {
  actions: { setClearEntities },
} = require("./kids-entities");

let unreadCountTimer; // 未读消息轮询定时器

// 自身的actionTypes
// -- 用户登录态 & 内层窗口
const setIsLogin = "kids/userPanel/SET_IS_LOGIN";
const setUserPanelMode = "kids/userPanel/SET_USER_PANEL_MODE";
const setDrawBoard = "kids/userPanel/SET_DRAW_BOARD";
// -- 用户信息状态
const setId = "kids/userPanel/SET_ID";
const setPhone = "kids/userPanel/SET_PHONE";
const setRole = "kids/userPanel/SET_ROLE";
const setUsername = "kids/userPanel/SET_USERNAME";
const setAvatar = "kids/userPanel/SET_AVATAR";
const setGender = "kids/userPanel/SET_GENDER";
const setRegisterTime = "kids/userPanel/SET_REGISTER_TIME";
const setEmail = "kids/userPanel/SET_EMAIL";
const setBirthday = "kids/userPanel/SET_BIRTHDAY";
const setSchool = "kids/userPanel/SET_SCHOOL";
const setClassroom = "kids/userPanel/SET_CLASSROOM";
const setSlogan = "kids/userPanel/SET_SLOGAN";
const setWXUnionId = "kids/userPanel/SET_WX_UNION_ID";
const setUserInfo = "kids/userPanel/SET_USER_INFO";

const setAutoSaveWork = "kids/userPanel/SET_AUTO_SAVE_WORK";

// -- 用户保存作品页&作品状态
const setShowSaveProjectPanel = "kids/userPanel/SET_SHOW_SAVE_PROJECT_PANEL";
const setProjectName = "kids/userPanel/SET_PROJECT_NAME";
const setProjectComment = "kids/userPanel/SET_PROJECT_COMMENT";
const setShareLabel = "kids/userPanel/SET_SHARE_LABEL";
const setProjectList = "kids/userPanel/SET_PROJECT_LIST";
// -- 用户保存素材页&素材状态
const setShowSaveSpritePanel = "kids/userPanel/SET_SHOW_SAVE_SPRITE_PANEL";
const setSpriteName = "kids/userPanel/SET_SPRITE_NAME";
const setSpriteComment = "kids/userPanel/SET_SPRITE_COMMENT";
const setSpriteList = "kids/userPanel/SET_SPRITE_LIST";
// -- 标签管理页&标签状态
const setShowLabelPanel = "kids/userPanel/SET_SHOW_LABEL_PANEL";
const setWorkLabelList = "kids/userPanel/SET_WORK_LABEL_LIST";
const setSpriteLabelList = "kids/userPanel/SET_SPRITE_LABEL_LIST";
const setSelectedWorkLabel = "kids/userPanel/SET_SELECTED_WORK_LABEL";
const setSelectedSpriteLabel = "kids/userPanel/SET_SELECTED_SPRITE_LABEL";
const setCurrentWorkLabelInfo = "kids/userPanel/SET_CURRENT_WORK_LABEL_INFO";
const setCurrentWorkLabel = "kids/userPanel/SET_CURRENT_WORK_LABEL";
const setCurrentSpriteLabel = "kids/userPanel/SET_CURRENT_SPRITE_LABEL";
const setCurrentWorkShareCode = "kids/userPanel/SET_CURRENT_WORK_SHARE_CODE";
const setCurrentSpriteShareCode =
  "kids/userPanel/SET_CURRENT_SPRITE_SHARE_CODE";
const setWorkShareCode = "kids/userPanel/SET_WORK_SHARE_CODE";
const setWorkShareLabelName = "kids/userPanel/SET_WORK_SHARE_LABEL_NAME";
const setSpriteShareLabelName = "kids/userPanel/SET_SPRITE_SHARE_LABEL_NAME";
const setWorkShareList = "kids/userPanel/SET_WORK_SHARE_LIST";
const setSpriteShareList = "kids/userPanel/SET_SPRITE_SHARE_LIST";

const setWorkShareUserId = "kids/userPanel/SET_WORK_SHARE_USERID";
const setWorkShareUsername = "kids/userPanel/SET_WORK_SHARE_USERNAME";
const setSpriteShareUsername = "kids/userPanel/SET_SPRITE_SHARE_USERNAME";
// -- 清理相关
const setClearUserPanel = "kids/userPanel/SET_CLEAR_USER_PANEL";
// -- 消息相关
const setUnreadCount = "kids/userPanel/SET_UNREAD_COUNT";

const initialState = {
  // 登录态
  isLogin: true, // 假登录，防止毫秒间隔内被跳转
  // 个人信息 (严格同步后端字段)
  id: "",
  wx_union_id: "",
  phone: "",
  role: "",
  username: "",
  avatar: defaultAvatar,
  gender: "",
  register_time: "",
  email: "",
  birthday: "",
  school: "",
  classroom: "",

  // 自动保存作品
  auto_save_work: false,

  // 窗口状态 0隐藏1个人中心2我的创作3我的素材4退出
  userPanelMode: 0,
  // 画板窗口
  showDrawBoard: 0,
  // (保存作品)
  showSaveProjectPanel: 0, // 保存作品面板
  projectName: "",
  projectComment: "",
  shareLabel: "",
  workLabelList: [],
  // (保存素材)
  showSaveSpritePanel: "", // 暂存聚焦态的作品ID
  spriteName: "",
  spriteComment: "",
  spriteLabelList: [],
  // 标签面板相关
  showLabelPanel: 0, // 面板 1作品标签 2素材标签
  selectedWorkLabelId: 0, // 上传作品标签选中ID
  selectedSpriteLabelId: 0, // 上传素材标签选中ID
  currentWorkLabelInfo: "", // 当前选中的标签信息
  currentWorkLabelId: 0, // 实际作品标签选中ID
  currentSpriteLabelId: 0, // 实际素材标签选中ID
  currentWorkShareCode: 0, // 当前作品共享码
  currentSpriteShareCode: 0, // 当前素材共享码
  workShareLabelName: "", // 共享标签名
  spriteShareLabelName: "",
  workShareList: [], // 共享数据集合
  spriteShareList: [],
  workShareUsername: "", // 共享的用户昵称
  spriteShareUsername: "",
  // (我的作品&我的素材&分页)
  projectList: [], // 用户作品集，
  spriteList: [], // 用户素材集，
  // 消息相关
  unreadCount: 0, // 消息未读条目
  msgList: [], // 消息集合
};

const actions = {
  // 登录态
  setIsLogin: (isLogin) => ({
    type: setIsLogin,
    isLogin,
  }),
  setUserPanelMode: (mode) => ({
    type: setUserPanelMode,
    mode,
  }),
  setDrawBoard: (mode) => ({
    type: setDrawBoard,
    mode,
  }),
  // 个人中心相关
  setId: (id) => ({
    type: setId,
    id,
  }),
  setPhone: (phone) => ({
    type: setPhone,
    phone,
  }),
  setRole: (role) => ({
    type: setRole,
    role,
  }),
  setUsername: (username) => ({
    type: setUsername,
    username,
  }),
  setAvatar: (avatar) => ({
    type: setAvatar,
    avatar,
  }),
  setGender: (gender) => ({
    type: setGender,
    gender,
  }),
  setRegisterTime: (registerTime) => ({
    type: setRegisterTime,
    registerTime,
  }),
  setEmail: (email) => ({
    type: setEmail,
    email,
  }),
  setBirthday: (birthday) => ({
    type: setBirthday,
    birthday,
  }),
  setSchool: (school) => ({
    type: setSchool,
    school,
  }),
  setClassroom: (classroom) => ({
    type: setClassroom,
    classroom,
  }),
  setSlogan: (slogan) => ({
    type: setSlogan,
    slogan,
  }),
  setWXUnionId: (unionId) => ({
    type: setWXUnionId,
    unionId,
  }),

  setAutoSaveWork: (autoSaveWork) => ({
    type: setAutoSaveWork,
    autoSaveWork,
  }),

  // 保存作品面板
  setShowSaveProjectPanel: (show) => ({
    type: setShowSaveProjectPanel,
    show,
  }),
  setProjectName: (name) => ({
    type: setProjectName,
    name,
  }),
  setProjectComment: (comment) => ({
    type: setProjectComment,
    comment,
  }),
  setShareLabel: (shareLabel) => ({
    type: setShareLabel,
    shareLabel,
  }),
  setProjectList: (list) => ({
    type: setProjectList,
    list,
  }),
  // 保存素材相关
  setShowSaveSpritePanel: (show) => ({
    type: setShowSaveSpritePanel,
    show,
  }),
  setSpriteName: (name) => ({
    type: setSpriteName,
    name,
  }),
  setSpriteComment: (comment) => ({
    type: setSpriteComment,
    comment,
  }),
  setSpriteList: (list) => ({
    type: setSpriteList,
    list,
  }),
  // 标签相关
  setShowLabelPanel: (show) => ({
    type: setShowLabelPanel,
    show,
  }),
  setSelectedWorkLabel: (id) => ({
    type: setSelectedWorkLabel,
    id,
  }),
  setSelectedSpriteLabel: (id) => ({
    type: setSelectedSpriteLabel,
    id,
  }),
  setWorkLabelList: (list) => ({
    type: setWorkLabelList,
    list,
  }),
  setSpriteLabelList: (list) => ({
    type: setSpriteLabelList,
    list,
  }),
  setCurrentWorkLabel: (id) => ({
    type: setCurrentWorkLabel,
    id,
  }),
  setCurrentWorkLabelInfo: (info) => ({
    type: setCurrentWorkLabelInfo,
    info,
  }),
  setCurrentSpriteLabel: (id) => ({
    type: setCurrentSpriteLabel,
    id,
  }),
  setCurrentWorkShareCode: (code) => ({
    type: setCurrentWorkShareCode,
    code,
  }),
  setCurrentSpriteShareCode: (code) => ({
    type: setCurrentSpriteShareCode,
    code,
  }),
  setWorkShareCode: (shareCode) => ({
    type: setWorkShareCode,
    shareCode,
  }),
  setWorkShareLabelName: (name) => ({
    type: setWorkShareLabelName,
    name,
  }),
  setSpriteShareLabelName: (name) => ({
    type: setSpriteShareLabelName,
    name,
  }),
  setWorkShareList: (list) => ({
    type: setWorkShareList,
    list,
  }),
  setSpriteShareList: (list) => ({
    type: setSpriteShareList,
    list,
  }),
  setWorkShareUserId: (userId) => ({
    type: setWorkShareUserId,
    userId,
  }),
  setWorkShareUsername: (name) => ({
    type: setWorkShareUsername,
    name,
  }),
  setSpriteShareUsername: (name) => ({
    type: setSpriteShareUsername,
    name,
  }),
  // 消息相关
  setUnreadCount: (count) => ({
    type: setUnreadCount,
    count,
  }),
  // 初始化所有数据
  setClearUserPanel: () => ({
    type: setClearUserPanel,
  }),
  // 更新用户信息
  setUserInfo: (info) => ({
    type: setUserInfo,
    info,
  }),
  // 异步action
  submitLogin: (dispatch, loginToken) => onSubmitLogin(dispatch, loginToken),
  getLoginInfo: (dispatch, officialSite = false) =>
    onGetLoginInfo(dispatch, officialSite),
  logout: (dispatch) => onLogout(dispatch),
  onUpdateUserWork: (dispatch) => onUpdateUserWork(dispatch),
  updateUserWorkLabel: (dispatch) => onUpdateUserWorkLabel(dispatch),
  updateUserSpriteLabel: (dispatch) => onUpdateUserSpriteLabel(dispatch),
};

// 登录
const onSubmitLogin = (
  dispatch,
  { phone, password, captcha, officialSite = false }
) => {
  // ...处理登录事件
  login({
    phone,
    password,
    captcha,
  }).then((res) => {
    if (res.errno === -1) {
      // 发送错误action
      dispatch(setToastShow("error", res.msg));
    } else {
      onGetLoginInfo(dispatch, officialSite);
      dispatch(setToastShow("success", "登录成功"));
    }
  });
};

// 登出
const onLogout = (dispatch) => {
  // ..处理退出事件
  logout().then((res) => {
    if (res.errno === 0) {
      // 将kids-user-panel数据全部初始化
      dispatch(actions.setClearUserPanel());
      dispatch(setClearEntities()); // 清理领域实体（用户的特征映射肯定不同，所以还是清理干净比较好）
      dispatch(actions.setIsLogin(false));
      clearInterval(unreadCountTimer);
      // dispatch(setToastShow('success', '已退出'));
    }
  });
};

// 判断需要获取的信息种类（2种情况GUI和officialSite)
const onGetLoginInfo = (dispatch, officialSite = false) => {
  // 先获取必要信息
  const { setIsLogin, setUserInfo } = actions;
  return getUserAllInfo().then((res) => {
    if (res.errno === -1) {
      dispatch(setIsLogin(false));
      dispatch(setToastShow("error", "您还未登录"));
    } else {
      // 本身的actions
      dispatch(setIsLogin(true)); // 登录态true
      dispatch(setOuterPanelMode(0)); // 关闭登录面板
      if (!res.data.avatar) {
        // 头像为空强行注入默认头像
        res.data.avatar = defaultAvatar;
      }
      dispatch(setUserInfo(res.data)); // 更新用户信息
      // 根据站点分别获取信息
      if (officialSite) {
        return onGetOfficialSiteLoginInfo(dispatch);
      } else {
        return onGetGUILoginInfo(dispatch);
      }
    }
  });
};

// GUI页用户信息
const onGetGUILoginInfo = (dispatch) => {
  // 此处异步返回，是为了用户更新的头像能单页面形式渲染。
  return new Promise((resolve) => {
    onUpdateUserWork(dispatch); // 更新用户作品标签
    onUpdateUserSprite(dispatch); // 更新用户素材标签
    // dispatch(setToastShow('success', '用户信息获取成功'));
    resolve();
  });
};

// 官网页用户信息
const onGetOfficialSiteLoginInfo = (dispatch) => {
  return new Promise((resolve) => {
    getUnreadCount(dispatch); // 获取未读条目
    getUserPageInfo(dispatch); // 获取UserPage数据
    // 轮询20s获取未读条目
    unreadCountTimer = setInterval(() => {
      getUnreadCount(dispatch);
    }, 20000);
    resolve();
  });
};

// 获取未读条目
const getUnreadCount = (dispatch) => {
  getUserMsgUnreadCount().then(({ errno, data: { count = 0 } }) => {
    dispatch(actions.setUnreadCount(count));
  });
};

// 更新用户作品 & 标签数据
const onUpdateUserWork = (dispatch) => {
  return Promise.all([
    // 获取标签
    onUpdateUserWorkLabel(dispatch),
    // 获取作品
    getUserWorkLabel().then(({ data: list }) => {
      // 标签首部加入'无'
      list.unshift({
        id: 0,
        label: "无",
      });
      dispatch(actions.setWorkLabelList(list));
      return list;
    }),
    // 拿作品
    getUserWork().then(({ data: list }) => {
      dispatch(actions.setProjectList(list.reverse()));
    }),
  ]);
};
const onUpdateUserWorkLabel = (dispatch) => {
  return getUserWorkLabel().then(({ data: list }) => {
    // 标签首部加入'无'
    list.unshift({
      id: 0,
      label: "无",
    });
    dispatch(actions.setWorkLabelList(list));
    return list;
  });
};

// 更新用户素材 & 标签数据
const onUpdateUserSprite = (dispatch) => {
  return Promise.all([
    // 获取标签，
    onUpdateUserSpriteLabel(dispatch),
    // 获取素材
    getUserSprite().then(({ data: list }) => {
      dispatch(actions.setSpriteList(list.reverse()));
    }),
  ]);
};
const onUpdateUserSpriteLabel = (dispatch) => {
  return getUserSpriteLabel().then(({ data: list }) => {
    // 标签首部加入'无'
    list.unshift({
      id: 0,
      label: "无",
    });
    dispatch(actions.setSpriteLabelList(list));
    return list;
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // 登录态
    case setIsLogin:
      return { ...state, isLogin: action.isLogin };
    case setUserPanelMode:
      return { ...state, userPanelMode: action.mode };
    case setDrawBoard:
      return { ...state, showDrawBoard: action.mode };
    // 用户信息状态
    case setId:
      return { ...state, id: action.id };
    case setPhone:
      return { ...state, phone: action.phone };
    case setRole:
      return { ...state, role: action.role };
    case setUsername:
      return { ...state, username: action.username };
    case setAvatar:
      return { ...state, avatar: action.avatar };
    case setGender:
      return { ...state, gender: action.gender };
    case setRegisterTime:
      return { ...state, register_time: action.registerTime };
    case setEmail:
      return { ...state, email: action.email };
    case setBirthday:
      return { ...state, birthday: action.birthday };
    case setSchool:
      return { ...state, school: action.school };
    case setClassroom:
      return { ...state, classroom: action.classroom };
    case setSlogan:
      return { ...state, slogan: action.slogan };
    case setWXUnionId:
      return { ...state, wx_union_id: action.unionId };

    case setAutoSaveWork:
      return { ...state, autoSaveWork: action.autoSaveWork };
    case setUserInfo:
      const { auto_save_work } = action.info
      const autoSaveWork = auto_save_work ? true : false
      return { ...state, ...action.info, autoSaveWork };

    // 保存作品相关
    case setShowSaveProjectPanel:
      return {
        ...state,
        showSaveProjectPanel: action.show,
      };
    case setProjectName:
      return { ...state, projectName: action.name };
    case setProjectComment:
      return { ...state, projectComment: action.comment };
    case setProjectList:
      return {
        ...state,
        projectList: action.list,
      };
    case setShareLabel:
      return { ...state, shareLabel: action.shareLabel };
    // 素材相关
    case setShowSaveSpritePanel:
      return {
        ...state,
        showSaveSpritePanel: action.show,
      };
    case setSpriteName:
      return { ...state, spriteName: action.name };
    case setSpriteComment:
      return { ...state, spriteComment: action.comment };
    case setSpriteList:
      return {
        ...state,
        spriteList: action.list,
      };
    // 标签相关
    case setShowLabelPanel:
      return { ...state, showLabelPanel: action.show };
    case setWorkLabelList:
      return { ...state, workLabelList: action.list };
    case setSpriteLabelList:
      return { ...state, spriteLabelList: action.list };
    case setSelectedWorkLabel:
      return {
        ...state,
        selectedWorkLabelId: action.id,
      };
    case setSelectedSpriteLabel:
      return {
        ...state,
        selectedSpriteLabelId: action.id,
      };
    case setCurrentWorkLabel:
      return { ...state, currentWorkLabelId: action.id };
    case setCurrentWorkLabelInfo:
      return { ...state, currentWorkLabelInfo: action.info };
    case setCurrentSpriteLabel:
      return { ...state, currentSpriteLabelId: action.id };
    case setCurrentWorkShareCode:
      return { ...state, currentWorkShareCode: action.code };
    case setCurrentSpriteShareCode:
      return { ...state, currentSpriteShareCode: action.code };
    case setWorkShareCode:
      return { ...state, workShareCode: action.shareCode };
    case setWorkShareLabelName:
      return { ...state, workShareLabelName: action.name };
    case setSpriteShareLabelName:
      return { ...state, spriteShareLabelName: action.name };
    case setWorkShareList:
      return { ...state, workShareList: action.list };
    case setSpriteShareList:
      return { ...state, spriteShareList: action.list };
    case setWorkShareUsername:
      return { ...state, workShareUsername: action.name };
    case setWorkShareUserId:
      return { ...state, workShareUserId: action.userId };
    case setSpriteShareUsername:
      return { ...state, spriteShareUsername: action.name };
    // 消息相关
    case setUnreadCount:
      return { ...state, unreadCount: action.count };
    // 清理相关
    case setClearUserPanel:
      return { ...initialState };
    default:
      return state;
  }
};

export { reducer as default, actions };
