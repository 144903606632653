import React, { Component } from 'react';
import bindAll from 'lodash.bindall';
import styles from './style.css';
import { connect } from 'react-redux';
const { actions: {
  setOuterPanelMode,
  setRecoverProgress, setRecoverCode,
  setRecoverPassword, setRecoverRePassword
} } = require('../../../../../reducers/kids-outer-panel');
import closeIcon from 'static/closeIcon.png';
import userIcon from 'static/userIcon.png';
import codeIcon from 'static/codeIcon.png';
import passIcon from 'static/passIcon.png';

class Forget extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleCloseBtnClick'
    ]);
  }

  handleCloseBtnClick() {
    this.props.changePanel(0);
  }

  render() {
    const { loginPhone, recoverCode, recoverProgress, recoverPassword, recoverRePassword } = this.props;
    return (
      <div>
        <div className={styles.kidsLogin__header}>
          <p className={styles.kidsLogin__title}>忘记密码</p>
          <img className={styles.kidsLogin__close}
            src={closeIcon}
            onClick={this.handleCloseBtnClick} />
        </div>
        <div className={styles.kidsLogin__progress}>
          {
            ['输入手机号', '设置新密码', '设置成功'].map((text, index) => {
              index = index + 1;
              return (
                <div key={index}
                  className={
                    styles.kidsLogin__progress__item + ' ' +
                    (recoverProgress === index && styles['kidsLogin__progress__item--active']) + ' ' +
                    (recoverProgress > index && styles['kidsLogin__progress__item--complete'])
                  }
                >
                  <div className={styles.kidsLogin__progress__item__circle}>
                    {recoverProgress > index ? (
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
                    ) : index}
                  </div>
                  <div className={styles.kidsLogin__progress__item__text}>{text}</div>
                </div>
              )
            })
          }
        </div>
        {
          recoverProgress === 1 && (
            <div className={styles.kidsLogin__form}>
              <div className={styles.kidsLogin__form__item}>
                <img className={styles.kidsLogin__form__icon}
                  src={userIcon} />
                <input type="text" name='phone' placeholder='请输入手机号码'
                  value={loginPhone}
                  onChange={(e) => this.setState({ loginPhone: e.target.value })}
                />
              </div>
              <div className={styles.kidsLogin__form__item}>
                <img className={styles.kidsLogin__form__icon}
                  src={codeIcon} />
                <input type="text" placeholder='请输入短信验证码'
                  onChange={(e) => this.setState({ recoverCode: e.target.value })}
                  value={recoverCode}
                />
                <p className={styles.kidsLogin__form__text}>获取短信验证码</p>
              </div>
              <div className={styles.kidsLogin__login}
                onClick={this.handleRecoverPwdNext}>下一步</div>
            </div>
          )
        }
        {
          recoverProgress === 2 && (
            <div className={styles.kidsLogin__form}>
              <div className={styles.kidsLogin__form__item}>
                <img className={styles.kidsLogin__form__icon}
                  src={passIcon} />
                <input type="password" placeholder='请输入密码'
                  onChange={(e) => this.setState({ recoverPassword: e.target.value })}
                  value={recoverPassword}
                />
              </div>
              <div className={styles.kidsLogin__form__item}>
                <img className={styles.kidsLogin__form__icon}
                  src={passIcon} />
                <input type="password" placeholder='请再次输入密码'
                  onChange={(e) => this.setState({ recoverRePassword: e.target.value })}
                  value={recoverRePassword}
                />
              </div>
              <div className={styles.kidsLogin__login}
                onClick={this.handleRecoverPwdNext}>下一步</div>
            </div>
          )
        }
        {
          recoverProgress === 3 && (
            <div className={styles.kidsLogin__form}>
              重置密码完成
                <div className={styles.kidsLogin__login}
                onClick={this.handleRecoverPwdNext}>立即登录</div>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginPhone: state.kids.outerPanel.loginPhone,
  recoverProgress: state.kids.outerPanel.recoverProgress,
  recoverCode: state.kids.outerPanel.recoverCode,
  recoverPassword: state.kids.outerPanel.recoverPassword,
  recoverRePassword: state.kids.outerPanel.recoverRePassword
});

const mapDispatchToProps = (dispatch) => ({
  changePanel: (mode) => dispatch(setOuterPanelMode(mode)),
  setRecoverProgress: (progress) => dispatch(setRecoverProgress(progress)),
  setRecoverCode: (code) => dispatch(setRecoverCode(code)),
  setRecoverPassword: (password) => dispatch(setRecoverPassword(password)),
  setRecoverRePassword: (password) => dispatch(setRecoverRePassword(password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forget);