import React, { Component } from "react";
import bindAll from "lodash.bindall";
import styles from "./index.css";

import enterSvg from "static/fullscreen.svg";
import exitSvg from "static/fullscreen-exit.svg";

class Fullscreen extends Component {
  constructor(props) {
    super(props);
    bindAll(this, ["handleFullscreenClick", "onResize"]);

    this.state = {
      fullscreen: false,
    };
  }

  handleFullscreenClick() {
    const { fullscreen } = this.state;

    const element = document.documentElement;
    // 如果是全屏状态
    if (fullscreen) {
      // 如果浏览器有这个Function
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // 如果浏览器有这个Function
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }

    // 更新变量
    // 这里延迟100ms，是为了使 resize 先执行完逻辑，我们再同步状态
    // 因为如果 我们直接同步状态，resize 会使用当前的 fullscreen （已为true），导致渲染效果异常。
    setTimeout(() => {
      this.setState({
        fullscreen: !fullscreen,
      });
    }, 100);
  }

  onResize(e) {
    // 如果resize变化，且当前为全屏模式，则肯定是 ecs 触发的变化
    const { fullscreen } = this.state;
    if (fullscreen) {
      this.setState({
        fullscreen: false,
      });
    }
  }

  componentDidMount() {
    // 场景为全屏模式，无法监听 ECS 触发逻辑，故要通过 resize 间接处理
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    const { fullscreen } = this.state;

    return (
      <div className={styles.fullscreen} onClick={this.handleFullscreenClick}>
        {!fullscreen ? (
          <img className={styles.icon} src={enterSvg} />
        ) : (
          <img
            className={styles.icon}
            style={{ width: "30px", height: "30px" }}
            src={exitSvg}
          />
        )}
      </div>
    );
  }
}

export default Fullscreen;
