exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_kidsProgress__wrapper_FjkkH {\n  position: fixed;\n  top: 60px;\n  margin-left: -160px;\n  left: 50%;\n  border: 1px solid #eee;\n  z-index: 28000;\n  width: 320px;\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  border-radius: 40px;\n  background: -webkit-gradient(linear, left top, left bottom, from(#3ec4f5)) no-repeat, -webkit-gradient(linear, left top, left bottom, from(#f3f3f3));\n  background: -o-linear-gradient(#3ec4f5) no-repeat, -o-linear-gradient(#f3f3f3);\n  background: linear-gradient(#3ec4f5) no-repeat, linear-gradient(#f3f3f3);\n  background-size: 0% 100%;\n  -webkit-transition: all 0.3s;\n  -o-transition: all 0.3s;\n  transition: all 0.3s;\n  font-size: 16px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);\n          box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);\n}\n\n.style_kidsProgress__close_LnHxf {\n  width: 36px;\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  color: #666;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"kidsProgress__wrapper": "style_kidsProgress__wrapper_FjkkH",
	"kidsProgressWrapper": "style_kidsProgress__wrapper_FjkkH",
	"kidsProgress__close": "style_kidsProgress__close_LnHxf",
	"kidsProgressClose": "style_kidsProgress__close_LnHxf"
};