import { AGENT_HOST } from "./config";

// base64 转 blob
export const convertBase64ToBlob = (base64) => {
  // base64转成ArrayBuffer
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*);/)[1];
  const bytes = window.atob(arr[1]);
  const arrayBuffer = new ArrayBuffer(bytes.length);
  const uint8 = new Uint8Array(arrayBuffer);
  for (let i = 0; i < bytes.length; i++) {
    uint8[i] = bytes.charCodeAt(i);
  }
  // 再转成Blob
  return new Blob([arrayBuffer], { type: mime });
};

// blob 转 URL
export const blobToURL = (blob_data) => {
  return URL.createObjectURL(blob_data);
};

// File 转 arraybuffer
export const convertFileToArrayBuffer = (file, cb) => {
  const read = new FileReader();
  read.readAsArrayBuffer(file);
  read.onloadend = function () {
    cb(this.result);
  };
};

// File 转 base64
export const convertFileToBase64 = (file, cb) => {
  const read = new FileReader();
  read.readAsDataURL(file);
  read.onloadend = function () {
    cb(this.result);
  };
};

// 节流函数
export const throttle = (fn, delay) => {
  let _timer;
  return function (...args) {
    if (_timer) {
      return;
    } else {
      _timer = setTimeout(() => {
        fn.apply(this, args);
        clearTimeout(_timer);
        _timer = null;
      }, delay);
    }
  };
};

// 防抖函数
export const debounce = (fn, delay) => {
  let _timer;
  return function (...args) {
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

// 哈希串转对象
export const hashToObj = (hash) => {
  let json = {};
  if (hash.includes("#")) {
    hash
      .substring(1)
      .split("&")
      .forEach((item, index) => {
        const [field, val] = item.split("=");
        json[field] = window.decodeURIComponent(val); // 需要解码一下
      });
  }
  return json;
};

// 对象转哈希串
export const objToHash = (obj) => {
  let hash = "#";
  Object.keys(obj).forEach((field) => {
    hash += `${field}=${obj[field]}&`;
  });
  return hash.slice(0, -1);
};

// 获取哈希中指定的field值
export const getHashFieldVal = (hash, field) => {
  return hashToObj(hash)[field] || 0;
};

// 日期补零
const _addZero = (n) => ("00" + n).slice(-2);

// 日期生成 2020-01-02
export const formatTimeToDate = (num = Date.now()) => {
  let [y, m, d] = new Date(num).toLocaleDateString().split("/");
  m = _addZero(m);
  d = _addZero(d);
  return `${y}-${m}-${d}`;
};

// 日期生成 2020-01-02 13:52:21
export const formatTimeToDatetime = (num = Date.now()) => {
  const _Date = new Date();
  const time = formatTimeToDate(num);

  const h = _addZero(_Date.getHours());
  const m = _addZero(_Date.getMinutes());
  const s = _addZero(_Date.getSeconds());

  return `${time} ${h}:${m}:${s}`;
};

// 判断当前propNode 是否含于 fNode
export const inCurrentNode = (fNode, propNode) => {
  while (propNode !== null) {
    if (propNode === fNode) {
      return true;
    }
    propNode = propNode.parentNode;
  }
  return false;
};

/**
 * 扁平化数组：目前服务于键值化数组
 * @param {array} arr 数组
 * @param {string} subList 多级遍历字段名，[默认: 'childList']
 */
export const flattenArr = (arr, subList = "childList") => {
  return arr ? arr.concat(...arr.map((item) => flattenArr(item[subList]))) : [];
};

/**
 * 键值化数组：生成键值对
 * @param {array} arr 理论上传入“扁平化”后的数组
 * @param {string} id 主键字段名,[默认: 'id']
 */
export const keyArr = (arr, id = "id") => {
  return arr.reduce((currentState, item) => {
    currentState[item[id]] = item;
    return currentState;
  }, {});
};

const _toTree = (id, arr, subList) => {
  let filterArr = arr.filter((item) => item.parent_id === id);
  filterArr.forEach((item) => (item[subList] = _toTree(item.id, arr)));
  return filterArr;
};

/**
 * 树形化数组： 生成树形数组
 * @param {array} arr 传入“扁平化”后的数组，子数组名固定：'childList'
 * @param {string} subList 多级遍历字段名，[默认: 'childList']
 */
export const treeArr = (arr, subList = "childList") => {
  const fList = arr.filter((item) => !item.parent_id);
  return fList.map((item) => {
    item[subList] = _toTree(item.id, arr, subList);
    return item;
  });
};

/**
 * 选项卡数组绑定onClick: push跳转事件
 * @param {array} tabs 选项卡数组 (需要携带type标识作为hash名)
 * @param {string} hash 当前URL
 * @param {function} jump push | replace | window.open ...方法传入
 * @param {string} name 覆写的哈希名称
 * @param {string} pathname 跳转的前置路由 (如：'/user')
 */
export const tabArrBindClick = (tabs, hash, jump, name, pathname) => {
  // 将传入的hash键值化,并将
  const hashInfo = hashToObj(hash);
  // 遍历tabs绑定onClick字段
  return tabs.map((t) => ({
    ...t,
    onClick: () =>
      jump(
        `${pathname}${objToHash({
          ...hashInfo,
          [name]: t.name,
        })}`
      ),
  }));
};

// 获取20位长度的随机标识
export const randomId = () => {
  return (Math.random().toString(36) + Math.random().toString(36))
    .replace(/0\./g, "")
    .slice(0, 20);
};

// 随机数
export const appendTimestamp = (url) => {
  return `${url}?t=${Date.now()}`;
};

// 解析图片对应尺寸的路径（请提前在OSS写好样式文件）
export const sizeImage = (url, size = "") => {
  if (!size) {
    return url;
  }
  return `${url}?x-oss-process=style/image-size-${size}`;
};

// dom滚动条置顶兼容方法
export const scrollToTop = (dom) => {
  if (dom.scroll) {
    dom.scrollTo(0, 0);
  } else {
    dom.scrollTop = 0;
  }
};

// 判断是否为移动端
export function isMobile (){
  if(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
    return true
  }
}

/**
 * 解析 search 参数
 */
 export function parseSearch() {

  // 需兼容 hash模式
  const { href } = window.location
  const searchIndex = href.indexOf('?')
  let search = href.slice(searchIndex)

  if (!search.includes("?")) {
    return {};
  }
  search = search.slice(1);
  const map = {};
  const searchArr = search.split("&");
  searchArr.forEach((i, index) => {
    const [k, v] = i.split("=");
    map[k] = v;
  });
  return map;
}
