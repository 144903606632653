import React, { Component } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import InfoCanvas from '../InfoCanvas';
import { updateUserInfo } from '../../../../../lib/fetch-api';

class InfoBasic extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleUsernameChange',
      'handleEmailChange',
      'handleSaveBaseInfoClick',
      'handleGenderClick'
    ]);
  }

  handleUsernameChange(e) {
    this.props.changeUsername(e.target.value);
  }

  handleEmailChange(e) {
    this.props.changeEmail(e.target.value);
  }

  handleGenderClick(gender) {
    this.props.changeGender(gender);
  }

  handleSaveBaseInfoClick() {
    const {
      showError,
      showSuccess,
      getLoginInfo
    } = this.props;
    this.toUpdateUserBasicInfo().then(rs => {
      // 昵称为空
      if (rs === -1) {
        showError('昵称不能为空哦');
        return;
      }
      // 新增邮箱格式非法
      if (rs === -2) {
        showError('请输入正确邮箱的格式哦');
        return;
      }
      showSuccess('信息已保存');
      // onCloseBtnClick();
      /**
       *  有个小坑，因为我是通过发送请求而使redux的状态更新，
       *   但是avatar更新后，因为src图片名称并未发生变化
       *    所以导致浏览器读缓存内的旧图片，所以得通过单页面的方式改变头像
       *      以base64格式
       */
      getLoginInfo().then(() => {
        // 改变组件的state的avatar和redux的avatar
        this.refs.infoCanvas.onSetAvatar();
        this.refs.infoCanvas.clearCanvasPanelAllInfo();
      });
    })
  }

  toUpdateUserBasicInfo() {
    const { username, email, gender } = this.props;
    return new Promise(resolve => {
      if (!username) {
        resolve(-1);
        return;
      }
      // 如果填了邮箱，必须为符合正则
      if (email && !/.*@.*\..*/.test(email)) {
        resolve(-2);
        return;
      }
      updateUserInfo({
        username,
        email,
        gender
      }).then(res => {
        if (res.erron === -1) {
          resolve(-2);
          return;
        }
        resolve(1);
      });
    });
  }

  render() {
    const { avatar, gender, username, email } = this.props;
    return (
      <div className={styles.kidsPerson__userInfo}>
        <InfoCanvas ref='infoCanvas'
          avatar={avatar}
          setAvatar={this.props.setAvatar}
          showSuccess={this.props.showSuccess}
          showError={this.props.showError}
        />

        <div className={styles.kidsPerson__userInfo__info}>
          <div className={styles.kidsPerson__userInfoItem}>
            <div className={styles.kidsPerson__userInfoItem__text}>性别 :</div>
            <div className={styles.kidsPerson__userInfo__genderBox}>
              <div className={
                styles.kidsPerson__userInfo__gender + ' ' +
                (gender === '男' && styles['kidsPerson__userInfo__genderBoy--selected'])
              }
                onClick={this.handleGenderClick.bind(this, '男')}
              >
                <i className={
                  "iconfont icon-nan" + ' ' +
                  styles.info_gender_nan
                } />
              </div>
              <div className={
                styles.kidsPerson__userInfo__gender + ' ' +
                (gender === '女' && styles['kidsPerson__userInfo__genderGirl--selected'])
              }
                onClick={this.handleGenderClick.bind(this, '女')}
              >
                <i className={
                  "iconfont icon-nv" + ' ' +
                  styles.info_gender_nv
                } />
              </div>
            </div>
          </div>
          <div className={styles.kidsPerson__userInfoItem}>
            <div className={styles.kidsPerson__userInfoItem__text}>昵称 :</div>
            <input
              className={styles.kidsPerson__userInfoItem__input}
              type="text"
              placeholder='请填写您的昵称'
              value={username}
              onChange={this.handleUsernameChange}
            />
          </div>
          <div className={styles.kidsPerson__userInfoItem}>
            <div className={styles.kidsPerson__userInfoItem__text}>电子邮箱 :</div>
            <input
              className={styles.kidsPerson__userInfoItem__input}
              type="text"
              placeholder='输入电子邮箱'
              value={email || ''}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className={styles.kidsPerson__userInfoSave}
            onClick={this.handleSaveBaseInfoClick}
          >保存</div>
        </div>
      </div>
    );
  }
}

export default InfoBasic;