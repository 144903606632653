import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';

import { setProjectUnchanged } from '../reducers/project-changed';
import {
  LoadingStates,
  getIsCreatingNew,
  getIsFetchingWithId,
  getIsLoading,
  getIsShowingProject,
  onFetchedProjectData,
  projectError,
  setProjectId
} from '../reducers/project-state';
import {
  activateTab,
  BLOCKS_TAB_INDEX
} from '../reducers/editor-tab';

import log from './log';
import storage from './storage';
import { bucketScratchGuiPublicPath } from '../config/oss';

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */
const ProjectFetcherHOC = function (WrappedComponent) {
  class ProjectFetcherComponent extends React.Component {
    constructor(props) {
      super(props);
      bindAll(this, [
        'fetchProject'
      ]);
      storage.setProjectHost(props.projectHost);
      storage.setAssetHost(props.assetHost);
      storage.setTranslatorFunction(props.intl.formatMessage);
      // props.projectId might be unset, in which case we use our default;
      // or it may be set by an even higher HOC, and passed to us.
      // Either way, we now know what the initial projectId should be, so
      // set it in the redux store.
      if (
        props.projectId !== '' &&
        props.projectId !== null &&
        typeof props.projectId !== 'undefined'
      ) {
        this.props.setProjectId(props.projectId.toString());
      }
    }
    componentDidUpdate(prevProps) {
      if (prevProps.projectHost !== this.props.projectHost) {
        storage.setProjectHost(this.props.projectHost);
      }
      if (prevProps.assetHost !== this.props.assetHost) {
        storage.setAssetHost(this.props.assetHost);
      }
      if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
        this.fetchProject(this.props.reduxProjectId, this.props.loadingState);
      }
      if (this.props.isShowingProject && !prevProps.isShowingProject) {
        this.props.onProjectUnchanged();
      }
      if (this.props.isShowingProject && (prevProps.isLoadingProject || prevProps.isCreatingNew)) {
        this.props.onActivateTab(BLOCKS_TAB_INDEX);
      }
    }
    fetchProject(projectId, loadingState) {
      return storage
        .load(storage.AssetType.Project, projectId, storage.DataFormat.JSON)
        .then(projectAsset => {
          if (projectAsset) {
            this.props.onFetchedProjectData(projectAsset.data, loadingState);
          } else {
            // Treat failure to load as an error
            // Throw to be caught by catch later on
            throw new Error('Could not find project');
          }
        })
        .catch(err => {
          this.props.onError(err);
          log.error(err);
        });
    }
    render() {
      const {
        /* eslint-disable no-unused-vars */
        assetHost,
        intl,
        isLoadingProject: isLoadingProjectProp,
        loadingState,
        onActivateTab,
        onError: onErrorProp,
        onFetchedProjectData: onFetchedProjectDataProp,
        onProjectUnchanged,
        projectHost,
        projectId,
        reduxProjectId,
        setProjectId: setProjectIdProp,
        /* eslint-enable no-unused-vars */
        isFetchingWithId: isFetchingWithIdProp,
        ...componentProps
      } = this.props;
      return (
        <WrappedComponent
          fetchingProject={isFetchingWithIdProp}
          {...componentProps}
        />
      );
    }
  }
  ProjectFetcherComponent.propTypes = {
    assetHost: PropTypes.string,
    canSave: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreatingNew: PropTypes.bool,
    isFetchingWithId: PropTypes.bool,
    isLoadingProject: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingState: PropTypes.oneOf(LoadingStates),
    onActivateTab: PropTypes.func,
    onError: PropTypes.func,
    onFetchedProjectData: PropTypes.func,
    onProjectUnchanged: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setProjectId: PropTypes.func
  };
  console.log(process.env.NODE_ENV)
  ProjectFetcherComponent.defaultProps = {
    // assetHost: 'https://assets.scratch.mit.edu',
    // assetHost: process.env.NODE_ENV === 'dev' ? 'http://localhost:8601' : 'https://scratch3.k12-code.com/',
    assetHost: process.env.NODE_ENV === 'dev' ? 'http://localhost:8601' : bucketScratchGuiPublicPath,
    
    // projectHost: 'https://projects.scratch.mit.edu'
    projectHost: process.env.NODE_ENV === 'dev' ? 'not use projectId to load' : 'not use projectId to load'
  };

  const mapStateToProps = state => ({
    isCreatingNew: getIsCreatingNew(state.scratchGui.projectState.loadingState),
    isFetchingWithId: getIsFetchingWithId(state.scratchGui.projectState.loadingState),
    isLoadingProject: getIsLoading(state.scratchGui.projectState.loadingState),
    isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
    loadingState: state.scratchGui.projectState.loadingState,
    reduxProjectId: state.scratchGui.projectState.projectId
  });
  const mapDispatchToProps = dispatch => ({
    onActivateTab: tab => dispatch(activateTab(tab)),
    onError: error => dispatch(projectError(error)),
    onFetchedProjectData: (projectData, loadingState) =>
      dispatch(onFetchedProjectData(projectData, loadingState)),
    setProjectId: projectId => dispatch(setProjectId(projectId)),
    onProjectUnchanged: () => dispatch(setProjectUnchanged())
  });
  // Allow incoming props to override redux-provided props. Used to mock in tests.
  const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, stateProps, dispatchProps, ownProps
  );
  return injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ProjectFetcherComponent));
};

export {
  ProjectFetcherHOC as default
};
