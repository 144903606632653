exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_drawBoard_1YNYm {\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  margin: auto;\n  margin-top: 180px;\n  width: 300px;\n  height: 320px;\n  background: #fff;\n  font-size: 16px;\n  font-weight: 300;\n  border-radius: 5px;\n  color: #333;\n  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  overflow: hidden;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  padding: 10px;\n}\n\n.style_drawBoard__header_2Fw_O {\n  position: relative;\n}\n\n.style_drawBoard__header__close_1fha0 {\n  width: 38px;\n  position: absolute;\n  right: 0px;\n  top: -6px;\n  color: #666;\n  cursor: pointer;\n}\n\n.style_drawBoard__work_1tvVc dl dd {\n  width: 70%;\n  white-space: nowrap;\n  margin-top: 8px;\n}\n\n.style_drawBoard__work_1tvVc dl dd > span {\n  padding-right: 12px;\n}\n\n.style_drawBoard__btn_bEhOH {\n  background: #999;\n  color: #fff;\n  border: none;\n  padding: 6px 12px;\n  border-radius: 2px;\n  font-size: 14px;\n  outline: none;\n}\n\n.style_drawBoard__btn_bEhOH:hover {\n  cursor: pointer;\n  opacity: 0.75;\n}\n", ""]);

// exports
exports.locals = {
	"drawBoard": "style_drawBoard_1YNYm",
	"drawBoard__header": "style_drawBoard__header_2Fw_O",
	"drawBoardHeader": "style_drawBoard__header_2Fw_O",
	"drawBoard__header__close": "style_drawBoard__header__close_1fha0",
	"drawBoardHeaderClose": "style_drawBoard__header__close_1fha0",
	"drawBoard__work": "style_drawBoard__work_1tvVc",
	"drawBoardWork": "style_drawBoard__work_1tvVc",
	"drawBoard__btn": "style_drawBoard__btn_bEhOH",
	"drawBoardBtn": "style_drawBoard__btn_bEhOH"
};