import React, { Component } from 'react';
import styles from './style.css';
import errorIcon from 'static/errorIcon.png';
import smileIcon from 'static/smileIcon.png';

class Toast extends Component {
  constructor(props) {
    super(props);
    this._errorTimer = null;
    this._successTimer = null;
    this._errorCloseTimer = null;
    this._successCloseTimer = null;
    this.errorTimestamp = 2000; // 提醒时长
    this.successTimestamp = 2000;
  }

  render() {
    return (
      <div>
        {/* 错误提示框 */}
        <div className={styles.kidsLogin__error} ref='error'>
          <img className={styles.kidsLogin__error__icon}
            src={errorIcon} />
          <div className={styles.kidsLogin__error__text}></div>
        </div>
        {/* 成功提示框 */}
        <div className={styles.kidsLogin__success} ref='success'>
          <img className={styles.kidsLogin__success__icon}
            src={smileIcon} />
          <div className={styles.kidsLogin__success__text}></div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.receiveModeChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if(
      prevProps.mode !== this.props.mode ||
      prevProps.text !== this.props.text
      ) {
        this.receiveModeChange();
      }
  }

  receiveModeChange() {
    clearTimeout(this._errorTimer);
    clearTimeout(this._successTimer);
    clearTimeout(this._errorCloseTimer);
    clearTimeout(this._successCloseTimer);
    const { mode, text } = this.props;
    setTimeout(() => {
      if (mode === 'success') {
        this.showSuccess(text)
      } else {
        this.showError(text)
      }
    }, 20);
  }

  showError(text) {
    // 隐藏showSuccess避免重合
    this.refs.success.className = styles.kidsLogin__success;
    // 呼出错误框
    const errorDom = this.refs.error;
    const errorTextDom = errorDom.lastChild;
    errorDom.className = styles.kidsLogin__error + ' ' + styles['kidsLogin__error--enter'];
    errorTextDom.innerText = text;
    this._errorTimer = setTimeout(() => {
      // 渐隐效果 300ms
      errorDom.className = styles.kidsLogin__error + ' ' + styles['kidsLogin__error--leave'];
      // 300ms后销毁组件
      this._errorCloseTimer = setTimeout(() => {
        this.props.setToastClose();
      }, 300);
    }, this.errorTimestamp);
  }

  showSuccess(text) {
    // 隐藏showError避免重合
    this.refs.error.className = styles.kidsLogin__error;
    // 呼出成功框
    const successDom = this.refs.success;
    const successTextDom = successDom.lastChild;
    successDom.className = styles.kidsLogin__success + ' ' + styles['kidsLogin__success--enter'];
    successTextDom.innerText = text;
    this._successTimer = setTimeout(() => {
      // 渐隐效果 300ms
      successDom.className = styles.kidsLogin__success + ' ' + styles['kidsLogin__success--leave'];
      // 300ms后销毁组件
      this._successCloseTimer = setTimeout(() => {
        this.props.setToastClose();
      }, 300);
    }, this.successTimestamp);
  }

}

export default Toast;