import React, { Component, Fragment } from 'react';
import styles from './style.css';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import TabBar from '../TabBar';
const { actions: {
  setUserPanelMode, setSpriteList,
  setSpriteName, setSpriteComment,
  setCurrentSpriteLabel, setShowLabelPanel,
  setSelectedSpriteLabel, setSpriteShareLabelName,
  setSpriteShareList, setSpriteShareUsername
} } = require('../../../../../reducers/kids-user-panel');
const { actions: {
  setToastShow, setConfirmShow, setConfirmClose,
  setPopupShow, setPopupClose, setSelectShow,
  setSelectClose, setLoading
} } = require('../../../../../reducers/kids-app');
import {
  getUserSprite, deleteUserSprite, findSpriteByShareCode,
  removeUserSpriteLabel
} from '../../../../../lib/fetch-api';
import { scrollToTop } from '../../../../../lib/utils/kids-tools';
import closeIcon from 'static/closeIcon.png';
import person_add from 'static/person_add.png';
import person_delete from 'static/person_delete.png';

const PAGE_SIZE = 18; // 页容

class Create extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'handleTabBarClick',
      'handleCloseClick',
      'handleAddClick',
      'handleDeleteClick',
      'handleManageLabelClick',
      'handleLoadMoreClick',
      'handleTransferClick',
      'handleShareLabelClick',
      'handleShareDeleteClick'
    ]);
    this.state = {
      workList: [],
      page: 1
    }
  }

  componentDidMount() {
    this.refreshSpriteList();
  }

  refreshSpriteList() {
    // 待数据重渲完毕后
    setTimeout(() => {
      scrollToTop(this.refs.contentRef);
      this.setState({ page: 1 });
      this.updateWorkList();
    }, 20);
  }

  updateWorkList() {
    // 根据标签以及页数页码进行渲染
    const { currentSpriteLabelId, spriteList, spriteShareList } = this.props;
    if (currentSpriteLabelId === -255) {
      // 渲染共享素材
      this.setState({
        workList: spriteShareList
      });
    } else {
      // 渲染个人素材
      const list = spriteList.filter(item => {
        if (currentSpriteLabelId === 0) {
          return true;
        }
        if (item.tbl_label_id === currentSpriteLabelId) {
          return true;
        }
        return false;
      });
      this.setState({
        workList: list
      });
    }
  }

  handleShareDeleteClick(e) {
    e.stopPropagation();
    const { setSpriteShareLabelName, setSpriteShareList, setCurrentSpriteLabel, showSuccess } = this.props;
    setSpriteShareLabelName('');
    setSpriteShareList([]);
    setCurrentSpriteLabel(0);
    this.refreshSpriteList();
    showSuccess('已移除共享');
  }

  handleShareLabelClick() {
    const { spriteShareLabelName, setCurrentSpriteLabel, setPopupShow, popupInput } = this.props;
    // 若共享名称存在，则说明是切换至共享作品
    if (spriteShareLabelName) {
      this.refreshSpriteList();
      setCurrentSpriteLabel(-255);
      return;
    }
    setPopupShow({
      title: '素材共享入口',
      tip: '若共享码不存在，则打开失败',
      input: popupInput,
      placeholder: '请输入老师提供的共享码',
      confirmText: '打开',
      confirmEvent: this.openShareCodeSpriteList.bind(this),
    })
  }

  openShareCodeSpriteList() {
    // 获取用户输入的共享码
    const {
      popupInput, showError, showSuccess,
      setSpriteShareList, setCurrentSpriteLabel,
      setPopupClose, setSpriteShareLabelName,
      setSpriteShareUsername
    } = this.props;
    if (!popupInput) {
      showError('共享码不能为空');
      return;
    }
    // 检索作品
    findSpriteByShareCode({
      share_code: popupInput
    }).then(res => {
      if (res.errno === -1) {
        showError(res.msg)
        return;
      }
      const { username, label, list } = res.data;
      this.refreshSpriteList();
      setSpriteShareUsername(username);
      showSuccess(res.msg)
      setSpriteShareList(list.reverse()); // 丢数据
      setCurrentSpriteLabel(-255); // 重渲标签Id-255
      setSpriteShareLabelName(label); // 重渲标签名 
      setPopupClose();
    })
  }

  handleLoadMoreClick() {
    this.setState({
      page: this.state.page + 1
    });
  }

  handleManageLabelClick() {
    this.props.setShowLabelPanel(2);
  }

  handleAddClick(filePath, name, comment, id) {
    const {
      setSpriteName, setSpriteComment, setSelectedSpriteLabel,
      addSprite, closePanel, showSuccess, setLoading
    } = this.props;
    // 将name comment存入redux
    setSpriteName(name);
    setSpriteComment(comment);
    setSelectedSpriteLabel(id);
    // 先请求sb3文件，再丢给addSprite
    fetch(filePath, {
      method: 'GET'
    }).then(response => response.arrayBuffer())
      .then(file => {
        // 开启loading
        setLoading(true);
        addSprite(file).then(() => {
          setTimeout(() => {
            setLoading(false);
            showSuccess('素材添加成功');
          }, 500)
          // closePanel(0);
        })
      })
  }

  handleDeleteClick(item) {
    this.props.confirmShow({
      content: '确认后,素材将永久删除',
      confirmEvent: this.execDeleteUserSprite.bind(this, item)
    });
  }

  handleTransferClick(item) {
    const { setSelectShow, spriteLabelList } = this.props;
    // labelId定位当前spriteLabelList的Index
    let index;
    spriteLabelList.forEach((label, idx) => {
      if (label.id === item.tbl_label_id) {
        index = idx;
        return;
      }
    });
    setSelectShow({
      title: '移动素材',
      index,
      list: spriteLabelList,
      confirmText: '移动',
      confirmEvent: this.execRemoveUserSprite.bind(this, item.id)
    })
  }

  execRemoveUserSprite(id) {
    const { spriteList, selectedIndex, spriteLabelList, showError, showSuccess, setSelectClose } = this.props;
    // 根据selectedIndex定位labelId
    const labelId = spriteLabelList[selectedIndex].id;
    // console.log(selectedIndex, id, labelId); 
    // id 是作品id labelId是移动到的标签id
    removeUserSpriteLabel({
      id,
      labelId
    }).then(res => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      showSuccess(res.msg);
      // 更新redux
      this.props.setSpriteList(spriteList.map(item => {
        if (item.id === id) {
          return { ...item, tbl_label_id: labelId };
        }
        return item;
      }));
      // 更新state
      this.updateWorkList();
      setSelectClose();
    });
  }

  execDeleteUserSprite(item) {
    const { showError, showSuccess, spriteList, setSpriteList } = this.props;
    deleteUserSprite({
      id: item.id,
      file_path: item.file_path.split('/').pop(),
      cover_path: item.cover_path.split('/').pop()
    }).then(res => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      // 更新数据
      setSpriteList(spriteList.filter(sprite => sprite.id !== item.id));
      // 更新state
      this.updateWorkList();
      showSuccess(res.msg);
    })
    // 关闭弹窗
    this.props.confirmClose();
  }

  handleCloseClick() {
    this.props.closePanel(0);
  }

  handleTabBarClick(item) {
    const labelId = item.id;
    this.props.setCurrentSpriteLabel(labelId);
    this.refreshSpriteList();
  }

  render() {
    const {
      currentSpriteLabelId,
      spriteLabelList,
      spriteShareLabelName,
      spriteShareUsername
    } = this.props;
    const { page, workList } = this.state;
    const currentList = workList.slice(0, page * PAGE_SIZE);

    return (
      <div>
        <div className={styles.kidsPerson__create}>
          <div className={styles.kidsPerson__createWrapper}>
            <div className={styles.kidsPerson__header}>
              <div className={styles.kidsPerson__title}>
                {currentSpriteLabelId === -255 ? `来自: ${spriteShareUsername}` : '我的素材'}
              </div>
              <img className={styles.kidsPerson__close}
                src={closeIcon}
                onClick={this.handleCloseClick}
              />
            </div>
            {/* 标签选项 */}
            <TabBar
              currentLabelId={currentSpriteLabelId}
              labelList={spriteLabelList}
              onTabBarClick={this.handleTabBarClick}
            />
            <div className={styles.kidsOther__saveWrapper__managerSelect}
              onClick={this.handleManageLabelClick}
            >
              管理标签
            </div>
            <div className={
              styles.kidsOther__saveWrapper__shareSelect + ' ' +
              (spriteShareLabelName && styles['kidsOther__saveWrapper__shareSelect--active']) + ' ' +
              (currentSpriteLabelId === -255 && styles['kidsOther__saveWrapper__shareSelect--active--selected'])
            }
              onClick={this.handleShareLabelClick}
            >
              <div className={
                'iconfont icon-wenjianjia2 ' +
                styles.kidsOther__saveWrapper__shareSelectIcon
              }></div>
              <div className={styles.kidsOther__saveWrapper__shareSelectText}>
                {spriteShareLabelName ? spriteShareLabelName : '共享入口'}
              </div>
              {spriteShareLabelName && (
                <div className={'iconfont icon-delete ' +
                  styles.kidsOther__saveWrapper__shareSelectIconDelete}
                  onClick={this.handleShareDeleteClick}
                ></div>
              )}
            </div>
            <div className={styles.kidsPerson__contentBox} ref="contentRef">
              <ul className={styles.kidsPerson__content}>
                {
                  currentList.map((item, index) => {
                    const { id, name, comment, cover_path, file_path, upload_time, tbl_label_id } = item;
                    return (
                      <li key={id || index}
                        className={styles.kidsPerson__content__project}>
                        <div className={styles.kidsPerson__content__image}>
                          <img className={styles.kidsPerson__content__image__img}
                            src={cover_path} />
                          <div className={styles.kidsPerson__content__tools}>
                            <div
                              className={styles.kidsPerson__content__tools__icon}
                              onClick={this.handleAddClick.bind(this, file_path, name, comment, tbl_label_id)}
                            >
                              <img src={person_add} alt="" />
                            </div>
                            {!item.share_code && (
                              <Fragment>
                                <div
                                  className={styles.kidsPerson__content__tools__icon}
                                  onClick={this.handleDeleteClick.bind(this, item)}>
                                  <img src={person_delete} alt="" />
                                </div>
                                <div
                                  className={
                                    styles.kidsPerson__content__tools__icon + ' ' +
                                    styles.kidsPerson__content__tools__iconTransfer
                                  }
                                  onClick={this.handleTransferClick.bind(this, item)}>
                                  <div className={
                                    'iconfont icon-plus-transfer ' +
                                    styles.kidsPerson__content__tools__iconTransferIcon
                                  }></div>
                                </div>
                              </Fragment>
                            )}

                          </div>
                        </div>
                        <div className={styles.kidsPerson__content__title}>
                          {name}
                        </div>
                        <div className={styles.kidsPerson__content__date}>
                          {upload_time.replace(/:\d{2}$/, '')}
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
              {/* 是否溢出 */}
              {
                currentList.length < workList.length && (
                  < div
                    className={styles.kidsPerson__content__loadMore}
                    onClick={this.handleLoadMoreClick}
                  >加载更多素材</div>
                )
              }
              {/* 有无作品 */}

              {
                currentSpriteLabelId === -255 && currentList.length === 0
                  ? <div className={styles.kidsPerson__content__noSprite}>Ta还没有分享素材</div>
                  : currentSpriteLabelId !== -255 && currentList.length === 0 &&
                  <div className={styles.kidsPerson__content__noSprite}>快去保存一个素材吧</div>
              }


            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  spriteLabelList: state.kids.userPanel.spriteLabelList,
  currentSpriteLabelId: state.kids.userPanel.currentSpriteLabelId,
  popupInput: state.kids.app.popup.input,
  spriteShareLabelName: state.kids.userPanel.spriteShareLabelName,
  spriteShareList: state.kids.userPanel.spriteShareList,
  spriteShareUsername: state.kids.userPanel.spriteShareUsername,
  selectedIndex: state.kids.app.select.index,

  spriteList: state.kids.userPanel.spriteList,

  addSprite: state.scratchGui.vm.addSprite.bind(state.scratchGui.vm),

});

const mapDispatchToProps = (dispatch) => ({
  setSpriteList: (list) => dispatch(setSpriteList(list)),
  setSpriteName: (name) => dispatch(setSpriteName(name)),
  setSpriteComment: (comment) => dispatch(setSpriteComment(comment)),
  setCurrentSpriteLabel: (id) => dispatch(setCurrentSpriteLabel(id)),
  setShowLabelPanel: (mode) => dispatch(setShowLabelPanel(mode)),
  setSelectedSpriteLabel: (id) => dispatch(setSelectedSpriteLabel(id)),
  setSpriteShareLabelName: (name) => dispatch(setSpriteShareLabelName(name)),
  setSpriteShareList: (list) => dispatch(setSpriteShareList(list)),
  setSpriteShareUsername: (name) => dispatch(setSpriteShareUsername(name)),
  setSelectShow: (info) => dispatch(setSelectShow(info)),
  setSelectClose: () => dispatch(setSelectClose()),

  setPopupShow: (info) => dispatch(setPopupShow(info)),
  setPopupClose: () => dispatch(setPopupClose()),
  setLoading: (status) => dispatch(setLoading(status)),
  closePanel: () => dispatch(setUserPanelMode(0)),
  showSuccess: (text) => dispatch(setToastShow('success', text)),
  showError: (text) => dispatch(setToastShow('error', text)),

  confirmShow: (obj) => dispatch(setConfirmShow(obj)),
  confirmClose: () => dispatch(setConfirmClose())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);