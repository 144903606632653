import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import DOMElementRenderer from '../../containers/dom-element-renderer.jsx';
import Loupe from '../loupe/loupe.jsx';
import MonitorList from '../../containers/monitor-list.jsx';
import TargetHighlight from '../../containers/target-highlight.jsx';
import GreenFlagOverlay from '../../containers/green-flag-overlay.jsx';
import Question from '../../containers/question.jsx';
import MicIndicator from '../mic-indicator/mic-indicator.jsx';
import { STAGE_DISPLAY_SIZES } from '../../lib/layout-constants.js';
import { getStageDimensions } from '../../lib/screen-utils.js';
import styles from './stage.css';

const StageComponent = props => {
  const {
    canvas,
    dragRef,
    isPlayerOnly,
    isColorPicking,
    isFullScreen,
    isStarted,
    colorInfo,
    micIndicator,
    question,
    stageSize,
    useEditorDragStyle,
    onDeactivateColorPicker,
    onDoubleClick,
    onQuestionAnswered,
    playerSize,
    ...boxProps
  } = props;
  const stageDimensions = getStageDimensions(stageSize, isFullScreen, playerSize);

  return (
    <div>
      <Box
        className={classNames({
          [styles.stageWrapper]: !isFullScreen,
          [styles.stageWrapperOverlay]: isFullScreen,
          [styles.withColorPicker]: !isFullScreen && isColorPicking
        })}
        style={{
          minHeight: stageDimensions.height,
          minWidth: stageDimensions.width
        }}
        onDoubleClick={onDoubleClick}
      >
        <Box
          className={classNames(
            styles.stage,
            {
              [styles.stageOverlayContent]: isFullScreen,
              [styles.stageMedium]: isPlayerOnly
            }
          )}
          style={{
            height: stageDimensions.height,
            width: stageDimensions.width
          }}
        >
          <DOMElementRenderer
            domElement={canvas}
            style={{
              height: stageDimensions.height,
              width: stageDimensions.width
            }}
            {...boxProps}
          />
        </Box>
        <Box className={styles.monitorWrapper}>
          <MonitorList
            draggable={useEditorDragStyle}
            stageSize={stageDimensions}
          />
        </Box>
        <Box className={styles.frameWrapper}>
          <TargetHighlight
            className={styles.frame}
            stageHeight={stageDimensions.height}
            stageWidth={stageDimensions.width}
          />
        </Box>
        {isStarted ? null : (
          <GreenFlagOverlay
            className={styles.greenFlagOverlay}
            wrapperClass={styles.greenFlagOverlayWrapper}
          />
        )}
        {isColorPicking && colorInfo ? (
          <Box className={styles.colorPickerWrapper}>
            <Loupe colorInfo={colorInfo} />
          </Box>
        ) : null}
        <div
          className={styles.stageBottomWrapper}
          style={{
            width: stageDimensions.width,
            height: stageDimensions.height,
            left: '50%',
            marginLeft: stageDimensions.width * -0.5
          }}
        >
          {micIndicator ? (
            <MicIndicator
              className={styles.micIndicator}
              stageSize={stageDimensions}
            />
          ) : null}
          {question === null ? null : (
            <div
              className={styles.questionWrapper}
              style={{ width: stageDimensions.width }}
            >
              <Question
                question={question}
                onQuestionAnswered={onQuestionAnswered}
              />
            </div>
          )}
        </div>
        <canvas
          className={styles.draggingSprite}
          height={0}
          ref={dragRef}
          width={0}
        />
      </Box>
      {isColorPicking ? (
        <Box
          className={styles.colorPickerBackground}
          onClick={onDeactivateColorPicker}
        />
      ) : null}
    </div>
  );
};
StageComponent.propTypes = {
  canvas: PropTypes.instanceOf(Element).isRequired,
  colorInfo: Loupe.propTypes.colorInfo,
  dragRef: PropTypes.func,
  isColorPicking: PropTypes.bool,
  isPlayerOnly: PropTypes.bool,
  isFullScreen: PropTypes.bool.isRequired,
  isStarted: PropTypes.bool,
  micIndicator: PropTypes.bool,
  onDeactivateColorPicker: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onQuestionAnswered: PropTypes.func,
  playerSize: PropTypes.array,
  question: PropTypes.string,
  stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
  useEditorDragStyle: PropTypes.bool
};
StageComponent.defaultProps = {
  dragRef: () => { }
};
export default StageComponent;
