import React, { Component, Fragment } from "react";
import cn from "classnames";
import styles from "./style.css";
import bindAll from "lodash.bindall";
import { connect } from "react-redux";
import TabBar from "../TabBar";
const {
  actions: {
    setUserPanelMode,
    setProjectList,
    setProjectName,
    setProjectComment,
    setCurrentWorkLabel,
    setCurrentWorkLabelInfo,
    setShowLabelPanel,
    setSelectedWorkLabel,
    setWorkShareCode,
    setWorkShareLabelName,
    setWorkShareList,
    setWorkShareUserId,
    setWorkShareUsername,
    onUpdateUserWork,
    setAutoSaveWork,
  },
} = require("../../../../../reducers/kids-user-panel");
const {
  actions: {
    setToastShow,
    setConfirmShow,
    setConfirmClose,
    setPopupShow,
    setPopupClose,
    setSelectShow,
    setSelectClose,
    setLoading,
  },
} = require("../../../../../reducers/kids-app");
import {
  getUserWork,
  deleteUserWork,
  unbindUserWorkShareLabel,
  findWorkByShareCode,
  removeUserWorkLabel,
  updateAutoSaveWork,
} from "../../../../../lib/fetch-api";
import { fetchWork } from "../../../../../lib/utils/mixin-api";
import { scrollToTop } from "../../../../../lib/utils/kids-tools";
import closeIcon from "static/closeIcon.png";
import person_edit from "static/person_edit.png";
import person_delete from "static/person_delete.png";
import person_unbind from "static/person_unbind.png";
import icon_refresh from "static/icon-refresh.png";

const PAGE_SIZE = 9; // 页容

class Create extends Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      "handleTabBarClick",
      "handleCloseClick",
      "handleEditClick",
      "handleDeleteClick",
      "handleUnbindClick",
      "handleManageLabelClick",
      "handleLoadMoreClick",
      "handleTransferClick",
      "handleShareLabelClick",
      "handleShareDeleteClick",
      "handleRefreshIconClick",
      "handleAutoSaveWorkChange",
    ]);
    this.state = {
      workList: [], // 最新的用户作品
      page: 1, // 当前页
    };
  }

  componentDidMount() {
    this.getAndRefresh();
  }

  getAndRefresh() {
    // 获取最新作品
    return this.toGetWorkAndShareList().then(() => {
      // redux的标签 进行抽取projectList 推入 workList
      this.refreshWorkList();
    });
  }

  // 自动保存变更
  handleAutoSaveWorkChange(e) {
    e.persist();
    const { checked } = e.target;

    const { setAutoSaveWork, showSuccess } = this.props;

    // api 同步
    updateAutoSaveWork({ checked }).then((res) => {
      if (res.errno === -1) {
        reject(res.msg);
        return;
      }

      checked && showSuccess("已开启自动保存提醒");
      setAutoSaveWork(checked);
    });
  }

  // 刷新图标点击
  handleRefreshIconClick() {
    this.getAndRefresh();
  }

  toGetWorkAndShareList() {
    return new Promise((resolve) => {
      // actions 重新请求资源
      const {
        workShareCode,
        onUpdateUserWork,
        setLoading,
        showSuccess,
        showError,
        currentWorkLabelId,
      } = this.props;

      setLoading(true);

      // 更新作品(即重新获取作品)
      onUpdateUserWork()
        .then((res) => {
          // 如果连接了共享文件，更新共享标签列表
          if (workShareCode) {
            this.toGetShareWorkList(workShareCode)
              .catch((err) => {
                this.handleShareDeleteClick();
                showError(`${err}, 已移除共享`);
                return Promise.reject();
              })
              .then(() => {
                showSuccess("作品已刷新");
              });
          } else {
            showSuccess("作品已刷新");
          }
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => {
          setLoading(false);
          resolve();
        });
    });
  }

  refreshWorkList() {
    // 重新更新数据
    setTimeout(() => {
      scrollToTop(this.refs.contentRef);
      this.setState({ page: 1 }); // 假分页重置
      this.updateWorkList();
    }, 30);
  }

  updateWorkList() {
    // 根据标签以及页数页码进行渲染
    const { userId, currentWorkLabelId, workShareList, projectList } =
      this.props;

    if (currentWorkLabelId === -255) {
      // 渲染共享作品
      this.setState({
        workList: workShareList,
      });
    } else {
      // 根据标签选出作品
      const list = projectList.filter((item) => {
        // 如果是全部标签
        if (currentWorkLabelId === 0) {
          // 必须是自己的作品才显示
          if (item.tbl_user_id === userId) {
            return true;
          }
        } else if (
          item.tbl_label_id === currentWorkLabelId ||
          item.tbl_share_label_id === currentWorkLabelId
        ) {
          // 标签项
          // 自己作品或他人作品都显示
          return true;
        }
        return false;
      });

      // 渲染共享作品
      this.setState({
        workList: list,
      });
    }
  }

  handleShareDeleteClick(e) {
    e && e.stopPropagation();
    const {
      setWorkShareCode,
      setWorkShareUserId,
      setWorkShareLabelName,
      setWorkShareList,
      setCurrentWorkLabel,
      showSuccess,
    } = this.props;
    setWorkShareLabelName("");
    setWorkShareList([]);
    setCurrentWorkLabel(0);
    this.refreshWorkList();

    setWorkShareCode("");
    setWorkShareUserId("");
    showSuccess("已移除共享");
  }

  handleShareLabelClick() {
    const {
      workShareLabelName,
      setCurrentWorkLabel,
      setPopupShow,
      popupInput,
    } = this.props;
    // 若共享标签的名称存在，则说明是切换至共享作品
    if (workShareLabelName) {
      this.refreshWorkList();
      setCurrentWorkLabel(-255);
      return;
    }
    setPopupShow({
      title: "作品共享入口",
      tip: "若共享码不存在，则打开失败",
      input: popupInput,
      placeholder: "请输入老师提供的共享码",
      confirmText: "打开",
      confirmEvent: this.openShareCodeProjectList.bind(this),
    });
  }

  openShareCodeProjectList() {
    // 获取用户输入的共享码
    const {
      popupInput,
      showError,
      showSuccess,
      setWorkShareCode,
      setCurrentWorkLabel,
      setPopupClose,
    } = this.props;

    if (!popupInput) {
      showError("共享码不能为空");
      return;
    }

    setWorkShareCode(popupInput);

    this.toGetShareWorkList(popupInput)
      .then(() => {
        showSuccess("连接成功");
        setCurrentWorkLabel(-255); // 重渲标签Id-255
        this.refreshWorkList(); // 重渲作品
        setPopupClose();
      })
      .catch((err) => {
        showError(err);
      });
  }

  toGetShareWorkList(share_code) {
    const {
      setWorkShareList,
      setWorkShareUserId,
      setWorkShareLabelName,
      setWorkShareUsername,
    } = this.props;
    return new Promise((resolve, reject) => {
      // 检索作品
      findWorkByShareCode({
        share_code,
      }).then((res) => {
        if (res.errno === -1) {
          reject(res.msg);
          return;
        }
        const { userId, username, list, label } = res.data;
        setWorkShareList(list.reverse()); // 设置数据
        setWorkShareUserId(userId); // 设置用户昵称
        setWorkShareUsername(username); // 设置用户昵称
        setWorkShareLabelName(label); // 重渲标签名
        resolve();
      });
    });
  }

  handleLoadMoreClick() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  handleManageLabelClick() {
    this.props.setShowLabelPanel(1);
  }

  handleEditClick(filePath, name, comment, id) {
    const {
      setProjectName,
      setProjectComment,
      setSelectedWorkLabel,
      closePanel,
      showSuccess,
      showError,
      currentWorkLabelId,
    } = this.props;
    // 将name comment存入redux
    setProjectName(name);
    setProjectComment(comment);

    // ★ 如果currentWorkLabelId === -255
    // worklabel 必须为 0
    if (currentWorkLabelId === -255) {
      setSelectedWorkLabel(0);
    } else {
      setSelectedWorkLabel(id);
    }

    // 先请求sb3文件，再丢给loadProject
    fetchWork(this.props)(filePath)
      .then(() => {
        showSuccess("作品打开成功");
        closePanel(0);
      })
      .catch((err) => {
        showError("作品异常，打开失败");
      });
  }

  handleDeleteClick(item) {
    this.props.confirmShow({
      content: "确认后,作品将永久删除",
      confirmEvent: this.execDeleteUserWork.bind(this, item),
    });
  }

  handleUnbindClick(item) {
    this.props.confirmShow({
      content: "将该作品解除共享?",
      confirmEvent: this.execUnbindUserWork.bind(this, item),
    });
  }

  handleTransferClick(item) {
    const { setSelectShow, workLabelList } = this.props;
    // labelId定位当前workLabelList的Index
    let index;
    workLabelList.forEach((label, idx) => {
      if (label.id === item.tbl_label_id) {
        index = idx;
        return;
      }
    });
    setSelectShow({
      title: "移动作品",
      index,
      list: workLabelList,
      confirmText: "移动",
      confirmEvent: this.execRemoveUserWork.bind(this, item.id),
    });
  }

  execRemoveUserWork(id) {
    const {
      projectList,
      selectedIndex,
      workLabelList,
      showError,
      showSuccess,
      setSelectClose,
    } = this.props;
    // 根据selectedIndex定位labelId
    const labelId = workLabelList[selectedIndex].id;
    // console.log(selectedIndex, id, labelId);
    // id 是作品id labelId是移动到的标签id
    removeUserWorkLabel({
      id,
      labelId,
    }).then((res) => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      showSuccess(res.msg);
      // 更新redux
      this.props.setProjectList(
        projectList.map((item) => {
          if (item.id === id) {
            return { ...item, tbl_label_id: labelId };
          }
          return item;
        })
      );
      // 更新state
      this.updateWorkList();
      setSelectClose();
    });
  }

  execDeleteUserWork(item) {
    const { showError, showSuccess } = this.props;
    deleteUserWork({
      id: item.id,
      cover_path: item.cover_path.split("/").pop(),
      file_path: item.file_path.split("/").pop(),
    }).then((res) => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      // 更新数据
      // setProjectList(projectList.filter((i) => i.id !== item.id));
      // setWorkShareList(workShareList.filter((i) => i.id !== item.id));

      this.getAndRefresh().then(() => {
        showSuccess(res.msg);
      });
    });
    // 关闭弹窗
    this.props.confirmClose();
  }

  execUnbindUserWork(item) {
    const { showError, showSuccess } = this.props;
    unbindUserWorkShareLabel({
      id: item.id,
    }).then((res) => {
      if (res.errno === -1) {
        showError(res.msg);
        return;
      }
      // 更新数据

      this.getAndRefresh().then(() => {
        showSuccess(res.msg);
      });
    });
    // 关闭弹窗
    this.props.confirmClose();
  }

  handleCloseClick() {
    this.props.closePanel(0);
  }

  handleTabBarClick(item) {
    const labelId = item.id;
    this.props.setCurrentWorkLabelInfo(item);
    this.props.setCurrentWorkLabel(labelId);

    this.refreshWorkList();
  }

  render() {
    const {
      currentWorkLabelInfo,
      currentWorkLabelId,
      workLabelList,
      workShareCode,
      workShareUserId,
      workShareLabelName,
      workShareUsername,
      userId,
      autoSaveWork,
    } = this.props;

    const { page, workList } = this.state;
    const {
      share_code: curWorkLabelShareCode,
      tbl_user_id: curWorkLabelUserId,
    } = currentWorkLabelInfo;

    let currentList = workList.slice(0, page * PAGE_SIZE); // 拷贝渲染数组
    let isShareDir = false;
    let isAuthorDir = true;
    let dirAuthorId = "";

    // 如果当前选中项， curWorkLabelShareCode 存在
    if (currentWorkLabelId === -255) {
      console.log("currentWorkLabelId 共享模式", dirAuthorId);
      isShareDir = true;
      dirAuthorId = workShareUserId;
      if (dirAuthorId !== userId) {
        isAuthorDir = false;
      }
    } else if (curWorkLabelShareCode) {
      console.log("curWorkLabelShareCode 共享模式", dirAuthorId);
      isShareDir = true;
      dirAuthorId = curWorkLabelUserId;
      if (dirAuthorId !== userId) {
        isAuthorDir = false;
      }
    }

    return (
      <div>
        <div className={styles.kidsPerson__create}>
          <div className={styles.kidsPerson__createWrapper}>
            <div className={styles.kidsPerson__header}>
              <div className={styles.kidsPerson__title}>
                <p className={styles.kidsPerson__title__main}>
                  {currentWorkLabelId === -255 ? (
                    <span>作者: {workShareUsername}</span>
                  ) : (
                    <span>我的创作</span>
                  )}
                  <span
                    className={styles.kidsOther__saveWrapper__refresh}
                    onClick={this.handleRefreshIconClick}
                  >
                    <img src={icon_refresh} alt="" width="30" />
                  </span>
                </p>

                {currentWorkLabelId === -255 && !isAuthorDir && (
                  <p className={styles.kidsPerson__title__tip}>
                    (您非共享标签作者，仅允许查看作品)
                  </p>
                )}

                {currentWorkLabelId === -255 && isAuthorDir && (
                  <p
                    className={cn({
                      [styles.kidsPerson__title__tip]: true,
                      [styles.author]: isAuthorDir,
                    })}
                  >
                    (您是共享标签作者，开放高级操作)
                  </p>
                )}
              </div>

              <img
                className={styles.kidsPerson__close}
                src={closeIcon}
                onClick={this.handleCloseClick}
              />
            </div>
            {/* 标签选项 */}
            <TabBar
              currentLabelId={currentWorkLabelId}
              labelList={workLabelList}
              onTabBarClick={this.handleTabBarClick}
            />
            <div
              className={styles.kidsOther__saveWrapper__managerSelect}
              onClick={this.handleManageLabelClick}
            >
              管理标签
            </div>

            {/* 共享入口 */}
            <div className={styles.kidsOther__saveWrapper__shareSelectBox}>
              <div
                className={
                  styles.kidsOther__saveWrapper__shareSelect +
                  " " +
                  (workShareLabelName &&
                    styles["kidsOther__saveWrapper__shareSelect--active"]) +
                  " " +
                  (currentWorkLabelId === -255 &&
                    styles[
                      "kidsOther__saveWrapper__shareSelect--active--selected"
                    ])
                }
                onClick={this.handleShareLabelClick}
              >
                <div
                  className={
                    "iconfont icon-wenjianjia2 " +
                    styles.kidsOther__saveWrapper__shareSelectIcon
                  }
                ></div>
                <div className={styles.kidsOther__saveWrapper__shareSelectText}>
                  {workShareLabelName ? workShareLabelName : "共享入口"}
                </div>
              </div>
              {workShareLabelName && (
                <div
                  className={
                    "iconfont icon-delete " +
                    styles.kidsOther__saveWrapper__shareSelectIconDelete
                  }
                  onClick={this.handleShareDeleteClick}
                ></div>
              )}
            </div>

            {/* 自动保存提醒 */}
            <div className={styles.kidsOther__saveWrapper__autoSave}>
              <label>
                <input
                  type="checkbox"
                  checked={autoSaveWork}
                  onChange={this.handleAutoSaveWorkChange.bind(this)}
                  ref="autoSaveWork"
                />
                自动保存提醒(10分钟)
              </label>
            </div>

            {/* 主列表 */}
            <div className={styles.kidsPerson__contentBox} ref="contentRef">
              <ul className={styles.kidsPerson__content}>
                {currentList.map((item, index) => {
                  const {
                    id,
                    name,
                    comment,
                    cover_path,
                    file_path,
                    upload_time,
                    tbl_label_id,
                  } = item;
                  const { tbl_user_id, tbl_share_label_id, username } = item;

                  // 是当前用户 的作品
                  const isCurrentUserWork = userId === tbl_user_id;
                  const isCurrentAuthorWork = dirAuthorId === tbl_user_id;

                  return (
                    <li
                      key={id}
                      className={cn({
                        [styles.kidsPerson__content__project]: true,
                        [styles.light]: isShareDir && !isCurrentAuthorWork,
                      })}
                    >
                      <div className={styles.kidsPerson__content__image}>
                        <img
                          className={styles.kidsPerson__content__image__img}
                          src={cover_path}
                        />
                        <div className={styles.kidsPerson__content__tools}>
                          <div
                            className={styles.kidsPerson__content__tools__icon}
                            onClick={this.handleEditClick.bind(
                              this,
                              file_path,
                              name,
                              comment,
                              tbl_label_id || 0
                            )}
                          >
                            <img src={person_edit} alt="" />
                          </div>

                          {/*
                            1. 非共享标签，且是用户作品
                            2. 是共享标签，且是标签作者，且是用户作品
                            展示删除 移动按钮
                          */}
                          {((!isShareDir && isCurrentUserWork) ||
                            (isShareDir &&
                              isAuthorDir &&
                              isCurrentAuthorWork)) && (
                            <Fragment>
                              <div
                                className={
                                  styles.kidsPerson__content__tools__icon
                                }
                                onClick={this.handleDeleteClick.bind(
                                  this,
                                  item
                                )}
                              >
                                <img src={person_delete} alt="" />
                              </div>
                              <div
                                className={
                                  styles.kidsPerson__content__tools__icon +
                                  " " +
                                  styles.kidsPerson__content__tools__iconTransfer
                                }
                                onClick={this.handleTransferClick.bind(
                                  this,
                                  item
                                )}
                              >
                                <div
                                  className={
                                    "iconfont icon-plus-transfer " +
                                    styles.kidsPerson__content__tools__iconTransferIcon
                                  }
                                ></div>
                              </div>
                            </Fragment>
                          )}

                          {/*
                            1. 是共享标签，且是标签作者，但非用户作品
                            展示移除按钮
                          */}
                          {isShareDir && isAuthorDir && !isCurrentAuthorWork && (
                            <Fragment>
                              <div
                                className={
                                  styles.kidsPerson__content__tools__icon
                                }
                                onClick={this.handleUnbindClick.bind(
                                  this,
                                  item
                                )}
                              >
                                <img src={person_unbind} alt="" />
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className={styles.kidsPerson__content__title}>
                        <span>{name}</span>
                      </div>
                      <div className={styles.kidsPerson__content__date}>
                        <span>{upload_time.replace(/:\d{2}$/, "")}</span>
                      </div>
                      {isShareDir && !isCurrentAuthorWork && (
                        <div
                          className={cn({
                            [styles.kidsPerson__content__username]: true,
                          })}
                        >
                          <span>{username}</span>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
              {/* 是否溢出 */}
              {currentList.length < workList.length && (
                <div
                  className={styles.kidsPerson__content__loadMore}
                  onClick={this.handleLoadMoreClick}
                >
                  加载更多作品
                </div>
              )}
              {/* 有无作品 */}
              {currentWorkLabelId === -255 && currentList.length === 0 ? (
                <div className={styles.kidsPerson__content__noWork}>
                  Ta还没有分享作品
                </div>
              ) : (
                currentWorkLabelId !== -255 &&
                currentList.length === 0 && (
                  <div className={styles.kidsPerson__content__noWork}>
                    快去保存一个作品吧
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workLabelList: state.kids.userPanel.workLabelList,
  userId: state.kids.userPanel.id,
  autoSaveWork: state.kids.userPanel.autoSaveWork,
  currentWorkLabelInfo: state.kids.userPanel.currentWorkLabelInfo,
  currentWorkLabelId: state.kids.userPanel.currentWorkLabelId,
  popupInput: state.kids.app.popup.input,
  workShareCode: state.kids.userPanel.workShareCode,
  workShareUserId: state.kids.userPanel.workShareUserId,
  workShareLabelName: state.kids.userPanel.workShareLabelName,
  workShareList: state.kids.userPanel.workShareList,
  workShareUsername: state.kids.userPanel.workShareUsername,
  selectedIndex: state.kids.app.select.index,

  projectList: state.kids.userPanel.projectList,

  loadProject: state.scratchGui.vm.loadProject.bind(state.scratchGui.vm),
});

const mapDispatchToProps = (dispatch) => ({
  setProjectList: (list) => dispatch(setProjectList(list)),
  setProjectName: (name) => dispatch(setProjectName(name)),
  setProjectComment: (comment) => dispatch(setProjectComment(comment)),
  setCurrentWorkLabel: (id) => dispatch(setCurrentWorkLabel(id)),
  setCurrentWorkLabelInfo: (info) => dispatch(setCurrentWorkLabelInfo(info)),
  setShowLabelPanel: (mode) => dispatch(setShowLabelPanel(mode)),
  setSelectedWorkLabel: (id) => dispatch(setSelectedWorkLabel(id)),
  setWorkShareCode: (name) => dispatch(setWorkShareCode(name)),
  setWorkShareLabelName: (name) => dispatch(setWorkShareLabelName(name)),
  setWorkShareList: (list) => dispatch(setWorkShareList(list)),
  setWorkShareUserId: (userId) => dispatch(setWorkShareUserId(userId)),
  setWorkShareUsername: (name) => dispatch(setWorkShareUsername(name)),
  setSelectShow: (info) => dispatch(setSelectShow(info)),
  setSelectClose: () => dispatch(setSelectClose()),
  onUpdateUserWork: () => onUpdateUserWork(dispatch),
  setAutoSaveWork: (autoSaveWork) => dispatch(setAutoSaveWork(autoSaveWork)),

  setPopupShow: (info) => dispatch(setPopupShow(info)),
  setPopupClose: () => dispatch(setPopupClose()),
  setLoading: (status) => dispatch(setLoading(status)),
  closePanel: () => dispatch(setUserPanelMode(0)),
  showSuccess: (text) => dispatch(setToastShow("success", text)),
  showError: (text) => dispatch(setToastShow("error", text)),

  confirmShow: (obj) => dispatch(setConfirmShow(obj)),
  confirmClose: () => dispatch(setConfirmClose()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
